import React, { Component } from 'react';
import Link from './../link.js';
import Isvg from 'react-inlinesvg';
import cart_icon from '../images/svg/cart-icon.svg'
import watermark from '../images/watermark.png';
import {
    Button

} from 'reactstrap';

class Artikal extends React.Component {
    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);

        this.state = {
            
        }
    }


    componentDidMount(){
        //console.log(this.props);
        this.setState({
            quantity: this.props.MinOrder
        })
    }

    addToCart(e) {
        e.preventDefault();



        if (parseInt(this.state.quantity) < this.props.MinOrder){
            this.props.showInfoMessage('Za ovaj proizvod postoji minimalna količina.', true);
            return;
        }


        if (this.props.Attributes && this.props.Attributes.length){
            this.props.handleArticleModal({
                _id: this.props._id,
                Alias: this.props.Alias,
                Name: this.props.Name,
                Attributes: this.props.Attributes,
                Image: this.props.Image,
                price: this.props.price,
                quantity: this.state.quantity
            });

            return;
        }


        this.props.shopCore.addToCart({ _id: this.props._id, quantity: parseInt(this.state.quantity) }, (data) => {
            if (data.successful) {
                this.props.showInfoMessage('Proizvod je uspješno dodat u korpu!');
                this.props.shopCore.cartInfo('cartInfo');
            } else {
                this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

            }
        });
        return false;
    }


    render() {
        //console.log(this.props);

        return (
            <div className="wrapper" onMouseLeave={() => { this.setState({ hover: null }) }}>
                <div className={this.state.hover ? 'card-flip flip' : 'card-flip'} >
                    <div className="front">
                       {/* <img src={watermark} className="watermark"></img>*/}
                        <div className="imageBox" onMouseEnter={() => { this.setState({ hover: true }) }}>
                            <img src={this.props.Image} className="img-fluid"></img>
                            {this.props.OnOffer ?
                            <div className="offer-tag">{this.props.translate('NA AKCIJI')}</div>
                            :
                            null}
                        </div>
                        <div className="textBox">
                            <Link to={`/artikal/${this.props._id}/${this.props.Alias}`} lang={this.props.lang} className="nav-link">

                                <h3><span>Art. {this.props.Alias} </span><br/> {this.props.Name && this.props.Name[this.props.lang]}</h3>
                            </Link>
                            <h2>{ this.props.price != (this.props.mpc && this.props.mpc[0]) ? <span className="original-price">{this.props.mpc && this.props.mpc[0] ? this.props.formatPrice(this.props.mpc[0]) : null } </span> : null } { this.props.price ?  this.props.formatPrice(this.props.price) : null } </h2>

                            <div className="textWrapper">
                                <input type="text" value={this.state.quantity} onChange={(e) => {
                                    this.setState({
                                        quantity: e.target.value
                                    });
                                }} />

                                <Button onClick={this.addToCart}><Isvg src={cart_icon} />{this.props.translate("U KORPU")}</Button>
                            </div>
                        </div>
                    </div>
                    <div className="back flip-image">
                        <Link to={`/artikal/${this.props._id}/${this.props.Alias}`} lang={this.props.lang} className="nav-link">

                            <img src={this.props.Images && this.props.Images.length ? this.props.Images[0] : this.props.Image} className="img-fluid"></img>
                        </Link>

                    </div>
                </div>
            </div>
        );
    }
}


export default Artikal;