import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../../link.js';
import showResults from '../showResults.js';
import { renderTextField, required, email, passwordsMustMatch } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const newPasswordForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, translate } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="password"
                        type="password"
                        component={renderTextField}
                        label={('Stara lozinka')}
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="new_password"
                        type="password"
                        label={('Nova Lozinka')}
                        component={renderTextField}
                        validate={[required]}
                    />
                </Col>

                <Col xl={12} lg={12} md={12}>
                <Field translate={translate}
                        name="repeat_password"
                        type="password"
                        label={('Ponovite Lozinku')}
                        component={renderTextField}
                        validate={[required]}
                    />
                </Col>
            </Row>

            <button className="btn"><span>{props.translate("SAČUVAJ")}</span></button>

        </form>


    )
}

export default reduxForm({
    form: 'newPasswordForm',  // a unique identifier for this form

})(newPasswordForm)
