import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import { Redirect } from 'react-router-dom';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import NewPasswordForm from '../containers/forms/newPasswordForm.js';
import Link from './../link.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class NewPassword extends React.Component {
    constructor(props) {
        super(props);
        this.resetPassword = this.resetPassword.bind(this);

        this.state = {

        }
    }

    resetPassword(obj) {
        this.props.shopCore.userChangePassword(obj, (data) => {
            if (data.error) {
                if (data.error == 'passwords-not-match') {
                    this.setState({
                        error: 'Lozinke se ne podudaraju'
                    })
                }
            } else {
                this.props.setUserData(data.user);
                this.props[0].history.push('/nalog');

            }
        })

    }

    render() {

        return (
            <div>
                {
                    !this.props.uData ? <Redirect to='/prijava' ></Redirect> : null
                }

                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Nova Lozinka")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Nova Lozinka")}</h2>
                                <div className="newWrapper">
                                    <div className="loginForm">
                                        <Row>
                                            <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                                <NewPasswordForm lang={this.props.lang} translate={this.props.translate} onSubmit={this.resetPassword}></NewPasswordForm>
                                                {
                                                    this.state.error ?

                                                        <div className="alert alert-danger" role="alert">
                                                            {this.state.error}
                                                        </div>


                                                        : null
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default NewPassword;