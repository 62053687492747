import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import HomeKategorije from '../containers/homeCategories.js';
import Artikli from '../containers/articles.js';
import carousel1 from '../images/Carousel1.png';
import Link from './../link.js';
import SpecijalnaPonuda from '../containers/specialOffer.js';
import PosebnaPonuda from '../containers/specialArticles.js';
import News from '../containers/news.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import slika1 from '../images/slika1.png';
import slika2 from '../images/slika2.png';
import slika3 from '../images/slika3.png';
import slika4 from '../images/slika4.png';
import slika5 from '../images/slika5.png';
import linija from '../images/linija.png';




import {
  Container,
  Row,
  Col,
  NavItem,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Button,

} from 'reactstrap';

const items = [
  {
    src: carousel1
  },
  {
    src: carousel1
  },
  {
    src: carousel1
  }

];

const pictures = [
  {
    src: slika1,
  },
  {
    src: slika2,
  },
  {
    src: slika3,
  },
  {
    src: slika4,
  },
  {
    src: slika5,
  }

];

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);

    this.state = {
      activeIndex: 0,
      promoted: [],
      popular: [],
      offer: [],
      selectedItems: 'promoted',
      news: [],
      slides: []
    };

  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.shopCore.fetchHomeProducts((data) => {
      //alert(JSON.stringify(data));
      this.setState(data);
    })
    this.props.shopCore.fetchNews({ count: 4 }, (data) => {
      //alert(JSON.stringify(data));
      this.setState({ news: data });
    })

    this.props.shopCore.fetchSlides((data) => {
      this.setState({ slides: data });
    });

  }
  componentDidUpdate(prevProps){
    if (!prevProps.uData && this.props.uData){
      this.props.shopCore.fetchHomeProducts((data) => {
        //alert(JSON.stringify(data));
        this.setState(data);
      })
      this.props.shopCore.fetchNews({ count: 4 }, (data) => {
        //alert(JSON.stringify(data));
        this.setState({ news: data });
      })
  
      this.props.shopCore.fetchSlides((data) => {
        this.setState({ slides: data });
      });
    }
  }


  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === this.state.slides.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? this.state.slides.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = this.state.slides.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.Image}
        >

          <img src={item.Image} className="img-fluid" />

        </CarouselItem>
      );
    });

    const slides2 = this.props.gallery.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item}
        >

          <img src={item} className="img-fluid" />

        </CarouselItem>
      );
    });

    let product = {};
    if (this.state.promoted.length) {
      product = this.state.promoted[0];
    }

    return (
      <div>
        <Navigacija {...this.props}></Navigacija>
        <div className="sliderWrapper">
          <Container>
            <Row className="no-gutters">
              <Col lg="12" md="12" className="sliderBg">
                <Carousel
                  activeIndex={activeIndex}
                  next={this.next}
                  previous={this.previous}
                  className="home-slider"
                >
                  <CarouselIndicators items={this.state.slides} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                  {slides}
                </Carousel>
              </Col>

            </Row>
          </Container>
        </div>

        <div className="home-wrap">
          <Container>
            <Row>
              <HomeKategorije categories={this.props.categories} translate={this.props.translate} lang={this.props.lang}></HomeKategorije>
              <Col lg="9">
                <div className="articleMenu">
                  <Container>
                    <Row>
                      <NavItem>
                        <a onClick={() => this.setState({ selectedItems: 'promoted' })} className={this.state.selectedItems == 'promoted' ? 'nav-link active' : 'nav-link'}>{this.props.translate("Izdvojeni")}</a>
                      </NavItem>
                      <NavItem>
                        <a onClick={() => this.setState({ selectedItems: 'popular' })} className={this.state.selectedItems == 'popular' ? 'nav-link active' : 'nav-link'}>{this.props.translate("Popularni")}</a>
                      </NavItem>
                      <NavItem>
                        <a onClick={() => this.setState({ selectedItems: 'offer' })} className={this.state.selectedItems == 'offer' ? 'nav-link active' : 'nav-link'}>{this.props.translate("Na akciji")}</a>
                      </NavItem>
                    </Row>
                    <a className="carousel-control-prev" href="#slajder" data-slide="prev">
                    </a>
                    <a className="carousel-control-next" href="#slajder" data-slide="next">
                    </a>
                  </Container>

                </div>

                <div id="slajder" className="carousel slide" data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="articleBox">
                        <Artikli {...this.props} items={this.state[this.state.selectedItems].slice(0, 6)}></Artikli>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="articleBox">
                        <Artikli {...this.props} items={this.state[this.state.selectedItems].slice(6, 12)}></Artikli>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <SpecijalnaPonuda {...this.props}></SpecijalnaPonuda>
        <PosebnaPonuda {...this.props}></PosebnaPonuda>

        <div className="homeNews">
          <Container>
            <Row className="no-gutters d-flex justify-content-between">
              <h4>{this.props.translate("NOVOSTI")}</h4>
              <Link to="/novosti" className="link" lang={this.props.lang}>{this.props.translate("Arhiva novosti")}</Link>
            </Row>
            <News items={this.state.news} translate={this.props.translate} lang={this.props.lang}></News>
          </Container>
        </div>

        <div className="homeGalery">
          <Container>
            <div>
              <Row className="no-gutters d-flex justify-content-between">
                <h4>{this.props.translate("FOTO GALERIJA")}</h4>
                <Link to="/galerija" className="link"  lang={this.props.lang}>{this.props.translate("Pogledajte kompletnu galeriju")}</Link>
              </Row>
            </div>
            <Row>
              <Col lg="4" md="5" xs="12">
                <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })}>
                  <img src={this.props.gallery[0] ? this.props.gallery[0] : ''} className="img-fluid prva" />

                </div>
              </Col>
              <Col lg="8" md="7" xs="12">
                <Row>
                  <Col lg="4" md="5" xs="12">
                    <div className="slikee" onClick={() => this.setState({ opened: true, activeIndex: 1 })}>
                      <img src={this.props.gallery[1] ? this.props.gallery[1] : ''} className="img-fluid" />

                    </div>
                  </Col>
                  <Col lg="8" md="7" xs="12">
                    <div className="slikee" onClick={() => this.setState({ opened: true, activeIndex: 2 })}>
                      <img src={this.props.gallery[2] ? this.props.gallery[2] : ''} className="img-fluid" />

                    </div>
                  </Col>
                </Row>
                <Row className="rowDivider">
                  <Col lg="8" md="7" xs="12">
                    <div className="slikee" onClick={() => this.setState({ opened: true, activeIndex: 3 })}>
                      <img src={this.props.gallery[3] ? this.props.gallery[3] : ''} className="img-fluid" />

                    </div>
                  </Col>
                  <Col lg="4" md="5" xs="12">
                    <div className="slikee" onClick={() => this.setState({ opened: true, activeIndex: 4 })}>
                      <img src={this.props.gallery[4] ? this.props.gallery[4] : ''} className="img-fluid" />

                    </div>
                  </Col>
                </Row>
              </Col>
              {this.state.opened ?
                <div className="lightbox">
                  <Container>
                    <Row>
                      <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                      <Carousel className="bg"
                        activeIndex={activeIndex}
                        next={this.next}
                        previous={this.previous}
                      >
                        <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                        {slides2}
                        <i className="fa fa-angle-left" onClick={this.previous}></i>
                        <i className="fa fa-angle-right" onClick={this.next}></i>
                      </Carousel>
                    </Row>
                  </Container>
                </div> : null
              }
            </Row>
          </Container>

        </div>

        <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
        <Map {...this.props}></Map>
       <Footer translate={this.props.translate}></Footer>




      </div>

    );
  }
}


export default Home;