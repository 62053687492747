import React, { Component } from 'react'
import Link from './../link.js';


import bgcPreparation from '../images/bgcPreparation.png';

import logo from '../images/logo.png';


import {
    Container, FormGroup, Input, Button, label
} from 'reactstrap';


export class inPreparation extends Component {

    constructor(props) {
        super(props);
        this.login = this.login.bind(this);

        this.state = {

        }
    }



    login(data) {
        this.props.shopCore.userLogin(data, (data) => {
            //console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                //this.props.socketIOClient.emit('cartInfo', {});

            } else {
                if (data.error == 'email-not-verified') {
                    this.setState({
                        error: 'Potvrdite Vašu e-mail adresu da bi ste se prijavili!'
                    });
                } else if (data.error == 'user-not-exists') {
                    this.setState({
                        error: 'Korisnik nije pronadjen'
                    });
                } else if (data.error == 'wrong-credentials') {
                    this.setState({
                        error: 'Pogrešni podaci'
                    })
                }

            }
        });
    }


    render() {
        return (

            <div className="wrapperPreparation">
                <img src={bgcPreparation} className="bgc" />
                <div className="preparation">
                    <img src={logo} className="img-fluid logo" />

                    <h1>Stranica u pripremi    </h1>


                    <div className="login-form ">
                        <h3>Prijava</h3>
                        <h4>Prijava za postojeće korisnike</h4>

                        <FormGroup>
                            <label>E-mail</label>
                            <Input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email}></Input>


                            <label>Lozinka</label>
                            <Input type="password" onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password}></Input>

                            
                        </FormGroup>


                        {/*<Link to="#">Zaboravili ste lozinku?</Link>*/}
                        {/*<p>Zaboravili ste lozinku?</p>*/}
                        <div className="d-flex justify-content-center">
                            <Button onClick={() => {
                                this.login(this.state);
                            }}>PRIJAVI SE</Button>
                        </div>

                                                {
                            this.state.error ?
                                <div className="alert alert-danger" role="alert">
                                    {this.state.error}
                                </div>

                                : null
                        }


                    </div>

                </div>

            </div>
        )
    }
}

export default inPreparation
