import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../../link.js';
import { renderTextField, renderTextareaField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const registerForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, translate } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
            <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="User.EMail"
                        type="email"
                        component={renderTextField}
                        label={('Email')}
                        requiredField
                        validate={[required, email]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="password"
                        type="password"
                        component={renderTextField}
                        label={('Lozinka')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="repeat_password"
                        type="password"
                        component={renderTextField}
                        label={('Ponovite lozinku')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.FirstName"
                        type="text"
                        component={renderTextField}
                        label={('Ime')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>
     
                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.LastName"
                        type="text"
                        component={renderTextField}
                        label={('Prezime')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.Company"
                        type="text"
                        component={renderTextField}
                        label={('Firma')}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.City"
                        type="text"
                        component={renderTextField}
                        label={('Grad')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.Street"
                        type="text"
                        component={renderTextField}
                        label={('Ulica i broj')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.Zipcode"
                        type="text"
                        component={renderTextField}
                        label={('Poštanski broj')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.Phone"
                        type="text"
                        component={renderTextField}
                        label={('Telefon')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>
                <p><span>*</span>&nbsp;{props.translate("Obavezna polja")}</p>
                <Col xl={12} lg={12} md={12}>
                    <Field 
                        translate={translate}
                        name="SubscribeToNewsletter"
                        type="text"
                        component={renderCheckField}
                        label={('Preplatite se na newsletter')}
                    ></Field>
                </Col>
                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.JIB"
                        type="text"
                        component={renderTextField}
                        label={('JIB')}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.PDV"
                        type="text"
                        component={renderTextField}
                        label={('PDV')}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="BillingAddress.MB"
                        type="text"
                        component={renderTextField}
                        label={('MB')}
                    ></Field>
                </Col>

            </Row>
            <Button><span>{props.translate("REGISTRUJ SE")}</span></Button>


        </form>

    )
}

export default reduxForm({
    form: 'registerForm',  // a unique identifier for this form

})(registerForm)
