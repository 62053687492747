import React, { Component } from 'react';
import Link from './../link.js';
import novosti1 from '../images/Novosti1.png';
import novosti2 from '../images/Novosti2.png';
import novosti3 from '../images/Novosti3.png';
import NewsItem from './newsItem.js';
import {
    Container,
     Row, 
     Col
} from 'reactstrap';

const novosti = [
    {
        _id: 1,
        allias: 'detalji',
        title: 'Lorem ipsum',
        paragraf: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at suscipit enim, eget vehicula est.',
        image: novosti1
    },
    {
        _id: 2,
        allias: 'detalji',
        title: 'Lorem ipsum',
        paragraf: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at suscipit enim, eget vehicula est.',
        image: novosti2
    },
    {
        _id: 3,
        allias: 'detalji',
        title: 'Lorem ipsum',
        paragraf: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at suscipit enim, eget vehicula est.',
        image: novosti3
    },
    {
        _id: 4,
        allias: 'detalji',
        title: 'Lorem ipsum',
        paragraf: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse at suscipit enim, eget vehicula est.',
        image: novosti1
    },
    
];


class news extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (
                 <Row>
                    {this.props.items.map((item, idx) => {
                            return (
                                <Col xl="3" lg="6" md="6" xs="12" key={item._id}>
                                        <NewsItem {...item} lang={this.props.lang} translate={this.props.translate}></NewsItem>
                                </Col>
                            )
                        })}
                    </Row>
        );
    }
}


export default news;