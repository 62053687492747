import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Link from './../link.js';
import News from '../containers/news.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import {
  Container,
  Row,
  NavItem,
} from 'reactstrap';


class Novosti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news: []
    }
  }
  

  componentDidMount() { window.scrollTo(0,0);
    this.props.shopCore.fetchNews({}, (data) => {
      //alert(JSON.stringify(data));
      this.setState({news: data});
    })

  }

  render() {

    return (
      <div>
        <Navigacija {...this.props}></Navigacija>

        <div className="breadcrumbLink">
          <Container>
            <Row className="d-flex justify-content-end no-gutters">
              <NavItem>
                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
              </NavItem>

              <NavItem>
                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Novosti")}</Link>
              </NavItem>
            </Row>
          </Container>
        </div>
        <div className="naslovWrapper">
                    <Container>

                        <div className="naslov">
                            <Row className="d-block">
                                <h1>{this.props.translate("Novosti")}</h1>
                                <p>{this.props.translate("Novosti o našim uslugama i proizvodima")}</p>
                            </Row>
                        </div>
                    </Container>
                </div>
        <div className="newsPage">
          <div className="homeNews">
            <Container>
             
              <News items={this.state.news} translate={this.props.translate} lang={this.props.lang}></News>

            </Container>
          </div>
        </div>
        <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
        <Map {...this.props}></Map>
       <Footer translate={this.props.translate}></Footer>

      </div>

    );
  }
}


export default Novosti;