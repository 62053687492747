import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../../link.js';
import showResults from '../showResults.js';
import { renderTextField, renderTextareaField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Row,
    Col,
    Button
} from 'reactstrap'


const resetPasswordForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, translate } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
            <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="password"
                        type="password"
                        component={renderTextField}
                        label={('Nova lozinka')}
                        validate={[required]}
                    ></Field>
                </Col>
                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="repeat_password"
                        type="password"
                        component={renderTextField}
                        label={('Ponovite lozinku')}
                        validate={[required]}
                    ></Field>
                </Col>
            </Row>
            <Button><span>{props.translate("Nastavi")}</span></Button>


        </form>

    )
}

export default reduxForm({
    form: 'forgotPasswordForm',  // a unique identifier for this form

})(resetPasswordForm)
