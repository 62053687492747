import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import cart_icon from '../images/svg/cart-icon.svg';
import map_icon from '../images/svg/map-icon.svg';
import map_icon_active from '../images/svg/map-icon-active.svg';
import cart_done_icon_active from '../images/svg/cart-done-icon-active.svg';
import cart_done_icon from '../images/svg/cart-done-icon.svg';
import cc_icon from '../images/svg/credit-card-icon.svg';
import Isvg from 'react-inlinesvg';
import minus from '../images/svg/minus.svg';
import plus from '../images/svg/plus.svg';
import delete_icon from '../images/svg/delete-icon.svg';
import arrow_left_red from '../images/svg/arrow-left-red.svg';
import arrow_right_white from '../images/svg/arrow-right-white.svg';
import NewDeliverAdress from '../containers/forms/newDeliveryAdressForm.js';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
    Button,
    Table,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Shoping2 extends React.Component {
    constructor(props) {
        super(props);
        this.updateDeliveryAddress = this.updateDeliveryAddress.bind(this);
        this.updateCurrentOrder = this.updateCurrentOrder.bind(this);

        this.state = {
            step: 1,
            formLoaded: false
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.uData && this.props.uData.Client && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.Client.ShippingAddress.FirstName,
                    LastName: this.props.uData.Client.ShippingAddress.LastName,
                    Street: this.props.uData.Client.ShippingAddress.Street,
                    Zipcode: this.props.uData.Client.ShippingAddress.Zipcode,
                    City: this.props.uData.Client.ShippingAddress.City,
                    EMail: this.props.uData.Client.ShippingAddress.EMail,
                    Phone: this.props.uData.Client.ShippingAddress.Phone,

                }
            })
        }

        if (this.props.uData && !this.props.uData.Client && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.ShippingAddress.FirstName,
                    LastName: this.props.uData.ShippingAddress.LastName,
                    Street: this.props.uData.ShippingAddress.Street,
                    Zipcode: this.props.uData.ShippingAddress.Zipcode,
                    City: this.props.uData.ShippingAddress.City,
                    EMail: this.props.uData.ShippingAddress.EMail,
                    Phone: this.props.uData.ShippingAddress.Phone,

                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && !this.props.uData.Client && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.ShippingAddress.FirstName,
                    LastName: this.props.uData.ShippingAddress.LastName,
                    Street: this.props.uData.ShippingAddress.Street,
                    Zipcode: this.props.uData.ShippingAddress.Zipcode,
                    City: this.props.uData.ShippingAddress.City,
                    EMail: this.props.uData.ShippingAddress.EMail,
                    Phone: this.props.uData.ShippingAddress.Phone,

                }
            })
        }

        if (this.props.uData && this.props.uData.Client && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.Client.ShippingAddress.FirstName,
                    LastName: this.props.uData.Client.ShippingAddress.LastName,
                    Street: this.props.uData.Client.ShippingAddress.Street,
                    Zipcode: this.props.uData.Client.ShippingAddress.Zipcode,
                    City: this.props.uData.Client.ShippingAddress.City,
                    EMail: this.props.uData.Client.ShippingAddress.EMail,
                    Phone: this.props.uData.Client.ShippingAddress.Phone,

                }
            })
        }

    }

    updateDeliveryAddress(data) {
        //console.log(data);

        if (this.props.uData) {

            if (this.props.uData.Client) {
                let Client = this.props.uData.Client;

                Client.ShippingAddress = {
                    'FirstName': data.FirstName,
                    'LastName': data.LastName,
                    'Street': data.Street,
                    'Zipcode': data.Zipcode,
                    'City': data.City,
                    'EMail': data.EMail,
                    'Phone': data.Phone,
                }
                this.props.shopCore.updateUserData({ Client: Client }, (data) => {
                    if (data.successful) {
                        this.props.setUserData(data.user);
                        this.setState({
                            showForm: null,
                            initialValues: null
                        })
                    }
                })
            } else {
                let obj = {
                    'ShippingAddress.FirstName': data.FirstName,
                    'ShippingAddress.LastName': data.LastName,
                    'ShippingAddress.Street': data.Street,
                    'ShippingAddress.Zipcode': data.Zipcode,
                    'ShippingAddress.City': data.City,
                    'ShippingAddress.EMail': data.EMail,
                    'ShippingAddress.Phone': data.Phone,

                }

                this.props.shopCore.updateUserData(obj, (data) => {
                    if (data.successful) {
                        this.props.setUserData(data.user);
                        this.setState({
                            showForm: null,
                            initialValues: null
                        })

                    }
                })
            }



        } else {
            let obj = {
                'FirstName': data.FirstName,
                'LastName': data.LastName,
                'Street': data.Street,
                'Zipcode': data.Zipcode,
                'City': data.City,
                'EMail': data.EMail,
                'Phone': data.Phone,
            }

            this.setState({
                shippingAddress: obj,
                showForm: null
            })
        }

    }

    updateCurrentOrder() {

        if (this.props.uData && this.props.uData.Client) {
            this.props.shopCore.updateCurrentOrder({
                BillingAddress: this.props.uData.Client.BillingAddress,
                ShippingAddress:  (this.props.uData.Client.DeliverTo == 'ShippingAddress' ? this.props.uData.Client.ShippingAddress : this.props.uData.Client.BillingAddress)

            }, (data) => {
                this.props[0].history.push('/nalog/korpa3');
            });
        } else {



            this.props.shopCore.updateCurrentOrder({
                BillingAddress: this.props.uData ? this.props.uData.BillingAddress : this.state.shippingAddress,
                ShippingAddress: this.props.uData ? (this.props.uData.DeliverTo == 'ShippingAddress' ? this.props.uData.ShippingAddress : this.props.uData.BillingAddress) : this.state.shippingAddress

            }, (data) => {
                this.props[0].history.push('/nalog/korpa3');
            });
        }

    }



    render() {

        //console.log(this.props.uData)

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div>
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12" className="content-wrap">
                                <div className="cart-wrap">
                                    <Row>
                                        <ul className="cart-progress">

                                            <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                <div className="icon">
                                                    <Isvg src={cart_icon} />
                                                </div>
                                                <p>{this.props.translate("1. Korpa")}</p>
                                                <p>{this.props.translate("Količina, dostava i plaćanje")}</p>

                                            </li>

                                            <li className={this.state.step === 1 ? 'active' : this.state.step > 1 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 1 ? map_icon_active : map_icon} className="secoundSvg" />
                                                </div>
                                                <p>{this.props.translate("2. Adresa")}</p>
                                                <p>{this.props.translate("Adresa za isporuku")}</p>

                                            </li>

                                            <li className={this.state.step === 2 ? 'active' : this.state.step > 2 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 2 ? cart_done_icon_active : cart_done_icon} />
                                                </div>
                                                <p>{this.props.translate("3. Narudžba")}</p>
                                                <p>{this.props.translate("Provjerite narudžbu i potvrdite")}</p>

                                            </li>

                                            <li className={this.state.step === 3 ? 'active' : this.state.step > 3 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={cc_icon} />
                                                </div>
                                                <p>{this.props.translate("4. Plaćanje")}</p>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>
                                <div className="shoppingTable">
                                    <Container className="whiteContainer">
                                        <p>{this.props.translate("Molimo vas da provjerite svoje unose. Možete ih ispraviti u svakom koraku porudžbine.")}</p>
                                        <p>{this.props.translate("Ako je potrebno, kliknite na gore navedene korake.")}</p>
                                        <p>{this.props.translate("Molimo Vas obratite pažnju na")} <Link to="/page/terms-and-conditions" lang={this.props.lang}>{this.props.translate("Uslove Korištenja")}</Link> i <Link to="/page/privacy-policy" lang={this.props.lang}>{this.props.translate("Politiku Privatnosti")}</Link>.</p>

                                    </Container>
                                    {this.props.uData && this.props.uData.Client ?
                                        <div className="delivery">
                                            <div className="deliveryBox-container">
                                                <div className="deliveryBox">
                                                    <h6>{this.props.translate("Adresa za obračun")}</h6>
                                                </div>
                                                <div className="text">
                                                    <Row>
                                                        <Col lg="6" md="6" xs="12">
                                                            <p>{this.props.uData.Client.BillingAddress.FirstName} {this.props.uData.Client.BillingAddress.LastName}</p>
                                                            <p>{this.props.uData.Client.BillingAddress.Street}</p>
                                                            <p>{this.props.uData.Client.BillingAddress.Zipcode} {this.props.uData.Client.BillingAddress.City}</p>
                                                            <br />
                                                            <p>{this.props.uData.Client.BillingAddress.EMail}</p>

                                                        </Col>
                                                        <Col lg="6" md="6" xs="12">
                                                            <div className="input-wrap radio-button-wrap" onClick={() => {

                                                                let Client = this.props.uData.Client;
                                                                Client.DeliverTo = 'BillingAddress';

                                                                this.props.shopCore.updateUserData({ Client: Client }, (data) => {
                                                                    if (data.successful) {
                                                                        this.props.setUserData(data.user);
                                                                    }
                                                                });

                                                            }}>
                                                                <div className={this.props.uData.Client.DeliverTo == 'BillingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Dostavite na ovu adresu")}
                                        </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>


                                            {this.props.uData.Client.ShippingAddress && this.props.uData.Client.ShippingAddress.EMail && this.props.uData.Client.ShippingAddress.FirstName && this.props.uData.Client.ShippingAddress.LastName && this.props.uData.Client.ShippingAddress.Street && this.props.uData.Client.ShippingAddress.Zipcode && this.props.uData.Client.ShippingAddress.City ?
                                                <div className="deliveryBox-container">
                                                    <div className="deliveryBox">
                                                        <h6>{this.props.translate("Adresa za isporuku")}</h6>
                                                    </div>
                                                    <div className="text">
                                                        <Row>
                                                            <Col lg="6" md="6" xs="12">
                                                                <p>{this.props.uData.Client.ShippingAddress.FirstName} {this.props.uData.Client.ShippingAddress.LastName}</p>
                                                                <p>{this.props.uData.Client.ShippingAddress.Street}</p>
                                                                <p>{this.props.uData.Client.ShippingAddress.Zipcode} {this.props.uData.Client.ShippingAddress.City}</p>
                                                                <br />
                                                                <p>{this.props.uData.Client.ShippingAddress.EMail}</p>

                                                            </Col>
                                                            <Col lg="6" md="6" xs="12">
                                                                <div className="input-wrap radio-button-wrap" onClick={() => {
                                                                    let Client = this.props.uData.Client;
                                                                    Client.DeliverTo = 'ShippingAddress';

                                                                    this.props.shopCore.updateUserData({ Client: Client }, (data) => {
                                                                        if (data.successful) {
                                                                            this.props.setUserData(data.user);
                                                                        }
                                                                    });

                                                                }}>
                                                                    <div className={this.props.uData.Client.DeliverTo == 'ShippingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Dostavite na ovu adresu")}
                                        </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                : null
                                            }



                                            <Row className="d-flex justify-content-between">
                                                <Button className="btn2" onClick={() => this.setState({ showForm: !this.state.showForm })}>{this.props.translate("Dodajte novu adresu za isporuku")}</Button>
                                                <div className="centriraj"><Button className="btn3" onClick={this.updateCurrentOrder}>{this.props.translate("NASTAVI")}<Isvg src={arrow_right_white} /></Button></div>
                                            </Row>
                                        </div>

                                        :

                                        <div className="delivery">
                                            {this.props.uData ?
                                                <div className="deliveryBox-container">
                                                    <div className="deliveryBox">
                                                        <h6>Adresa za obračun</h6>
                                                    </div>
                                                    <div className="text">
                                                        <Row>
                                                            <Col lg="6" md="6" xs="12">
                                                                <p>{this.props.uData.BillingAddress.FirstName} {this.props.uData.BillingAddress.LastName}</p>
                                                                <p>{this.props.uData.BillingAddress.Street}</p>
                                                                <p>{this.props.uData.BillingAddress.Zipcode} {this.props.uData.BillingAddress.City}</p>
                                                                <br />
                                                                <p>{this.props.uData.BillingAddress.EMail}</p>

                                                            </Col>
                                                            <Col lg="6" md="6" xs="12">
                                                                <div className="input-wrap radio-button-wrap" onClick={() => {
                                                                    this.props.shopCore.updateUserData({ DeliverTo: 'BillingAddress' }, (data) => {
                                                                        if (data.successful) {
                                                                            this.props.setUserData(data.user);
                                                                        }
                                                                    });

                                                                }}>
                                                                    <div className={this.props.uData.DeliverTo == 'BillingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Dostavite na ovu adresu")}
                                        </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                            {this.props.uData && this.props.uData.ShippingAddress.EMail && this.props.uData.ShippingAddress.FirstName && this.props.uData.ShippingAddress.LastName && this.props.uData.ShippingAddress.Street && this.props.uData.ShippingAddress.Zipcode && this.props.uData.ShippingAddress.City ?
                                                <div className="deliveryBox-container">
                                                    <div className="deliveryBox">
                                                        <h6>{this.props.translate("Adresa za isporuku")}</h6>
                                                    </div>
                                                    <div className="text">
                                                        <Row>
                                                            <Col lg="6" md="6" xs="12">
                                                                <p>{this.props.uData.ShippingAddress.FirstName} {this.props.uData.ShippingAddress.LastName}</p>
                                                                <p>{this.props.uData.ShippingAddress.Street}</p>
                                                                <p>{this.props.uData.ShippingAddress.Zipcode} {this.props.uData.ShippingAddress.City}</p>
                                                                <br />
                                                                <p>{this.props.uData.ShippingAddress.EMail}</p>

                                                            </Col>
                                                            <Col lg="6" md="6" xs="12">
                                                                <div className="input-wrap radio-button-wrap" onClick={() => {
                                                                    this.props.shopCore.updateUserData({ DeliverTo: 'ShippingAddress' }, (data) => {
                                                                        if (data.successful) {
                                                                            this.props.setUserData(data.user);
                                                                        }
                                                                    });

                                                                }}>
                                                                    <div className={this.props.uData.DeliverTo == 'ShippingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Dostavite na ovu adresu")}
                                        </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                            {this.state.shippingAddress ?
                                                <div className="deliveryBox-container">
                                                    <div className="deliveryBox">
                                                        <h6>{this.props.translate("Adresa za isporuku")}</h6>
                                                    </div>
                                                    <div className="text">
                                                        <Row>
                                                            <Col lg="6" md="6" xs="12">
                                                                <p>{this.state.shippingAddress.FirstName} {this.state.shippingAddress.LastName}</p>
                                                                <p>{this.state.shippingAddress.Street}</p>
                                                                <p>{this.state.shippingAddress.Zipcode} {this.state.shippingAddress.City}</p>
                                                                <br />
                                                                <p>{this.state.shippingAddress.EMail}</p>

                                                            </Col>
                                                            <Col lg="6" md="6" xs="12">
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                : null
                                            }

                                            {this.props.uData ?
                                                <div>
                                                    <p className="paragraph">{this.props.translate("Da li želite da se pretplatite na naš Newsletter? Možete da se odjavite u bilo kojem terrnutku.")}</p>
                                                    <div className="input-wrap2" onClick={() => {
                                                        this.props.shopCore.updateUserData({ SubscribeToNewsletter: !this.props.uData.SubscribeToNewsletter }, (data) => {
                                                            if (data.successful) {
                                                                this.props.setUserData(data.user);
                                                            }
                                                        });

                                                    }}><div className={this.props.uData.SubscribeToNewsletter ? 'checkbox checked' : 'checkbox'}></div><span>{this.props.translate("Pretplatite se Newsletter")}</span></div>
                                                </div>

                                                :

                                                null
                                            }

                                            <Row className="d-flex justify-content-between">
                                                <Button className="btn2" onClick={() => this.setState({ showForm: !this.state.showForm })}>{this.props.translate("Dodajte novu adresu za isporuku")}</Button>
                                                {this.props.uData || this.state.shippingAddress ? <div className="centriraj"><Button className="btn3" onClick={this.updateCurrentOrder}>{this.props.translate("NASTAVI")}<Isvg src={arrow_right_white} /></Button></div> : null}
                                            </Row>
                                        </div>
                                    }

                                    {this.state.showForm ?
                                        <div className="register mt-5">
                                            <h6>{this.props.translate("ADRESA ZA ISPORUKU")}</h6>
                                            <Row>
                                                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                                    <NewDeliverAdress translate={this.props.translate} lang={this.props.lang} initialValues={this.state.initialValues} onSubmit={this.updateDeliveryAddress}></NewDeliverAdress>

                                                </Col>
                                            </Row>
                                        </div> : null
                                    }

                                </div>


                                <div className="continueShopping">
                                    <h6>{this.props.translate("Želite li da nastavite sa kupovinom kasnije?")}</h6>
                                    <p>{this.props.translate("Molimo Vas obratite pažnju na")} <Link to="/page/terms-and-conditions" lang={this.props.lang}>{this.props.translate("Uslove Korištenja")}</Link> i <Link to="/page/privacy-policy" lang={this.props.lang}>{this.props.translate("Politiku Privatnosti")}</Link>.</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Shoping2;