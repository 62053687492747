import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import carousel1 from '../images/Carousel1.png';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import picture from '../images/aboutPicture.png';
import {
  Container,
  Row,
  Col,
  NavItem,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from 'reactstrap';

const items = [
  {
    src: carousel1,
  },
  {
    src: carousel1,
  },
  {
    src: carousel1,
  }
];

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutUs: {}

    }
  }

  componentDidMount() {
    this.props.shopCore.fetchAboutUs((data) => {
      this.setState({ aboutUs: data })
    })
  }



  render() {

    return (
      <div>
        <Navigacija {...this.props}></Navigacija>

        <div className="breadcrumbLink">
          <Container>
            <Row className="no-gutters d-flex justify-content-end">
              <NavItem>
                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
              </NavItem>
              <NavItem>
                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("O nama")}</Link>
              </NavItem>
            </Row>
          </Container>
        </div>
        <div className="aboutUs">
          <Container className="aboutContent">
            <Row>
              <Col xl="6" lg="6" md="6">
                <h1>{this.props.translate("O kompaniji AutoIn EXCALIBUR@")}</h1>
                <p className="paragraf">Podaci o firmi:</p>
                <br></br>
                <div dangerouslySetInnerHTML={{__html: this.state.aboutUs.companyData && this.state.aboutUs.companyData[this.props.lang]}}></div>

              </Col>
              <Col xl="6" lg="6" md="6">
                <img src={picture} className="img-fluid"></img>
              </Col>
              <Col xl="10" className="textDivider">
              <div dangerouslySetInnerHTML={{__html: this.state.aboutUs.Content && this.state.aboutUs.Content[this.props.lang]}}></div>

              </Col>
            </Row>
          </Container>
        </div>

        <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
        <Map {...this.props}></Map>
        <Footer translate={this.props.translate}></Footer>
      </div>

    );
  }
}


export default About;