import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import { Redirect } from 'react-router-dom';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Isvg from 'react-inlinesvg';
import Door from '../images/svg/door.svg';
import adresa from '../images/svg/profile/adresa.svg';
import licniPodaci from '../images/svg/profile/licniPodaci.svg';
import listaZelja from '../images/svg/profile/listaZelja.svg';
import narudzbe from '../images/svg/profile/narudzbe.svg';
import newsletter from '../images/svg/profile/newsletter.svg';
import promjenaLozinke from '../images/svg/profile/promjenaLozinke.svg';
import delete_icon from '../images/svg/delete-icon.svg';
import Link from './../link.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.selectClient = this.selectClient.bind(this);

        this.state = {
            search: '',
            clients: []
        }
    }


    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                clients: []
            })
        }

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    selectClient(client) {
        this.props.shopCore.updateUserData({
            Client: client
        }, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);
            }
        })
    }

    render() {

        return (
            <div>
                {!this.props.uData ? <Redirect to='/prijava' ></Redirect> : null}

                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Nalog")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="profile">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col xl="9" lg="9" md="12" sm="12" xs="12">
                                <div className="profileTop">

                                    <h2>{this.props.uData && this.props.uData.User.Name}</h2>
                                    <Button onClick={() => this.props.setUserData(null)}><Isvg src={Door} />{this.props.translate("ODJAVA")}</Button>

                                </div>
                                <div className="content">
                                    <Row>
                                        <Col xl="4" lg="4" md="6" sm="6" xs="6">
                                            <Link to="/nalog/licni-podaci" lang={this.props.lang}>
                                                <div className="boxContent">
                                                    <Isvg src={licniPodaci} />
                                                    <p>{this.props.translate("Lični podaci")}</p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="6" sm="6" xs="6">
                                            <Link to="/nalog/narudzbe" lang={this.props.lang}>
                                                <div className="boxContent">
                                                    <Isvg src={narudzbe} />
                                                    <p>{this.props.translate("Moje narudžbe")}</p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="6" sm="6" xs="6">
                                            <Link to="/nalog/adresa" lang={this.props.lang}>
                                                <div className="boxContent">
                                                    <Isvg src={adresa} />
                                                    <p>{this.props.translate("Adresa za isporuku")}</p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="6" sm="6" xs="6">
                                            <Link to="/nalog/lista-zelja" lang={this.props.lang}>
                                                <div className="boxContent">
                                                    <Isvg src={listaZelja} />
                                                    <p>{this.props.translate("Lista želja")}</p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="6" sm="6" xs="6">
                                            <Link to="/nova-lozinka" lang={this.props.lang}>
                                                <div className="boxContent">
                                                    <Isvg src={promjenaLozinke} />
                                                    <p>{this.props.translate("Promjena lozinke")}</p>
                                                </div>
                                            </Link>
                                        </Col>
                                        <Col xl="4" lg="4" md="6" sm="6" xs="6">
                                            <Link to="/nalog/newsletter" lang={this.props.lang}>
                                                <div className="boxContent">
                                                    <Isvg src={newsletter} />
                                                    <p>{this.props.translate("Newsletter")}</p>
                                                </div>
                                            </Link>
                                        </Col>
                                    </Row>
                                </div>
                                {this.props.uData && this.props.uData.IsSalesManager ?
                                    <div className=" delivery content delivery-no-shadow">
                                        <Row>
                                            <Col lg="12">
                                                <div className="input-wrap find-client-wrap">
                                                    <label>Pronađi klijenta</label>
                                                    <input class="form-control" onFocus={() => {
                                                        this.props.shopCore.searchClients({ search: this.state.search }, (data) => {
                                                            this.setState({
                                                                clients: data
                                                            })
                                                        });
                                                    }} type="text" value={this.state.search} onChange={(e) => {

                                                        this.setState({
                                                            search: e.target.value
                                                        }, () => {
                                                            //console.log(this.state.search)
                                                            this.props.shopCore.searchClients({ search: this.state.search }, (data) => {
                                                                //console.log(data);
                                                                this.setState({
                                                                    clients: data
                                                                })
                                                            });

                                                        })


                                                    }} />
                                                    {this.state.clients.length ?
                                                        <ul ref={(node) => this.wrapperRef = node}>

                                                            {
                                                                this.state.clients.map((item, idx) => {
                                                                    return (
                                                                        <li onClick={() => this.selectClient(item)}>
                                                                            <Row>
                                                                                <Col lg="6" xs="6">
                                                                                    {item.User.Name}
                                                                                </Col>
                                                                                <Col lg="6" xs="6">
                                                                                    {item.User.EMail}
                                                                                </Col>

                                                                            </Row>
                                                                        </li>

                                                                    )
                                                                })
                                                            }
                                                        </ul>

                                                        :
                                                        null

                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                        {this.props.uData && this.props.uData.Client ?
                                            <div className="deliveryBox-container">
                                                <div className="deliveryBox">
                                                    <h6>Klijent</h6>
                                                </div>
                                                <div className="text">
                                                    <Row>
                                                        <Col lg="6" md="6" xs="12">
                                                            <p>{this.props.uData.Client.BillingAddress.FirstName} {this.props.uData.Client.BillingAddress.LastName}</p>
                                                            <p>{this.props.uData.Client.BillingAddress.Street}</p>
                                                            <p>{this.props.uData.Client.BillingAddress.Zipcode} {this.props.uData.Client.BillingAddress.City}</p>
                                                            <br />
                                                            <p>{this.props.uData.Client.BillingAddress.EMail}</p>

                                                        </Col>
                                                        <Col lg="6" md="6" xs="12">
                                                            <div className="delete-client" onClick={() => this.selectClient(null)}>
                                                                <div className="delete-icon"><Isvg src={delete_icon} /></div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>

                                    : null

                                }

                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="newsSpacer">
                    <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                </div>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Profile;