import React, { Component } from 'react';
import phone from '../images/phone.png';
import heart from '../images/heart.png';
import narudzba from '../images/narudzba.png';
import instagram from '../images/instagram.png';
import facebook from '../images/facebook.png';
import logo from '../images/logo.png';
import searchIcon from '../images/searchIcon.png';
import Link from './../link.js';
import account_icon from '../images/svg/account-icon.svg';
import cart_icon from '../images/svg/cart-icon.svg'
import Isvg from 'react-inlinesvg';
import zastava from '../images/zastava.png';
import categoryIcon from '../images/svg/category-icon.svg';
import homeIcon from '../images/svg/home-icon.svg';
import infoIcon from '../images/svg/info-icon.svg';
import phoneIcon from '../images/svg/phone.svg';
import priceTag from '../images/svg/price-tag.svg';
import partnerIcon from '../images/svg/partner.svg';
import artikal1 from '../images/Artikal1.png';
import MobileCategories from './mobileCategories';
import newsIcon from '../images/svg/newspaper.svg';
import autooprema from '../images/autooprema.png';

import {
    Container, Row, Col, Input, InputGroup, Button, InputGroupAddon,
    Navbar, NavbarBrand, Collapse, UncontrolledDropdown, NavItem, NavLink, Nav
} from 'reactstrap';

class Navigacija extends React.Component {
    constructor(props) {
        super(props);
        this.suggestions = this.suggestions.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false,
            otvoren: false,
            searching: false,
            search: '',
            suggestions: []
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);

    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
            otvoren: !this.state.otvoren

        });
    }

    suggestions() {
        if (this.state.search.length) {
            this.props.shopCore.suggestions({ search: this.state.search }, (data) => {
                this.setState({
                    imageErrors: {},
                    suggestions: data.products
                })

            });
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({
                suggestions: []
            })
        }

    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);


    }


    render() {
        return (
            <div>
                <div className="header">
                    <Container>
                        <Row>
                            <Col xl="3" lg="3" md="3">
                                <img src={autooprema} className="header-text-image" />
                            </Col>
                            <Col xl="2" lg="2" xs="6" className="d-md-none d-lg-flex">
                                <p><img src={phone}></img>+387 (0) 55 202 297</p>
                            </Col>
                            <Col xl="2" lg="2" md="3">
                                <Link to='/nalog/lista-zelja' lang={this.props.lang}><p><img src={heart}></img>{this.props.translate("Lista omiljenih")}</p></Link>
                            </Col>
                            <Col xl="5" lg="5" md="6" className="d-flex justify-content-between">
                                <Link to='/nalog/narudzbe' lang={this.props.lang} className="ml-md-n4 ml-lg-0"><p><img src={narudzba}></img>{this.props.translate("Pregled narudžbi")}</p></Link>
                                <div className="d-flex mr-4">
                                    <a to='/en' onClick={() => this.props.changeLanguage("en")} style={{ cursor: 'pointer' }}><p>EN</p></a>&nbsp;<p>|</p>&nbsp;<a to='/sr' onClick={() => this.props.changeLanguage("ba")} style={{ cursor: 'pointer' }}><p>SR</p></a>
                                </div>
                                <p>{this.props.translate("Pratite nas")}
                                <a href="https://www.facebook.com/autoin.ba" target="_blank"><img src={facebook} className="fb"></img></a>
                                <a href="https://www.instagram.com/auto.in.excalibur/" target="_blank"><img src={instagram} className="instagram"></img></a>
                                </p>
                            </Col>

                        </Row>
                    </Container>
                    <div className="search">
                        <Container>
                            <Row>
                                <Col xs="1" className="d-lg-none">
                                    <button className="btn-menu" onClick={() => this.setState({ isOpen: !this.state.isOpen })}><i className="fa fa-bars"></i></button>
                                </Col>
                                <Col xl="3" lg="3" md="3" xs="2">
                                    <Link to='/' lang={this.props.lang}><img src={logo} className="img-fluid logo"></img></Link>
                                </Col>
                                <Col xl="4" lg="4" md="6" xs="6">
                                    <InputGroup>
                                        <Input placeholder={this.props.translate("Unesite pojam za pretragu...")} onFocus={this.suggestions} value={this.state.search} onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                this.props[0].history.push(`/kategorija/&search=${this.state.search}`)
                                            }
                                        }} onChange={(e) => { this.setState({ search: e.target.value }, () => this.suggestions()) }} />
                                        <InputGroupAddon addonType="append">
                                            <Button onClick={() => {
                                                this.props[0].history.push(`/kategorija/&search=${this.state.search}`)
                                            }}><img src={searchIcon}></img></Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    {this.state.suggestions.length ?

                                        <div className="searchForm" ref={(node) => { this.wrapperRef = node; }}>
                                            <h5>Artikli</h5>
                                            <Row>
                                                <div className="searchProduct">
                                                    <ul>
                                                        {
                                                            this.state.suggestions.map((item, idx) => {
                                                                return (
                                                                    <li>
                                                                        <Link to={`/artikal/${item._id}/${item.Alias}`} lang={this.props.lang}>
                                                                            <Row>
                                                                                <Col lg="3" md="3" xs="3">
                                                                                    <img src={item.Image} className="img-fluid"></img>
                                                                                </Col>
                                                                                <Col lg="9" md="9" xs="9">
                                                                                    <p><span>{item.Alias}</span><br /> {item.Name && item.Name[this.props.lang]}</p>
                                                                                </Col>
                                                                            </Row>
                                                                        </Link>
                                                                    </li>

                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </Row>
                                        </div>
                                        : null}
                                </Col>
                                <Col xl="4" lg="4" md="4" className="account d-md-none d-none d-lg-flex">

                                    <Isvg src={account_icon} />
                                    {this.props.uData ?
                                        <div>
                                            <Link to="/nalog" lang={this.props.lang}>{this.props.translate("Moj nalog")}</Link>
                                        </div>

                                        :
                                        <div>
                                            <Link to="/prijava" lang={this.props.lang}>{this.props.translate("Prijava")}</Link> / <Link to="/registracija" lang={this.props.lang}>{this.props.translate("Registracija")}</Link>
                                        </div>
                                    }
                                    <div className="cart">
                                        <Link to='/nalog/korpa' className="cart-button" lang={this.props.lang}>
                                            <Isvg src={cart_icon} />
                                            <div className="number">{this.props.cartCount}</div>
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs="1" md="1">
                                    <div className="cart2">
                                        <Link to='/nalog/korpa' className="cart-button" lang={this.props.lang}>
                                            <Isvg src={cart_icon} />
                                        </Link>
                                    </div>
                                </Col>
                                <Col xs="1" md="1">
                                    <button className="btn-categories" onClick={() => this.setState({ otvoren: !this.state.otvoren })}><Isvg src={categoryIcon}></Isvg></button>
                                </Col>

                            </Row>
                            <Row className="zastava">
                                <Col xl="3" lg="2" className="offset-xl-9 offset-lg-10 d-md-none d-lg-block">
                                    <img src={zastava} className="img-fluid"></img>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    <div className="linija">
                        <Container>
                            <hr></hr>
                        </Container>
                    </div>

                    <Navbar className="navbar navbar-expand-lg static-top">
                        <Container>
                            <Col xl="7" lg="9" className="offset-xl-3 offset-lg-3">
                                <Nav navbar>
                                    <NavItem className="nav-item">
                                        <Link className="nav-link" to="/" lang={this.props.lang}>{this.props.translate("POČETNA")}</Link>
                                    </NavItem>
                                    <NavItem className="nav-item">
                                        <Link className="nav-link" to="/o-nama" lang={this.props.lang}>{this.props.translate("O NAMA")}</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/" lang={this.props.lang}>{this.props.translate("AKCIJA")}</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/partner" lang={this.props.lang}>{this.props.translate("PARTNERI")}</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/novosti" lang={this.props.lang}>{this.props.translate("NOVOSTI")}</Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link className="nav-link" to="/kontakt" lang={this.props.lang}>{this.props.translate("KONTAKT")}</Link>
                                    </NavItem>
                                </Nav>

                            </Col>
                        </Container>
                    </Navbar>

                    <div className={this.state.isOpen ? 'navbarResposniveOpen' : 'navbarResposnive'} onMouseDown={() => this.setState({ isOpen: !this.state.isOpen })}>
                        <div className={this.state.isOpen ? 'sadrzajOpen' : 'sadrzaj'} onMouseDown={this.state.isOpen}>
                            <div className="content">
                                <div className="user-area">
                                    <div className="selectLanguage">
                                        <a to="/en" onClick={() => this.props.changeLanguage("en")} lang={this.props.lang}>EN</a>|&nbsp;<a onClick={() => this.props.changeLanguage("ba")} to="/sr" lang={this.props.lang}>SR</a>
                                    </div>
                                    <Col xl="4" lg="4" md="4" className="account">
                                        <Isvg src={account_icon} />
                                        {this.props.uData ?
                                            <p><Link to="/nalog" lang={this.props.lang} className="nav-link">{this.props.translate("Moj nalog")}</Link></p>

                                            :
                                            <p><Link to="/prijava" lang={this.props.lang} className="nav-link">{this.props.translate("Prijava")}</Link>/<Link to="/registracija" className="nav-link" lang={this.props.lang}>{this.props.translate("Registracija")}</Link></p>
                                        }

                                    </Col>
                                </div>
                                <NavItem className="nav-item">
                                    <Link className="nav-link" to="/" lang={this.props.lang}><Isvg src={homeIcon}></Isvg>{this.props.translate("POČETNA")}</Link>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <Link className="nav-link" to="/o-nama" lang={this.props.lang}><Isvg src={infoIcon}></Isvg>{this.props.translate("O NAMA")}</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to="/" lang={this.props.lang}><Isvg src={priceTag}></Isvg>{this.props.translate("AKCIJA")}</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to="/partner" lang={this.props.lang}><Isvg src={partnerIcon}></Isvg>{this.props.translate("PARTNERI")}</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to="/novosti" lang={this.props.lang}><Isvg src={newsIcon}></Isvg>{this.props.translate("NOVOSTI")}</Link>
                                </NavItem>
                                <NavItem>
                                    <Link className="nav-link" to="/kontakt" lang={this.props.lang}><Isvg src={phoneIcon}></Isvg>{this.props.translate("KONTAKT")}</Link>
                                </NavItem>
                            </div>
                        </div>
                    </div>
                    <div className={this.state.otvoren ? 'navbarResposniveOpen2' : 'navbarResposnive2'} onMouseDown={() => this.setState({ otvoren: !this.state.otvoren })}>
                        <div className={this.state.otvoren ? 'sadrzajOpen2' : 'sadrzaj2'} onMouseDown={this.state.otvoren}>
                            <div className="content mobile-categories">
                                <h3>{this.props.translate("KATEGORIJE")}</h3>
                                <MobileCategories {...this.props}></MobileCategories>
                                {/*<ul>
                                    <li><Link to='/o-nama' className="nav-link">Auto Ratkapne</Link>
                                        <div className="submenu">
                                            <ul>
                                                <li><Link to='/o-nama' className="nav-link">Audi</Link></li>
                                                <li><Link to='/asd' className="nav-link">Bmw</Link></li>
                                                <li><Link to='/asd' className="nav-link">Pezo</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><Link to='/o-nama' className="nav-link">Auto Patosnice</Link>
                                        <div className="submenu">
                                            <ul>
                                                <li><Link to='/o-nama' className="nav-link">Audi</Link></li>
                                                <li><Link to='/asd' className="nav-link">Bmw</Link></li>
                                                <li><Link to='/asd' className="nav-link">Pezo</Link></li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li><Link to='' className="nav-link">Obloge Volana</Link></li>
                                    <li><Link to='' className="nav-link">Auto Presvlake</Link></li>
                                    <li><Link to='' className="nav-link">Mirisne Konzerve</Link></li>
                                    <li><Link to='' className="nav-link">WUNDER-BAUM</Link></li>
                                    <li><Link to='' className="nav-link">Kokpit Sprej</Link></li>
                                    <li><Link to='' className="nav-link">EX-40</Link></li>
                                    <li><Link to='' className="nav-link">Auto Krpe</Link></li>
                                    <li><Link to='' className="nav-link">Ručice Mjenjača</Link></li>
                                    <li><Link to='' className="nav-link">Lanci za Snijeg</Link></li>
                                    <li><Link to='' className="nav-link">Metlice Brisača</Link></li>
                                    <li><Link to='' className="nav-link">Auto Sijalice</Link></li>
                                    <li><Link to='' className="nav-link">Alarmni Sistemi</Link></li>
                                    <li><Link to='' className="nav-link">Antene</Link></li>
                                    <li><Link to='' className="nav-link">Folije</Link></li>
                                    <li><Link to='' className="nav-link">Zaštitne Rukavice</Link></li>
                                    <li><Link to='' className="nav-link">Auto Oprema</Link></li>
                                    <li><Link to='' className="nav-link">Ostala Kozmetika</Link></li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Navigacija;