import React, { Component } from 'react';
import Link from './../link.js';

import {
    Button, Row, Col

} from 'reactstrap';
import moment from 'moment';

class newsItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        //console.log(this.props.translate)
        return (
            <div className="newsBox">  
                <Link to={`/novosti/${this.props._id}/${this.props.Alias}`} lang={this.props.lang} className="nav-link">
                    <img src={this.props.Image} className="img-fluid"></img>
                    <h3>{this.props.Title && this.props.Title[this.props.lang]}</h3>
                    <p className="redParagraf">{moment.unix(this.props.time).format('DD.MM.YYYY')} god {moment.unix(this.props.time).format('HH:mm')}h</p>
                    <p>{this.props.ShortDescription && this.props.ShortDescription[this.props.lang] && this.props.ShortDescription[this.props.lang].length > 110 ? this.props.ShortDescription[this.props.lang].substring(0,110) + "..." : this.props.ShortDescription[this.props.lang]}</p>
                    <Button>{this.props.translate("Detaljnije")}</Button>
                </Link>

            </div>

        );
    }
}


export default newsItem;