import socketIO from 'socket.io-client';


export class Core {
    constructor(props) {
        this.socket = socketIO('https://socket.autoin.ba');

        this.socket.on('cartInfo', props.cartInfoCallback);
    }


    

    fetchHomeProducts(callback) {
        this.socket.emit('fetchHomeProducts', {});

        this.socket.once('fetchHomeProducts', callback);
    }


    fetchCategories(callback) {
        this.socket.emit('fetchCategories', {});

        this.socket.once('fetchCategories', callback);
    }


    fetchProducts(query, callback) {
        this.socket.emit('fetchProducts', query);

        this.socket.once('fetchProducts', callback);
    }


    fetchProduct(query, callback) {
        this.socket.emit('fetchProduct', query);

        this.socket.once('fetchProduct', callback);

    }
    fetchPages(callback) {
        this.socket.emit('fetchPages', {});

        this.socket.once('fetchPages', callback);

    }

    userRegister(data, callback) {
        this.socket.emit('userRegister', data);

        this.socket.once('userRegister', callback);

    }

    userLogin(data, callback) {
        this.socket.once('userLogin', callback);



        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((pos) => {
                data.gpsLocation = pos.coords.latitude + ',' + pos.coords.longitude;
                this.socket.emit('userLogin', data);
            }, () => {
                this.socket.emit('userLogin', data);
            });
        } else {
            this.socket.emit('userLogin', data);
        }
    }

    userVerify(data, callback) {
        this.socket.once('userVerify', callback);

        if (window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition((pos) => {
                data.gpsLocation = pos.coords.latitude + ',' + pos.coords.longitude;
                this.socket.emit('userVerify', data);
            }, () => {
                this.socket.emit('userVerify', data);
            });
        } else {
            this.socket.emit('userVerify', data);
        }


    }

    updateUserData(data, callback) {
        this.socket.emit('updateUserData', data);

        this.socket.once('updateUserData', callback);
    }

    addToCart(data, callback) {
        this.socket.emit('addToCart', data);

        this.socket.once('addToCart', callback);
    }

    cartInfo() {
        this.socket.emit('cartInfo', {});
    }

    fetchCurrentOrder(callback) {
        this.socket.emit('fetchCurrentOrder', {});

        this.socket.once('fetchCurrentOrder', callback);

    }

    updateCurrentOrder(data, callback) {
        this.socket.emit('updateCurrentOrder', data);

        this.socket.once('updateCurrentOrder', callback);
    }

    executeOrder(callback) {
        this.socket.emit('executeOrder', {});

        this.socket.once('executeOrder', callback);
    }

    suggestions(data, callback) {
        this.socket.emit('suggestions', data);

        this.socket.once('suggestions', callback);
    }

    subscribeToNewsletter(data, callback) {
        this.socket.emit('subscribeToNewsletter', data);

        this.socket.once('subscribeToNewsletter', callback);
    }


    addToWishlist(data, callback) {
        this.socket.emit('addToWishlist', data);

        this.socket.once('addToWishlist', callback);
    }

    wishlist(callback) {
        this.socket.emit('wishlist', {});

        this.socket.once('wishlist', callback);

    }

    fetchNews(data, callback) {
        this.socket.emit('fetchNews', data);

        this.socket.once('fetchNews', callback);

    }


    fetchAboutUs(callback) {
        this.socket.emit('fetchAboutUs', {});

        this.socket.once('fetchAboutUs', callback);

    }

    fetchPartners(callback) {
        this.socket.emit('fetchPartners', {});

        this.socket.once('fetchPartners', callback);

    }


    fetchNewsItem(data, callback) {
        this.socket.emit('fetchNewsItem', data);

        this.socket.once('fetchNewsItem', callback);

    }

    fetchSlides(callback) {
        this.socket.emit('fetchSlides', {});

        this.socket.once('fetchSlides', callback);

    }


    fetchBanners(callback) {
        this.socket.emit('fetchBanners', {});

        this.socket.once('fetchBanners', callback);

    }

    fetchGallery(callback) {
        this.socket.emit('fetchGallery', {});

        this.socket.once('fetchGallery', callback);

    }

    fetchSpecialOfferProducts(callback) {
        this.socket.emit('fetchSpecialOfferProducts', {});

        this.socket.once('fetchSpecialOfferProducts', callback);

    }

    contact(data, callback) {
        this.socket.emit('contact', data);

        this.socket.once('contact', callback);
    }

    userForgetPassword(data) {
        this.socket.emit('userForgetPassword', data);

    }

    userResetPassword(data, callback) {
        this.socket.emit('userResetPassword', data);

        this.socket.once('userResetPassword', callback);
    }

    removeFromWishlist(data, callback) {
        this.socket.emit('removeFromWishlist', data);

        this.socket.once('removeFromWishlist', callback);
    }

    fetchOrders(callback) {
        this.socket.emit('fetchOrders', {});

        this.socket.once('fetchOrders', callback);
    }

    removeCartItem(data, callback) {
        this.socket.emit('removeCartItem', data);

        this.socket.once('removeCartItem', callback);
    }

    searchClients(data, callback) {
        this.socket.emit('searchClients', data);

        this.socket.once('searchClients', callback);
    }

    fetchProductAttributes(data, callback) {
        this.socket.emit('fetchProductAttributes', data);

        this.socket.once('fetchProductAttributes', callback);
    }

    userChangePassword(data, callback) {
        this.socket.emit('userChangePassword', data);

        this.socket.once('userChangePassword', callback);
    }

    confirmEmail(data, callback) {
        this.socket.emit('confirmEmail', data);
        this.socket.once('confirmEmail', callback);
    }

}