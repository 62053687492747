import React, { Component } from 'react';
import Link from './../link.js';
import footerSlika from '../images/footerSlika.png';
import logo from '../images/footer_logo.png';
import phone from '../images/phone.png'
import location from '../images/location.png'
import email from '../images/email.png'

import {
    Container,
    Row,
    Button,
    Col,
    FormGroup,
    Label,
    Input
} from 'reactstrap';

class footer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="footer">
                <Container>
                    <Row>
                        <img src={footerSlika} className="img-fluid formula"></img>
                    </Row>
                    <div className="footerContent">
                        <Row>
                            <Col xl="4"  lg = "3" md = "12" xs="12">
                                <img src={logo} className="img-fluid" className="logo"></img>
                            </Col>

                            <Col xl="5"  lg = "6" md = "12" xs="12">
                                <Row>
                                    <Col xl="6"  lg = "6" md = "6" xs="12" className = "navigacija">
                                        <h5>{this.props.translate("NAVIGACIJA")}</h5>
                                        <Link to="#" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                                        <Link to="/o-nama" className="nav-link" lang={this.props.lang}>{this.props.translate("O nama")}</Link>
                                        <Link to="#" className="nav-link" lang={this.props.lang}>{this.props.translate("Akcija")}</Link>
                                        <Link to="/novosti" className="nav-link" lang={this.props.lang}>{this.props.translate("Novosti")}</Link>
                                        <Link to="/kontakt" className="nav-link" lang={this.props.lang}>{this.props.translate("Kontakt")}</Link>
                                    </Col>
                                    <Col xl="6"  lg = "6" md = "6" xs="12" className = "info">
                                        <h5>INFO</h5>
                                        <p><img src={phone} className = "phone"></img>+387 (0) 55 202 297</p>
                                        <p><img src={location} className = "location"></img>{this.props.translate("Dušana Baranjina 37")}</p>
                                        <p className = "paragraf">76300 Bijeljina</p>
                                        <p><img src={email} className = "email"></img>info@autoin.ba</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xl="3" lg = "3" md = "12" className = "kontakt">
                                <FormGroup>
                                    <h5>{this.props.translate("KONTAKT")}</h5>
                                    <Input type="text" name="ime" placeholder={this.props.translate("Ime")} />
                                    <Input type="text" name="email" placeholder="E-mail" />
                                    <Input type="textarea" name="poruka" placeholder={this.props.translate("Poruka")} />
                                </FormGroup>
                                <Button>{this.props.translate("POŠALJI")}</Button>
                            </Col>
                        </Row>
                    </div>
                    <div className = "copyright">
                    <p>Copyright © AutoIn Excalibur@ d.o.o. Bijeljina - All Rights Reserved.</p>
                    <p>Created by <a href="https://www.novamedia.agency/">NOVA media</a></p>
                    </div>
                </Container>
            </div>
        );
    }
}


export default footer;