import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import ResetPasswordForm from '../containers/forms/resetPasswordForm.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.resetPassword = this.resetPassword.bind(this);
        this.state = {

        };
    }


    resetPassword(data) {
        data.uid = this.props[0].match.params.uid;
        data.code = this.props[0].match.params.code;

        this.props.shopCore.userResetPassword(data, () => {
            if (data.error) {
                if (data.error == 'passwords-not-match') {
                    this.setState({
                        error: 'Lozinke se ne podudaraju'
                    })
                }
            } else {
                this.props.setUserData(data.user);
                this.props[0].history.push('/nalog');

            }
        });
    }
    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Zaboravljena lozinka")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Promjena Lozinka")}</h2>
                                <div className="loginForm">
                                    <Row>
                                        <Col xl={8} lg={8} md={12} sm={12} xs={12}>

                                            <ResetPasswordForm onSubmit={this.resetPassword}></ResetPasswordForm>
                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.error}
                                                    </div>
                                                    : null
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default ResetPassword;