import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import RegisterForm from '../containers/forms/registerForm.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.register = this.register.bind(this);

        this.state = {
            checked: false,
            error: null
        }
    }

    register(data) {
        //console.log(data);
        data.ShippingAddress = {};
        data.User.Name = data.BillingAddress.FirstName + " " + data.BillingAddress.LastName;
        data.BillingAddress.EMail = data.User.EMail;

        this.props.shopCore.userRegister(data, (data) => {
            if (data.successful) {
                this.setState({
                    successful: true
                })
            } else {
                if (data.error == 'user-exists') {
                    this.setState({
                        error: 'Korisnik već postoji!'
                    });
                } else if (data.error == 'passwords-not-match') {
                    this.setState({
                        error: 'Lozinke se ne podudaraju'
                    })
                }
            }


        })


    }



    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Registracija")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Registracija")}</h2>
                                <div className="loginForm">
                                    {!this.state.successful ?
                                        <h6>{this.props.translate("Podaci")}</h6> : null
                                        }
                                    <Row>
                                        <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                            {!this.state.successful ?

                                                <RegisterForm translate={this.props.translate} lang={this.props.lang} onSubmit={this.register}></RegisterForm>
                                                :
                                                <div>
                                                    <h3>{this.props.translate("Vaš nalog je uspješno kreiran, potvrdite Vaš email")}</h3>
                                                </div>

                                            }
                                            {this.state.error ?

                                                <div className="alert alert-danger" role="alert">
                                                    {this.state.error}
                                                </div>
                                                : null
                                            }

                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
                <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Register;