import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import cart_icon from '../images/svg/cart-icon.svg';
import map_icon from '../images/svg/map-icon.svg';
import map_icon_active from '../images/svg/map-icon-active.svg';
import cart_done_icon_active from '../images/svg/cart-done-icon-active.svg';
import cart_done_icon from '../images/svg/cart-done-icon.svg';
import cc_icon from '../images/svg/credit-card-icon.svg';
import Isvg from 'react-inlinesvg';
import minus from '../images/svg/minus.svg';
import plus from '../images/svg/plus.svg';
import delete_icon from '../images/svg/delete-icon.svg';
import arrow_left_red from '../images/svg/arrow-left-red.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
    Button,
    Table,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Shoping extends React.Component {
    constructor(props) {
        super(props);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateCurrentOrder = this.updateCurrentOrder.bind(this);
        this.removeCartItem = this.removeCartItem.bind(this);

        this.state = {
            step: 0,
            checked: true,
        };

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchCurrentOrder((data) => {
            if (data.message) {
                this.props.showInfoMessage(data.message, true);
            }

            this.setState({
                order: data.order,
                deliveryMethods: data.deliveryMethods
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && !prevProps.uData) {

            this.props.shopCore.fetchCurrentOrder((data) => {
                if (data.message) {
                    this.props.showInfoMessage(data.message, true);
                }

                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods
                });
            });

        }
    }

    updateQuantity(idx, val) {
        let order = this.state.order;

        order.products[idx].cartQuantity = val;

        this.setState({
            order: order,
            cartChanged: true
        })

    }


    updateCurrentOrder() {
        let obj = {
            products: this.state.order.products
        }

        this.props.shopCore.updateCurrentOrder({ products: this.state.order.products, orderNote: this.state.orderNote, Note: this.state.Note }, (data) => {
            if (data.message) {
                this.props.showInfoMessage(data.message, true);
            }

            this.props.shopCore.fetchCurrentOrder((data) => {
                if (data.message) {
                    this.props.showInfoMessage(data.message, true);
                }

                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods,
                    cartChanged: null
                });
            });

        });

    }

    removeCartItem(id) {
        this.props.shopCore.removeCartItem({ productId: id }, () => {

            this.props.shopCore.fetchCurrentOrder((data) => {
                if (data.message) {
                    this.props.showInfoMessage(data.message, true);
                }

                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods
                });
            });

            this.props.shopCore.cartInfo();

        });

    }

    render() {
        let cartItems = this.state.order ? this.state.order.products : [];

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div>
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12" className="content-wrap">
                                <div className="cart-wrap">
                                    <Row>
                                        <ul className="cart-progress">

                                            <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                <div className="icon">
                                                    <Isvg src={cart_icon} />
                                                </div>
                                                <p>{this.props.translate("1. Korpa")}</p>
                                                <p>{this.props.translate("Količina, dostava i plaćanje")}</p>

                                            </li>

                                            <li className={this.state.step === 1 ? 'active' : this.state.step > 1 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 1 ? map_icon_active : map_icon} className="secoundSvg" />
                                                </div>
                                                <p>{this.props.translate("2. Adresa")}</p>
                                                <p>{this.props.translate("Adresa za isporuku")}</p>

                                            </li>

                                            <li className={this.state.step === 2 ? 'active' : this.state.step > 2 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 2 ? cart_done_icon_active : cart_done_icon} />
                                                </div>
                                                <p>{this.props.translate("3. Narudžba")}</p>
                                                <p>{this.props.translate("Provjerite narudžbu i potvrdite")}</p>

                                            </li>

                                            <li className={this.state.step === 3 ? 'active' : this.state.step > 3 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={cc_icon} />
                                                </div>
                                                <p>{this.props.translate("4. Plaćanje")}</p>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>
                                <div className="shoppingTable">
                                    <Container className="whiteContainer">
                                        <p>{this.props.translate("Molimo vas da provjerite svoje unose. Možete ih ispraviti u svakom koraku porudžbine.")}</p>
                                        <p>{this.props.translate("Ako je potrebno, kliknite na gore navedene korake.")}</p>
                                        <p>{this.props.translate("Molimo Vas obratite pažnju na")} <Link to="/page/terms-and-conditions" lang={this.props.lang}>{this.props.translate("Uslove Korištenja")}</Link> i <Link to="/page/privacy-policy" lang={this.props.lang}>{this.props.translate("Politiku Privatnosti")}</Link>.</p>
                                        <div className="backToHome">
                                            <Link to="/" className="link2" lang={this.props.lang}><Isvg src={arrow_left_red}></Isvg>{this.props.translate("Nastavi sa kupovinom")}</Link>
                                        </div>
                                    </Container>
                                    <Container className="cart-table">

                                        <Row className="header">
                                            <Col lg="2" xs="2"><p>{this.props.translate("Količina")}</p></Col>
                                            <Col lg="5" xs="5"><p>{this.props.translate("Naziv artikla")}</p></Col>

                                            <Col lg="2" xs="2" className="number"><p>{this.props.translate("Cijena")}</p></Col>

                                            <Col lg="2" xs="2" className="number"><p>{this.props.translate("Ukupno")}</p></Col>
                                            <Col lg="1" xs="1"></Col>
                                        </Row>

                                        {
                                            cartItems.map((item, idx) => {
                                                return (
                                                    <Row className="article-info">
                                                        <Col lg="2" xs="2">
                                                            <div className="inputBox">
                                                                <input type='text' value={item.cartQuantity} onChange={(e) => this.updateQuantity(idx, e.target.value)}></input>
                                                            </div>
                                                        </Col>
                                                        <Col lg="5" xs="5">
                                                            <p>{item.Alias} - {item.Name && item.Name[this.props.lang]} {item.selectedAttributes ? ' - ' + Object.values(item.selectedAttributes).join(', ') : null}</p>
                                                        </Col>
                                                        <Col lg="2" xs="2" className="number">
                                                            <p>{this.props.formatPrice(item.price)}</p>
                                                        </Col>
                                                        <Col lg="2" xs="2" className="number">
                                                            <p>{this.props.formatPrice(item.price * item.cartQuantity)} </p>
                                                        </Col>
                                                        <Col lg="1" xs="1" className="delete" onClick={() => this.removeCartItem(item._id)}>
                                                            <Isvg src={delete_icon} />
                                                        </Col>
                                                    </Row>

                                                )
                                            })
                                        }

                                        <Row className="article-info">
                                            <Col lg="7" xs="7">
                                                <p>{this.props.translate("Ukupan iznos")}</p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p>{this.state.order ? this.props.formatPrice(this.state.order.itemsTotal) : this.props.formatPrice(0)} </p>
                                            </Col>
                                            <Col lg="1" xs="1" className="delete">
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="2" xs="3">
                                                <p>{this.props.translate("Dostava")}</p>
                                            </Col>
                                            <Col lg="5" xs="6">
                                                {this.state.order ?
                                                    <Dropdown className="select-field" isOpen={this.state.dropdownOpen1} toggle={() => { this.setState({ dropdownOpen1: !this.state.dropdownOpen1 }) }}>
                                                        <DropdownToggle nav caret>
                                                            {this.state.order.DeliveryMethod.name}
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-animation">
                                                            {
                                                                this.state.deliveryMethods.map((item, idx) => {
                                                                    return (
                                                                        <DropdownItem onClick={() => {
                                                                            this.props.shopCore.updateCurrentOrder({ DeliveryMethod: item }, (data) => {
                                                                                this.props.shopCore.fetchCurrentOrder((data) => {
                                                                                    if (data.message) {
                                                                                        this.props.showInfoMessage(data.message, true);
                                                                                    }

                                                                                    this.setState({
                                                                                        order: data.order,
                                                                                        deliveryMethods: data.deliveryMethods,
                                                                                        cartChanged: null
                                                                                    });
                                                                                });

                                                                            });


                                                                        }}>{item.name}</DropdownItem>

                                                                    )
                                                                })
                                                            }
                                                        </DropdownMenu>
                                                    </Dropdown>

                                                    : null
                                                }
                                            </Col>
                                            <Col lg="2" xs="2" className="d-none d-lg-block">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p>{this.state.order ? this.props.formatPrice(this.state.order.DeliveryMethod.totalPrice) : this.props.formatPrice(0)} </p>
                                            </Col>
                                            <Col lg="1" xs="1" className="delete">
                                            </Col>
                                        </Row>
                                        <Row className="article-info billing-methods-row">
                                            <Col lg="2" xs="3">
                                                <p>{this.props.translate("Način plaćanja")}</p>
                                            </Col>
                                            <Col lg="5" xs="9" className="billing-methods">
                                                <p onClick={() => {
                                                    this.props.shopCore.updateCurrentOrder({ BillingMethod: 'gotovinski-pouzecem' }, (data) => {
                                                        this.props.shopCore.fetchCurrentOrder((data) => {
                                                            if (data.message) {
                                                                this.props.showInfoMessage(data.message, true);
                                                            }

                                                            this.setState({
                                                                order: data.order,
                                                                deliveryMethods: data.deliveryMethods,
                                                                cartChanged: null
                                                            });
                                                        });

                                                    });


                                                }}><div className={this.state.order && this.state.order.BillingMethod == 'gotovinski-pouzecem' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Gotovinski pouzećem")}</p>
                                                <p onClick={() => {
                                                    this.props.shopCore.updateCurrentOrder({ BillingMethod: 'žiralno' }, (data) => {
                                                        this.props.shopCore.fetchCurrentOrder((data) => {
                                                            if (data.message) {
                                                                this.props.showInfoMessage(data.message, true);
                                                            }

                                                            this.setState({
                                                                order: data.order,
                                                                deliveryMethods: data.deliveryMethods,
                                                                cartChanged: null
                                                            });
                                                        });

                                                    });


                                                }}><div className={this.state.order && this.state.order.BillingMethod == 'žiralno' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Žiralno")}</p>

                                            </Col>
                                            <Col lg="2" className="d-none d-lg-block number">
                                            </Col>
                                            <Col lg="2" className="d-none d-lg-block number">
                                            </Col>
                                            <Col lg="1" className="d-none d-lg-block delete">
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="7" xs="7">
                                                <p><span>{this.props.translate("Iznos bez PDV-a")}</span></p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p><span>{this.state.order ? this.props.formatPrice(this.state.order.subtotal) : this.props.formatPrice(0)}</span></p>
                                            </Col>
                                            <Col lg="1" xs="1" className="delete">
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="7" xs="7">
                                                <p><span>{this.props.translate("Iznos PDV-a")}</span></p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p><span>{this.state.order ? this.props.formatPrice(this.state.order.tax) : this.props.formatPrice(0)} </span></p>
                                            </Col>
                                            <Col lg="1" xs="1" className="delete">
                                            </Col>
                                        </Row>

                                        <Row className="article-info">
                                            <Col lg="7" xs="7">
                                                <p><span>{this.props.translate("UKUPAN IZNOS")}</span></p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p><span>{this.state.order ? this.props.formatPrice(this.state.order.total) : this.props.formatPrice(0)} </span></p>
                                            </Col>
                                            <Col lg="1" xs="1" className="delete">
                                            </Col>
                                        </Row>


                                    </Container>
                                    {this.props.uData && ( this.props.uData.CustomerGroup == 'LegalPerson' || this.props.uData.IsSalesManager) ?

                                        <div className="input-wrap">
                                            <input className={'form-control'} type="text" onChange={(e) => {
                                                this.setState({
                                                    orderNote: e.target.value,
                                                    cartChanged: true
                                                })
                                            }} value={this.state.orderNote} placeholder={this.props.translate('Ime naručioca')} />
                                        </div>

                                        : null}
                                        <div className="input-wrap">
                                            <input className={'form-control'} type="text" onChange={(e) => {
                                                this.setState({
                                                    Note: e.target.value,
                                                    cartChanged: true
                                                })
                                            }} value={this.state.Note} placeholder={this.props.translate('Napomena')} />
                                        </div>

                                    <Row className="d-flex justify-content-center justify-content-lg-end">

                                        {
                                            !this.state.cartChanged ?
                                                <Link to="/nalog/korpa2" lang={this.props.lang}><Button>{this.props.translate("ZAVRŠI PORUDŽBINU")}</Button></Link>
                                                :
                                                <Button onClick={this.updateCurrentOrder}>{this.props.translate("OSVJEŽI PORUDŽBINU")}</Button>

                                        }

                                    </Row>

                                </div>
                                <div className="continueShopping">
                                    <h6>{this.props.translate("Želite li da nastavite sa kupovinom kasnije?")}</h6>
                                    <p>{this.props.translate("Molimo Vas obratite pažnju na")} <Link to="/nalog/korpa2" lang={this.props.lang}>{this.props.translate("Uslove Korištenja")}</Link> i <Link to="/" lang={this.props.lang}>{this.props.translate("Politiku Privatnosti")}</Link>.</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
                <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Shoping;