import React, { Component } from 'react';
import artikal1 from '../images/Artikal1.png';
import artikal2 from '../images/Artikal2.png';
import artikal3 from '../images/Artikal3.png';
import artikal4 from '../images/Artikal4.png';
import artikal5 from '../images/Artikal5.png';
import artikal6 from '../images/Artikal6.png';
import FavoriteArticle from './favoriteArticle.js';
import {
    Container, Row, Col, Input, InputGroup, Button, InputGroupAddon,
    Navbar, NavbarBrand, Collapse, UncontrolledDropdown, NavItem, NavLink, Nav

} from 'reactstrap';

const articles = [
    {
        _id: '1',
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '20',
        image: artikal1
    },
    {
        _id: '2',
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '15',
        image: artikal1
    },
    {
        _id: '3',
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '20',
        image: artikal1
    },
    {
        _id: '4',
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '35',
        image: artikal1
    },
    {
        _id: '5',
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '20',
        image: artikal1
    },
    {
        _id: '6',
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '15',
        image: artikal1
    },
];


class favoriteArticles extends React.Component {
    constructor(props) {
        super(props);

    }


    render() {
        return (
            <Container>
                <Row>
                    {this.props.items.map((item, idx) => {
                        return (
                            <Col xl="4" md="6" xs = "6" className = "divider" key={item._id}>
                                <FavoriteArticle {...this.props} handleDelete={this.props.handleDelete} {...item}></FavoriteArticle>
                            </Col>

                        )
                    })}

                </Row>
            </Container>
        );
    }
}


export default favoriteArticles;