import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import { Redirect } from 'react-router-dom';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import NewDeliverAdress from '../containers/forms/newDeliveryAdressForm';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Table,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class DeliveryAdress extends React.Component {
    constructor(props) {
        super(props);
        this.updateDeliveryAddress = this.updateDeliveryAddress.bind(this);
        this.updateDeliverTo = this.updateDeliverTo.bind(this);

        this.state = {
            DeliverTo: null
        }
    }



    componentDidMount() { window.scrollTo(0,0);
        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.ShippingAddress.FirstName,
                    LastName: this.props.uData.ShippingAddress.LastName,
                    Street: this.props.uData.ShippingAddress.Street,
                    Zipcode: this.props.uData.ShippingAddress.Zipcode,
                    City: this.props.uData.ShippingAddress.City,
                    EMail: this.props.uData.ShippingAddress.EMail,
                    Phone: this.props.uData.ShippingAddress.Phone,

                }
            })
        }

        if (this.props.uData && this.props.uData.DeliverTo && !this.state.DeliverTo) {
            this.setState({
                DeliverTo: this.props.uData.DeliverTo
            })
        }


    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.ShippingAddress.FirstName,
                    LastName: this.props.uData.ShippingAddress.LastName,
                    Street: this.props.uData.ShippingAddress.Street,
                    Zipcode: this.props.uData.ShippingAddress.Zipcode,
                    City: this.props.uData.ShippingAddress.City,
                    EMail: this.props.uData.ShippingAddress.EMail,
                    Phone: this.props.uData.ShippingAddress.Phone,

                }
            })
        }

        if (this.props.uData && this.props.uData.DeliverTo && !this.state.DeliverTo) {
            this.setState({
                DeliverTo: this.props.uData.DeliverTo
            })
        }


    }

    updateDeliveryAddress(data) {
        //console.log(data);
        let obj = {
            'ShippingAddress.FirstName': data.FirstName,
            'ShippingAddress.LastName': data.LastName,
            'ShippingAddress.Street': data.Street,
            'ShippingAddress.Zipcode': data.Zipcode,
            'ShippingAddress.City': data.City,
            'ShippingAddress.EMail': data.EMail,
            'ShippingAddress.Phone': data.Phone,

        }
        this.props.shopCore.updateUserData(obj, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);
                this.setState({
                    showForm: null,
                    initialValues: null
                })

                /*if (this.state.redirect) {
                    this.props[0].history.push('/nalog');
                }*/
            }
        })

    }

    updateDeliverTo() {
        let obj = {
            'DeliverTo': this.state.DeliverTo,
        }
        this.props.shopCore.updateUserData(obj, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);

                this.props[0].history.push('/nalog');
            }
        })

    }
    render() {

        return (
            <div>
                {
                    !this.props.uData ? <Redirect to='/prijava' ></Redirect> : null}
                }

                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Adresa")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Adresa za isporuku")}</h2>
                                <div className="delivery">
                                    <div className="deliveryBox">
                                        <h6>{this.props.translate("Adresa za obračun")}</h6>
                                    </div>
                                    <div className="text">
                                        <Row>
                                            {this.props.uData ?
                                                <Col lg="6" md="6" xs="12">
                                                    <p>{this.props.uData.BillingAddress.FirstName} {this.props.uData.BillingAddress.LastName}</p>
                                                    <p>{this.props.uData.BillingAddress.Street}</p>
                                                    <p>{this.props.uData.BillingAddress.Zipcode} {this.props.uData.BillingAddress.City}</p>
                                                    <br />
                                                    <p>{this.props.uData.BillingAddress.EMail}</p>
                                                </Col>
                                                : null
                                            }
                                            <Col lg="6" md="6" xs="12">
                                                <div className="input-wrap radio-button-wrap d-inline" onClick={() => this.setState({ DeliverTo: 'BillingAddress' })}>
                                                    <div className={this.state.DeliverTo == 'BillingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Dostavite na ovu adresu")}
                                             </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <br></br>
                                    {this.props.uData && this.props.uData.ShippingAddress.EMail && this.props.uData.ShippingAddress.FirstName && this.props.uData.ShippingAddress.LastName && this.props.uData.ShippingAddress.Street && this.props.uData.ShippingAddress.Zipcode && this.props.uData.ShippingAddress.City ?
                                        <div >
                                            <div className="deliveryBox">
                                                <h6>{this.props.translate("Adresa za isporuku")}</h6>
                                            </div>
                                            <div className="text">
                                                <Row>
                                                    <Col lg="6" md="6" xs="12">
                                                        <p>{this.props.uData.ShippingAddress.FirstName} {this.props.uData.ShippingAddress.LastName}</p>
                                                        <p>{this.props.uData.ShippingAddress.Street}</p>
                                                        <p>{this.props.uData.ShippingAddress.Zipcode} {this.props.uData.ShippingAddress.City}</p>
                                                        <br />
                                                        <p>{this.props.uData.ShippingAddress.EMail}</p>
                                                    </Col>
                                                    <Col lg="6" md="6" xs="12">
                                                        <div className="input-wrap radio-button-wrap d-inline" onClick={() => this.setState({ DeliverTo: 'ShippingAddress' })}>
                                                            <div className={this.state.DeliverTo == 'ShippingAddress' ? 'radio-button radio-button-checked' : 'radio-button'}></div>{this.props.translate("Dostavite na ovu adresu")}
                                             </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        : null}

                                    <Button className="btn1" onClick={this.updateDeliverTo}>{this.props.translate("Sačuvaj")}</Button>
                                    <Button className="btn2" onClick={() => this.setState({ showForm: true })}>{this.props.translate("Dodajte novu adresu za isporuku")}</Button>

                                    {this.state.showForm ?
                                        <div className="register mt-5">
                                            <h6>{this.props.translate("ADRESA ZA ISPORUKU")}</h6>
                                            <Row>
                                                <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                                    <NewDeliverAdress translate={this.props.translate} lang={this.props.lang} initialValues={this.state.initialValues} onSubmit={this.updateDeliveryAddress}></NewDeliverAdress>

                                                </Col>
                                            </Row>
                                        </div> : null
                                    }


                                </div>


                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default DeliveryAdress;