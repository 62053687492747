
import React, { Component } from 'react';
import {  Redirect } from 'react-router-dom';
import { Link } from './../link.js';

import Isvg from 'react-inlinesvg';

class Check extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className = "wrapInput">
            <div onClick={() => this.props.onChange(!this.props.value)} className="input-wrap">
                <div className={this.props.value ? "checkbox checked" : "checkbox"} ></div>
                <span>{this.props.label}</span>

            </div>
            </div>
        );
    }
}

export default Check;