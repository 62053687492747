import React, { Component } from 'react'
import {Link} from 'react-router-dom'

class Ruta extends Component {
    render() {
        return (
            <Link to = {`${this.props.to}?lang=${this.props.lang}`} lang={this.props.lang} className={this.props.className}>
                {this.props.children}
            </Link>
        )
    }
}

export default Ruta
