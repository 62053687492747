import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import {Redirect } from 'react-router-dom';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import odobreno from '../images/svg/profile/odobreno.svg';
import odbijeno from '../images/svg/profile/odbijeno.svg';
import Isvg from 'react-inlinesvg';
import FavoriteArticles from '../containers/favoriteArticles.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Table,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class FavoriteList extends React.Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
        this.state = {
            products: []
        }
    }

    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.wishlist((data) => {
            this.setState({
                products: data
            })

        })
    }

    handleDelete(id){
        this.props.shopCore.removeFromWishlist({_id: id}, () => {
            this.props.shopCore.wishlist((data) => {
                this.setState({
                    products: data
                })
    
            })
    
        })
    }


    render() {

        return (
            <div>
                                {!this.props.uData ? <Redirect to='/prijava' ></Redirect> : null}

                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Lista omiljenih")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="favoriteArticles">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Lista omiljenih")}</h2>
                                <div className = "articleBox">
                                <FavoriteArticles {...this.props} handleDelete={this.handleDelete} items={this.state.products} ></FavoriteArticles>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default FavoriteList;