import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import ForgotPasswordForm from '../containers/forms/forgotPasswordForm.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.sendEmail = this.sendEmail.bind(this);
    }


    sendEmail(data) {
        //console.log(data);
        this.props.shopCore.userForgetPassword(data);
    }

    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Zaboravljena lozinka")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md = "12"> 
                                <h2>{this.props.translate("Zaboravljena Lozinka")}</h2>
                                <div className="loginForm">
                                    <h6>{this.props.translate("Zaboravili ste lozinku?")}</h6>
                                        <Row>
                                            <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                            <p className = "newParagraf">{this.props.translate("Poslaćemo vam link na vaš e-mail na kojem možete da upišete novu lozinku.")}</p>

                                                    <ForgotPasswordForm translate={this.props.translate} lang={this.props.lang} onSubmit={this.sendEmail}></ForgotPasswordForm>                                                   
                                            </Col>
                                        </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default ForgotPassword;