import React, { Component } from 'react';
import Link from './../link.js';
import {
  Container,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
} from 'reactstrap';

class newsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    return (
      <div className="newsletterWrapper">
        <Container>
          <Row className="d-sm-block mx-auto">
            <div className="newsletter d-lg-flex">
              <Col xl="5" lg="6" xs="12">
                <h1>{this.props.translate("NEWSLETTER")}</h1>
                <p>{this.props.translate("Prijavite se na naš newsletter kako bi primali najnovije aktuelnosti i promotivne ponude.")}</p>
              </Col>
              <Col xl="5" lg="6" xs="12" className="offset-xl-2">
                {this.state._done ?

                  <p>{this.props.translate("Uspješno ste se prijavili")}</p>
                  : <InputGroup>
                    <Input type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} placeholder={this.props.translate("Unesite Vašu E-mail adresu")} />
                    <InputGroupAddon addonType="append">
                      <Button onClick={() => {
                        if (this.state.email && this.state.email.indexOf('@') !== -1) {
                          this.props.shopCore.subscribeToNewsletter({ email: this.state.email }, () => {
                            this.setState({
                              _done: true
                            })
                          })
                        }
                      }}>{this.props.translate("PRIJAVI SE")}</Button>
                    </InputGroupAddon>
                  </InputGroup>
                }
              </Col>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}


export default newsletter;