const langs = {  
    "en":{  
        "Ime": "Name",
        "Poruka": "Message",
       "Izdvojeni":"Featured",
       "Popularni":"Poopular",
       "Na akciji":"On sale",
       "NOVOSTI":"NEWS",
       "Arhiva novosti":"All news",
       "FOTO GALERIJA":"PHOTO GALLERY",
       "Pogledajte kompletnu galeriju":"See all gallery",
       "Lista omiljenih":"Favorite list",
       "Pregled narudžbi":"Orders review",
       "Pratite nas":"Follow us",
       "Moj nalog":"My account",
       "POČETNA":"HOME",
       "O NAMA":"ABOUT US",
       "AKCIJA":"SALE",
       "PARTNERI":"PARTNERS",
       "KONTAKT":"CONTACT",
       "KATEGORIJE":"CATEGORIES",
       "POSEBNO IZDVAJAMO":"SPECIAL OFFER",
       "Pogledajte kompletnu listu":"See complete list",
       "NEWSLETTER":"NEWSLETTER",
       "Prijavite se na naš newsletter kako bi primali najnovije aktuelnosti i promotivne ponude.":"Sign up for our newsletter in order to receive the latest news and promotional offers.",
       "PRIJAVI SE":"SIGN IN",
       "Lorem ipsum dolor sit amet, consectetur  adipiscing elit. Suspendisse at suscipit  enim, eget vehicula est.":"Lorem ipsum dolor sit amet, consectetur  adipiscing elit. Suspendisse at suscipit  enim, eget vehicula est.",
       "NAVIGACIJA":"MENU",
       "Početna":"Home",
       "O nama":"About us",
       "Akcija":"Sale",
       "Novosti":"News",
       "Kontakt":"Contact",
       "Dušana Baranjina 37":"Dušana Baranjina 37",
       "POŠALJI":"SEND",
       "U KORPU":"IN CART",
       "Detaljnije":"More",
       "SPECIJANA PONUDA":"SPECIAL OFFER",
       "SORTIRAJ PO":"SORT BY",
       "Cijeni opadajućoj":"Price declining",
       "Cijeni rastućoj":"Price ascending",
       "O kompaniji AutoIn EXCALIBUR@":"About company AutoIn EXCALIBUR@",
       "Firma d.o.o. AutoIn EXCALIBUR@ nalazi se na sledećoj adresi:":"Company d.o.o. AutoIn EXCALIBUR@ is located at the following address:",
       "Dušana Baranjina 37, 76300 Bijeljina, Bosna i Hercegovina.":"Dušana Baranjina 37, 76300 Bijeljina, Bosna i Hercegovina.",
       "Ideja o kompaniji, koja se bavi distribucijom kvalitetnih proizvoda iz segmenta AutoOpreme i AutoKozmetike, podstakla je osnivanje firme doo.EXCALIBUR@, Decembra 1998.godine.":"The idea of the company, which deals with the distribution of quality products from the segment of Auto equipment and Auto cosmetics, prompted the establishment of the company doo.EXCALIBUR@ in December 1998.",
       "Pocetne godine su nam ukazale na neophodnost unapredivanja ovog segmenta, te smo 2002.godine krenuli sa distribucijom brenda":"In the first years we pointed to the necessity of improving this segment, and in 2002 we started with brand distribution",
       "koji se izdvaja kvalitetom, jedinstvenim izgledom ambalaže kao i asortimanom artikala.":"which is distinguished by its quality, its unique appearance and its range of products.",
       "Pažljivim odabirom artikala i pracenjem zahtjeva kupaca":"Careful product selection and customer demand tracking",
       "stekli smo povjerenje i pozicionirali brend":"we gained trust and positioned the brand",
       "na ovom tržištu":"in this market",
       "koga kupci, DANAS,  prepoznaju kao brend koji, u potpunosti, zadovoljava potrebe krajnjeg potrošaca":"which customers, TODAY, recognize as a brand that fully meets the needs of the end consumer",
       "i cijenom i kvalitetom ali i izgledom.":"both price and quality, but also appearance.",
       "Ponosni smo i na dugogodišnju saradnju sa americkim brendom ":"We are proud of the long-standing cooperation with the American brand ",
       "liderom u segmentu Auto Osvježivaca, ciji":"the leader in the Auto Refreshment segment, whose",
       "smo distributeri za Bosnu i Hercegovinu.":"we are distributors for Bosnia and Herzegovina.",
       "Mi brinemo o njezi Vašeg auta!":"We care about the care of your car!",
       "Partneri":"Partners",
       "Naši partneri":"Our partners",
       "Brendovi sa kojima sarađujemo":"The brands we cooperate with",
       "21 Godina uspješnog poslovanja":"21 years of successful business",
       "Novosti o našim uslugama i proizvodima":"News about our services and products",
       "INFORMACIJE":"INFO",
       "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.":"Be free to contact us. We will respond to you as soon as possible.",
       "d.o.o. EXCALIBUR@":"d.o.o. EXCALIBUR@",
       "Pošalji":"Send",
       "VAŠE IME":"YOUR NAME",
       "VAŠA PREZIME":"YOU SURNAME",
       "VAŠ BROJ TELEFONA":"YOUR PHONE NUMBER",
       "VAŠA E-MAIL ADRESA":"YOUR E-MAIL ADDRESS",
       "SUBJECT":"SUBJECT",
       "PORUKA":"MESSAGE",
       "Nalog":"Account",
       "ODJAVA":"LOG OUT",
       "Lični podaci":"Personal data",
       "Moje narudžbe":"My orders",
       "Adresa za isporuku":"Shipping address",
       "Lista želja":"Wish list",
       "Promjena lozinke":"Change password",
       "Newsletter":"Newsletter",
       "Podaci":"Data",
       "Obavezna polja":"Mandatory fields",
       "SAČUVAJ":"SAVE",
       "Email":"E-mail",
       "Ime":"Name",
       "Firma":"Company",
       "Prezime":"Surname",
       "Grad":"City",
       "Ulica i broj":"Streat and number",
       "Poštanski broj":"ZIP code",
       "Telefon":"Phone",
       "JIB":"JIB",
       "PDV":"PDV",
       "MB":"MB",
       "Narudžbe":"Orders",
       "Broj nardužbe":"Order number",
       "Datum":"Date",
       "Iznos":"Amount",
       "Status":"Status",
       "Adresa":"Address",
       "Adresa za obračun":"Billing address",
       "Dostavite na ovu adresu":"Deliver to this address",
       "Sačuvaj":"Save",
       "Dodajte novu adresu za isporuku":"Add a new shipping address",
       "ADRESA ZA ISPORUKU":"SHIPPING ADDRESS",
       "Nova Lozinka":"New password",
       "Stara lozinka":"Old password",
       "Ponovite Lozinku":"Retype password",
       "Da li želite da budete u toku? Kako naručiti naš Newsletter:":"You want to keep up-to-date? How to order our Newsletter:",
       "1. Izabereite  Newsletter.":"1. Select the Newsletter.",
       "2. Unesite svoje ime i e-mail adresu, ako već niste prijavljeni.":"2. Enter your name and e-mail address, if you have not already logged in.",
       "3. Kliknite Prijavi se.":"3. Click Sign in.",
       "4. Primićete e-mail na kojem morate da potvrdite pretplatu na Newsletter.":"4. You will receive an email where you must confirm your subscription to the Newsletter.",
       "Napomena: Dostava e-mail poruke može potrajati nekoliko minuta.":"Note: Delivery of an e-mail message may take several minutes.",
       "Proverite da li je poruka e-pošte premještena u spam folder.":"Make sure is email moved to the spam folder.",
       "Ako ne primite ovu e-poruku, pokušajte ponovo ili nas kontaktirajte direktno.":"If you do not receive this email, please try again or contact us directly.",
       "Možete se odjaviti sa našeg Newsletter na ovoj stranici u bilo kom trenutku.":"You can unsubscribe from our Newsletter on this page at any time.",
       "1. Korpa":"1. Cart",
       "Količina, dostava i plaćanje":"Quantity, delivery and payment",
       "2. Adresa":"2. Addresd",
       "3. Narudžba":"3. Order",
       "Provjerite narudžbu i potvrdite":"Check your order and confirm",
       "4. Plaćanje":"4. Payment",
       "Molimo vas da provjerite svoje unose. Možete ih ispraviti u svakom koraku porudžbine.":"Please check your entries. You can correct them at every step of the order.",
       "Ako je potrebno, kliknite na gore navedene korake.":"If necessary, click on the steps above.",
       "Molimo Vas obratite pažnju na":"Please pay attention to",
       "Uslove Korištenja":"Terms of Use",
       "Politiku Privatnosti":"Privacy Policy",
       "Nastavi sa kupovinom":"Continue shopping",
       "Količina":"Quantity",
       "Naziv artikla":"Article name",
       "Cijena":"Price",
       "Ukupno":"In total",
       "Ukupan iznos":"Total amount",
       "Dostava":"Delivery",
       "Način plaćanja":"Payment method",
       "Plaćanje prilikom pouzeća":"Payments on delivery",
       "Uplata na tekući račun":"Payment to account",
       "Iznos bez PDV-a":"Amount without VAT",
       "Iznos PDV-a":"VAT amount",
       "UKUPAN IZNOS":"TOTAL AMOUNT",
       "ZAVRŠI PORUDŽBINU":"FINISH ORDER",
       "Želite li da nastavite sa kupovinom kasnije?":"Would you like to continue shopping later?",
       "NASTAVI":"CONTINUE",
       "Pročitao sam i prihvatam":"I read and I accept",
       "Uslove Korištenja.":"Terms of Use.",
       "Galerija":"Gallery",
       "Foto Galerija":"Photo Gallery",
       "Cijena bez PDV-a":"Amount without VAT",
       "Cijena sa PDV-a":"Amount with VAT",
       "KUPI ODMAH":"BUY NOW",
       "Dodaj u omiljene":"Add to favorites",
       "Podijeli":"Share",
       "Opis proizvoda":"Product description",
       "Unesite pojam za pretragu...": "Enter a search term ...",
       "Unesite Vašu E-mail adresu": "Enter your E-mail address",
       "Ime":"Name",
       "VAŠE NAME":"YOUR NAME",
       "Vaša poruka":"Your message"
    },
    "ba":{  
       "Izdvojeni":"Izdvojeni",
       "Popularni":"Popularni",
       "Na akciji":"Na akciji",
       "NOVOSTI":"NOVOSTI",
       "Arhiva novosti":"Arhiva novosti",
       "FOTO GALERIJA":"FOTO GALERIJA",
       "Pogledajte kompletnu galeriju":"Pogledajte kompletnu galeriju",
       "Lista omiljenih":"Lista omiljenih",
       "Pregled narudžbi":"Pregled narudžbi",
       "Pratite nas":"Pratite nas",
       "Moj nalog":"Moj nalog",
       "POČETNA":"POČETNA",
       "O NAMA":"O NAMA",
       "AKCIJA":"AKCIJA",
       "PARTNERI":"PARTNERI",
       "KONTAKT":"KONTAKT",
       "KATEGORIJE":"KATEGORIJE",
       "POSEBNO IZDVAJAMO":"POSEBNO IZDVAJAMO",
       "Pogledajte kompletnu listu":"Pogledajte kompletnu listu",
       "NEWSLETTER":"NEWSLETTER",
       "Prijavite se na naš newsletter kako bi primali najnovije aktuelnosti i promotivne ponude.":"Prijavite se na naš newsletter kako bi primali najnovije aktuelnosti i promotivne ponude.",
       "PRIJAVI SE":"PRIJAVI SE",
       "Lorem ipsum dolor sit amet, consectetur  adipiscing elit. Suspendisse at suscipit  enim, eget vehicula est.":"Lorem ipsum dolor sit amet, consectetur  adipiscing elit. Suspendisse at suscipit  enim, eget vehicula est.",
       "NAVIGACIJA":"NAVIGACIJA",
       "Početna":"Početna",
       "O nama":"O nama",
       "Akcija":"Akcija",
       "Novosti":"Novosti",
       "Kontakt":"Kontakt",
       "Dušana Baranjina 37":"Dušana Baranjina 37",
       "POŠALJI":"POŠALJI",
       "U KORPU":"U KORPU",
       "Detaljnije":"Detaljnije",
       "SPECIJANA PONUDA":"SPECIJANA PONUDA",
       "SORTIRAJ PO":"SORTIRAJ PO",
       "Cijeni opadajućoj":"Cijeni opadajućoj",
       "Cijeni rastućoj":"Cijeni rastućoj",
       "O kompaniji AutoIn EXCALIBUR@":"O kompaniji AutoIn EXCALIBUR@",
       "Firma d.o.o. AutoIn EXCALIBUR@ nalazi se na sledećoj adresi:":"Firma d.o.o. AutoIn EXCALIBUR@ nalazi se na sledećoj adresi:",
       "Dušana Baranjina 37, 76300 Bijeljina, Bosna i Hercegovina.":"Dušana Baranjina 37, 76300 Bijeljina, Bosna i Hercegovina.",
       "Ideja o kompaniji, koja se bavi distribucijom kvalitetnih proizvoda iz segmenta AutoOpreme i AutoKozmetike, podstakla je osnivanje firme doo.EXCALIBUR@, Decembra 1998.godine.":"Ideja o kompaniji, koja se bavi distribucijom kvalitetnih proizvoda iz segmenta AutoOpreme i AutoKozmetike, podstakla je osnivanje firme doo.EXCALIBUR@, Decembra 1998.godine.",
       "Pocetne godine su nam ukazale na neophodnost unapredivanja ovog segmenta, te smo 2002.godine krenuli sa distribucijom brenda":"Pocetne godine su nam ukazale na neophodnost unapredivanja ovog segmenta, te smo 2002.godine krenuli sa distribucijom brenda",
       "koji se izdvaja kvalitetom, jedinstvenim izgledom ambalaže kao i asortimanom artikala.":"koji se izdvaja kvalitetom, jedinstvenim izgledom ambalaže kao i asortimanom artikala.",
       "Pažljivim odabirom artikala i pracenjem zahtjeva kupaca":"Pažljivim odabirom artikala i pracenjem zahtjeva kupaca",
       "stekli smo povjerenje i pozicionirali brend":"stekli smo povjerenje i pozicionirali brend",
       "na ovom tržištu":"na ovom tržištu",
       "koga kupci, DANAS,  prepoznaju kao brend koji, u potpunosti, zadovoljava potrebe krajnjeg potrošaca":"koga kupci, DANAS,  prepoznaju kao brend koji, u potpunosti, zadovoljava potrebe krajnjeg potrošaca",
       "i cijenom i kvalitetom ali i izgledom.":"i cijenom i kvalitetom ali i izgledom.",
       "Ponosni smo i na dugogodišnju saradnju sa americkim brendom ":"Ponosni smo i na dugogodišnju saradnju sa americkim brendom ",
       "liderom u segmentu Auto Osvježivaca, ciji":"liderom u segmentu Auto Osvježivaca, ciji",
       "smo distributeri za Bosnu i Hercegovinu.":"smo distributeri za Bosnu i Hercegovinu.",
       "Mi brinemo o njezi Vašeg auta!":"Mi brinemo o njezi Vašeg auta!",
       "Partneri":"Partneri",
       "Naši partneri":"Naši partneri",
       "Brendovi sa kojima sarađujemo":"Brendovi sa kojima sarađujemo",
       "21 Godina uspješnog poslovanja":"21 Godina uspješnog poslovanja",
       "Novosti o našim uslugama i proizvodima":"Novosti o našim uslugama i proizvodima",
       "INFORMACIJE":"INFORMACIJE",
       "Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.":"Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.",
       "d.o.o. EXCALIBUR@":"d.o.o. EXCALIBUR@",
       "Pošalji":"Pošalji",
       "VAŠE IME":"VAŠE IME",
       "VAŠA PREZIME":"VAŠA PREZIME",
       "VAŠ BROJ TELEFONA":"VAŠ BROJ TELEFONA",
       "VAŠA E-MAIL ADRESA":"VAŠA E-MAIL ADRESA",
       "SUBJECT":"SUBJECT",
       "PORUKA":"PORUKA",
       "Nalog":"Nalog",
       "ODJAVA":"ODJAVA",
       "Lični podaci":"Lični podaci",
       "Moje narudžbe":"Moje narudžbe",
       "Adresa za isporuku":"Adresa za isporuku",
       "Lista želja":"Lista želja",
       "Promjena lozinke":"Promjena lozinke",
       "Newsletter":"Newsletter",
       "Podaci":"Podaci",
       "Obavezna polja":"Obavezna polja",
       "SAČUVAJ":"SAČUVAJ",
       "Email":"Email",
       "Ime":"Ime",
       "Firma":"Firma",
       "Prezime":"Prezime",
       "Grad":"Grad",
       "Ulica i broj":"Ulica i broj",
       "Poštanski broj":"Poštanski broj",
       "Telefon":"Telefon",
       "JIB":"JIB",
       "PDV":"PDV",
       "MB":"MB",
       "Narudžbe":"Narudžbe",
       "Broj nardužbe":"Broj nardužbe",
       "Datum":"Datum",
       "Iznos":"Iznos",
       "Status":"Status",
       "Adresa":"Adresa",
       "Adresa za obračun":"Adresa za obračun",
       "Dostavite na ovu adresu":"Dostavite na ovu adresu",
       "Sačuvaj":"Sačuvaj",
       "Dodajte novu adresu za isporuku":"Dodajte novu adresu za isporuku",
       "ADRESA ZA ISPORUKU":"ADRESA ZA ISPORUKU",
       "Nova Lozinka":"Nova Lozinka",
       "Stara lozinka":"Stara lozinka",
       "Ponovite Lozinku":"Ponovite Lozinku",
       "Da li želite da budete u toku? Kako naručiti naš Newsletter:":"Da li želite da budete u toku? Kako naručiti naš Newsletter:",
       "1. Izabereite  Newsletter.":"1. Izabereite  Newsletter.",
       "2. Unesite svoje ime i e-mail adresu, ako već niste prijavljeni.":"2. Unesite svoje ime i e-mail adresu, ako već niste prijavljeni.",
       "3. Kliknite Prijavi se.":"3. Kliknite Prijavi se.",
       "4. Primićete e-mail na kojem morate da potvrdite pretplatu na Newsletter.":"4. Primićete e-mail na kojem morate da potvrdite pretplatu na Newsletter.",
       "Napomena: Dostava e-mail poruke može potrajati nekoliko minuta.":"Napomena: Dostava e-mail poruke može potrajati nekoliko minuta.",
       "Proverite da li je poruka e-pošte premještena u spam folder.":"Proverite da li je poruka e-pošte premještena u spam folder.",
       "Ako ne primite ovu e-poruku, pokušajte ponovo ili nas kontaktirajte direktno.":"Ako ne primite ovu e-poruku, pokušajte ponovo ili nas kontaktirajte direktno.",
       "Možete se odjaviti sa našeg Newsletter na ovoj stranici u bilo kom trenutku.":"Možete se odjaviti sa našeg Newsletter na ovoj stranici u bilo kom trenutku.",
       "1. Korpa":"1. Korpa",
       "Količina, dostava i plaćanje":"Količina, dostava i plaćanje",
       "2. Adresa":"2. Adresa",
       "3. Narudžba":"3. Narudžba",
       "Provjerite narudžbu i potvrdite":"Provjerite narudžbu i potvrdite",
       "4. Plaćanje":"4. Plaćanje",
       "Molimo vas da provjerite svoje unose. Možete ih ispraviti u svakom koraku porudžbine.":"Molimo vas da provjerite svoje unose. Možete ih ispraviti u svakom koraku porudžbine.",
       "Ako je potrebno, kliknite na gore navedene korake.":"Ako je potrebno, kliknite na gore navedene korake.",
       "Molimo Vas obratite pažnju na":"Molimo Vas obratite pažnju na",
       "Uslove Korištenja":"Uslove Korištenja",
       "Politiku Privatnosti":"Politiku Privatnosti",
       "Nastavi sa kupovinom":"Nastavi sa kupovinom",
       "Količina":"Količina",
       "Naziv artikla":"Naziv artikla",
       "Cijena":"Cijena",
       "Ukupno":"Ukupno",
       "Ukupan iznos":"Ukupan iznos",
       "Dostava":"Dostava",
       "Način plaćanja":"Način plaćanja",
       "Plaćanje prilikom pouzeća":"Plaćanje prilikom pouzeća",
       "Uplata na tekući račun":"Uplata na tekući račun",
       "Iznos bez PDV-a":"Iznos bez PDV-a",
       "Iznos PDV-a":"Iznos PDV-a",
       "UKUPAN IZNOS":"UKUPAN IZNOS",
       "ZAVRŠI PORUDŽBINU":"ZAVRŠI PORUDŽBINU",
       "Želite li da nastavite sa kupovinom kasnije?":"Želite li da nastavite sa kupovinom kasnije?",
       "NASTAVI":"NASTAVI",
       "Pročitao sam i prihvatam":"Pročitao sam i prihvatam",
       "Uslove Korištenja.":"Uslove Korištenja.",
       "Galerija":"Galerija",
       "Foto Galerija":"Foto Galerija",
       "Cijena bez PDV-a":"Cijena bez PDV-a",
       "Cijena sa PDV-om":"Cijena sa PDV-om",

       "KUPI ODMAH":"KUPI ODMAH",
       "Dodaj u omiljene":"Dodaj u omiljene",
       "Podijeli":"Podijeli",
       "Opis proizvoda":"Opis proizvoda"
    }
 }

export default langs