import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Link from '../../link.js';
import showResults from '../showResults.js';
import { renderTextField, renderTextareaField, email, required } from '../../fields/fields.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap'


const contactForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, translate } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
                <Col lg="6">
                    <Field 
                        translate = {translate}
                        name="FirstName"
                        type="text"
                        component={renderTextField}
                        label={'VAŠE IME'}
                        placeholder="Ime"
                    ></Field>
                </Col>

                <Col lg="6">
                    <Field translate={translate}
                        name="LastName"
                        type="text"
                        component={renderTextField}
                        label={'VAŠA PREZIME'}
                        placeholder="Prezime"
                    ></Field>
                </Col>

                <Col lg="6">
                    <Field translate={translate}
                        name="Phone"
                        type="text"
                        component={renderTextField}
                        label={'VAŠ BROJ TELEFONA'}
                        placeholder="Telefon"
                        validate={[required]}
                    ></Field>
                </Col>

                <Col lg="6">
                    <Field translate={translate}
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        label={'VAŠA E-MAIL ADRESA'}
                        placeholder={"E-mail"}
                        validate={[required, email]}
                    ></Field>
                </Col>

                <Col lg="12">
                    <Field translate={translate}
                        name="Subject"
                        type="text"
                        component={renderTextField}
                        label={'SUBJECT'}
                        placeholder="Naslov"
                    ></Field>
                </Col>
                <Col lg="12">
                    <Field translate={translate}
                        name="Message"
                        type="text"
                        component={renderTextareaField}
                        label={'PORUKA'}
                        placeholder="Vaša poruka"
                        validate={[required]}

                    ></Field>
                </Col>
            </Row>

            <button className="btn"><span>{translate("Pošalji")}</span></button>


        </form>

    )
}

export default reduxForm({
    form: 'contactForm',  // a unique identifier for this form

})(contactForm)
