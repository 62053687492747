import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import carousel1 from '../images/Carousel1.png';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import wunderbaum from '../images/wunderbaum.png';
import wunderbaum2 from '../images/wunderbaum2.jpg';

import {
    Container,
    Row,
    Col,
    NavItem
} from 'reactstrap';


class Partner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: []
        }
    }

    componentDidMount() {
        this.props.shopCore.fetchPartners((data) => {
            this.setState({ partners: data })
        })
    }

    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div className="breadcrumbLink">
                    <Container>
                        <Row className="no-gutters d-flex justify-content-end">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Partneri")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>

                <div className="naslovWrapper">
                    <Container>

                        <div className="naslov">

                            <Row className="d-block">
                                <h1>{this.props.translate("Naši partneri")}</h1>
                                <p>{this.props.translate("Brendovi sa kojima sarađujemo")}</p>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="partnerWrapper">
                    <Container>
                        <div className="partner">
                            <Row>
                                {
                                    this.state.partners.map((item, idx) => {
                                        return (
                                            <Col key={idx} xl="3" lg="4" md="6" xs="12">
                                                <div className="partnerBox">
                                                    <img src={item.Image} ></img>
                                                </div>
                                            </Col>

                                        )
                                    })
                                }
                                <div className="bottomText">
                                    <h2>{this.props.translate("21 Godina uspješnog poslovanja")}</h2>
                                </div>
                            </Row>

                        </div>

                    </Container>
                </div>
                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>


                <Map {...this.props}></Map>
                <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Partner;