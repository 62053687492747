import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Link from './../link.js';
import News from '../containers/news.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Picture from '../images/newsDetailPicture.png';
import novosti1 from '../images/Novosti1.png';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem,
    Button
} from 'reactstrap';


class NovostiDetalji extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: [],
            item: {}
        }
    }


    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.fetchNews({ count: 3 }, (data) => {
            //alert(JSON.stringify(data));
            this.setState({ news: data });
        })
        this.props.shopCore.fetchNewsItem({ _id: this.props[0].match.params.id }, (data) => {
            //alert(JSON.stringify(data));
            this.setState({ item: data });
        })
    }

    componentDidUpdate(prevProps){
        if (this.props[0].match.params.id != prevProps[0].match.params.id){
            this.props.shopCore.fetchNewsItem({ _id: this.props[0].match.params.id }, (data) => {
                //alert(JSON.stringify(data));
                this.setState({ item: data });
            })
    
        }
    }

    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Novosti")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>

                <Container className="detailNaslov">
                    <div>
                        <h1>{this.state.item.Title && this.state.item.Title[this.props.lang]}</h1>
                        <p className="redParagraf">26.3.2019 god | 11:48h</p>
                    </div>
                </Container>
                <Container className="newsDetail">
                    <div>
                        <Row>
                            <Col lg="9" md="8" xs="12">
                                <img src={this.state.item.Image} className="img-fluid" />
                                <div dangerouslySetInnerHTML={{ __html: this.state.item.Content && this.state.item.Content[this.props.lang] }}>
                                </div>
                            </Col>
                            <Col lg="3" md="4" xs="12">
                                {
                                    this.state.news.map((item, idx) => {
                                        return (
                                            <div className="newsBox">  
                                            <Link to={`/novosti/${item._id}/${item.Alias}`} className="nav-link">
                                                <img src={item.Image} className="img-fluid"></img>
                                                <h3>{item.Title && item.Title[this.props.lang]}</h3>
                                                <p className="redParagraf">{moment.unix(item.time).format('DD.MM.YYYY')} god {moment.unix(item.time).format('HH:mm')}h</p>
                                                <p>{item.ShortDescription && item.ShortDescription[this.props.lang]  && item.ShortDescription[this.props.lang].length > 110 ? item.ShortDescription[this.props.lang].substring(0,110) + "..." : item.ShortDescription[this.props.lang]}</p>
                                                <Button>{this.props.translate("Detaljnije")}</Button>
                                            </Link>
                            
                                        </div>
                            
                                        )
                                    })
                                }

                            </Col>
                        </Row>
                    </div>
                </Container>

                <div className="newsSpacer">
                    <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                </div>

                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default NovostiDetalji;