import React, { Component } from 'react';
import Link from './../link.js';
import shoping from '../images/shoping.png';
import Isvg from 'react-inlinesvg';
import cart_icon from '../images/svg/cart-icon.svg'

import {
    Button, Row, Col

} from 'reactstrap';

class specialArticle extends React.Component {
    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);

        this.state = {

        }
    }


    componentDidMount() {
        this.setState({
            quantity: this.props.MinOrder
        })
    }

    addToCart(e) {
        e.preventDefault();

        if (parseInt(this.state.quantity) < this.props.MinOrder) {
            this.props.showInfoMessage('Za ovaj proizvod postoji minimalna količina.', true);
            return;
        }


        if (this.props.Attributes && this.props.Attributes.length){
            this.props.handleArticleModal({
                _id: this.props._id,
                Alias: this.props.Alias,
                Name: this.props.Name,
                Attributes: this.props.Attributes,
                Image: this.props.Image,
                price: this.props.price,
                quantity: this.state.quantity
            });

            return;
        }

        this.props.shopCore.addToCart({ _id: this.props._id, quantity: parseInt(this.props.MinOrder) }, (data) => {
            if (data.successful) {
                this.props.showInfoMessage('Proizvod je uspješno dodat u korpu!');
                this.props.shopCore.cartInfo('cartInfo');
            } else {
                this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

            }
        });
        return false;

    }

    render() {
        return (
            <>
                <div className="imageBox">
                    <Link to={`/artikal/${this.props._id}/${this.props.Alias}`} lang={this.props.lang} className="nav-link">

                        <img src={this.props.Image} ></img>
                        
                    </Link>
                    {this.props.OnOffer ?
                            <div className="offer-tag">{this.props.translate('NA AKCIJI')}</div>
                            :
                            null}

                </div>
                <div className="textBox">
                    <Link to={`/artikal/${this.props._id}/${this.props.Alias}`} className="nav-link">

                        <h3><span>Art. {this.props.Alias} </span><br/> {this.props.Name && this.props.Name[this.props.lang]}</h3>
                    </Link>
                    <h2><span className="original-price">{this.props.mpc && this.props.mpc[0] ? this.props.formatPrice(this.props.mpc[0]) : null }</span><br/> {this.props.price ? this.props.formatPrice(this.props.price) : null } </h2>

                    <div className="textWrapper">
                        <input type="text" value={this.state.quantity} onChange={(e) => {
                            this.setState({
                                quantity: e.target.value
                            });
                        }} />

                        <Button onClick={this.addToCart}><Isvg src={cart_icon} />{this.props.translate("U KORPU")}</Button>
                    </div>
                </div>
            </>

        );
    }
}


export default specialArticle;