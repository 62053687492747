import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import { Redirect } from 'react-router-dom';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import ResetPasswordForm from '../containers/forms/resetPasswordForm.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount() {
        if (this.props[0].match.params.uid && this.props[0].match.params.code && !this.state._confirmationStarted) {
            this.props.shopCore.confirmEmail({ uid: this.props[0].match.params.uid, code: this.props[0].match.params.code }, (data) => {
                if (data.successful) {
                    this.props.setUserData(data.user);
                }
            })
        }

    }

    render() {

        return (
            <div>
                {this.props.uData ? <Redirect to='/nalog' ></Redirect> : null}

                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Potvrda e-mail adrese")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Potvrda e-mail adrese je u toku.")}</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default ResetPassword;