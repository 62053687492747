import React, { Component } from 'react';
import Link from './../link.js';
import artikal1 from '../images/Artikal1.png';
import artikal2 from '../images/Artikal2.png';
import artikal3 from '../images/Artikal3.png';
import artikal4 from '../images/Artikal4.png';
import artikal5 from '../images/Artikal5.png';
import artikal6 from '../images/Artikal6.png';
import SpecialArticle from './specialArticle.js';
import {
    Container, Row, Col

} from 'reactstrap';
const articles = [
    {
        _id: 1,
        allias: 'sdsd',
        title: 'Auto Patosnice Kade AutoIn®',
        price: '20',
        image: artikal1
    },
    {
        _id: 2,
        allias: 'sdsd',
        title: 'Rucica Menjaca Superb (08-12)',
        price: '15',
        image: artikal2
    },
    {
        _id: 3,
        allias: 'sdsd',
        title: 'Rucica Menjaca Superb (08-12) 6+R AutoIn®',
        price: '20',
        image: artikal3
    },
    {
        _id: 4,
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '35',
        image: artikal4
    },
    {
        _id: 5,
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '20',
        image: artikal5
    },
    {
        _id: 6,
        allias: 'sdsd',
        title: 'Auto Presvlake M-size AutoIn®',
        price: '15',
        image: artikal6
    },
];


class specialArticles extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="specialArticleBoxWrapper">
                <Container className="articleBox">
                    <Row className="no-gutters d-flex justify-content-between">
                        <h4>{this.props.translate("POSEBNO IZDVAJAMO")}</h4>
                        <Link to="/kategorija" className="link" lang={this.props.lang}>{this.props.translate("Pogledajte kompletnu listu")}</Link>
                    </Row>
                    <Row>
                        {this.props.specialOffer.map((item, idx) => {
                            return (
                                <Col xl="4" lg="6" md="6" xs="12" key = {item._id}>
                                        <SpecialArticle {...item} {...this.props}></SpecialArticle>
                                </Col>
                            )
                        })}
                        
                    </Row>

                </Container>
            </div>
        );
    }
}


export default specialArticles;