import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import showResults from '../showResults.js';
import { renderTextField, renderCheckField, email, required } from '../../fields/fields.js';

import {
    Row,
    Col,
    Button

} from 'reactstrap'


const newDeliverAdress = (props) => {
    const { handleSubmit, pristine, reset, submitting, translate } = props;

    return (
        <form onSubmit={handleSubmit} >
            <Row>
                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="EMail"
                        type="email"
                        component={renderTextField}
                        label={('Email')}
                        requiredField
                        validate={[required, email]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="FirstName"
                        type="text"
                        component={renderTextField}
                        label={('Ime')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="LastName"
                        type="text"
                        component={renderTextField}
                        label={('Prezime')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="City"
                        type="text"
                        component={renderTextField}
                        label={('Grad')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="Street"
                        type="text"
                        component={renderTextField}
                        label={('Ulica i broj')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="Zipcode"
                        type="text"
                        component={renderTextField}
                        label={('Poštanski broj')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>

                                <Col xl={12} lg={12} md={12}>
                    <Field translate={translate}
                        name="Phone"
                        type="text"
                        component={renderTextField}
                        label={('Telefon')}
                        requiredField
                        validate={[required]}
                    ></Field>
                </Col>


            </Row>
            <p><span>*</span>&nbsp;{props.translate("Obavezna polja")}</p>

            <Button><span>{props.translate("SAČUVAJ")}</span></Button>


        </form>

    )
}

export default reduxForm({
    form: 'newDeliverAdress',  // a unique identifier for this form

})(newDeliverAdress)
