import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form'
import Text from '../fields/text.js';
import Textarea from '../fields/textarea.js';
import Check from '../fields/checkBox.js';

export const renderTextField = ({
    input,
    placeholder,
    label,
    type,
    meta: { touched, error, warning },
    requiredField, translate
}) => (
        <Text
            placeholder={translate(placeholder)}
            label={translate(label)}
            type={type}
            errorText={touched && error}
            error={touched && error}
            requiredField={requiredField}
            {...input}
        />
    )

export const renderTextareaField = ({
    input,
    placeholder,
    label,
    meta: { touched, error }, translate
}) => (
        <Textarea
            placeholder={translate(placeholder)}
            label={translate(label)}
            errorText={touched && error}
            error={touched && error}
            {...input}
        />
    )

export const renderCheckField = ({
    input,
    placeholder,
    label,
    meta: { touched, error }, translate
}) => (

        <Check
            label={translate(label)}
            {...input}
        />
    )

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Unesite E-mail adresu u pravilnom formatu' : undefined;

export const required = value => value ? undefined : 'Popunite ovo polje'

