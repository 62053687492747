import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import { Redirect } from 'react-router-dom';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import showResults from '../containers/showResults.js';
import LoginForm from '../containers/forms/loginForm.js';
import Link from './../link.js';


import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Login extends React.Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);

        this.state = {

        }
    }


    login(data) {
        this.props.shopCore.userLogin(data, (data) => {
            //console.log(data);
            if (data.successful) {
                this.props.setUserData(data.user);
                //this.props.socketIOClient.emit('cartInfo', {});

            } else {
                if (data.error == 'email-not-verified') {
                    this.setState({
                        error: 'Potvrdite Vašu e-mail adresu da bi ste se prijavili!'
                    });
                } else if (data.error == 'user-not-exists') {
                    this.setState({
                        error: 'Korisnik nije pronadjen'
                    });
                } else if (data.error == 'wrong-credentials') {
                    this.setState({
                        error: 'Pogrešni podaci'
                    })
                }

            }
        });
    }

    render() {

        return (
            <div>
                {
                    this.props.uData ? <Redirect to='/nalog' ></Redirect> : null
                }



                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Prijava")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="login">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Prijava")}</h2>
                                <div className="loginForm">
                                    <h6>{this.props.translate("Prijava za postojeće korisnike")}</h6>
                                    <Row>
                                        <Col xl={8} lg={8} md={8} sm={12} xs={12}>
                                            <LoginForm translate={this.props.translate} lang={this.props.lang} onSubmit={this.login}></LoginForm>

                                            {
                                                this.state.error ?
                                                    <div className="alert alert-danger" role="alert">
                                                        {this.state.error}
                                                    </div>

                                                    : null
                                            }


                                        </Col>
                                    </Row>
                                    <hr />
                                    <h4>{this.props.translate("Novi korisnik?")}</h4>
                                    <Link to="/registracija" lang={this.props.lang}><Button><span>{this.props.translate("Registruj se")}</span></Button></Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Login;