import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import cart_icon from '../images/svg/cart-icon.svg';
import map_icon from '../images/svg/map-icon.svg';
import map_icon_active from '../images/svg/map-icon-active.svg';
import cart_done_icon_active from '../images/svg/cart-done-icon-active.svg';
import cart_done_icon from '../images/svg/cart-done-icon.svg';
import cc_icon from '../images/svg/credit-card-icon.svg';
import Isvg from 'react-inlinesvg';
import minus from '../images/svg/minus.svg';
import plus from '../images/svg/plus.svg';
import delete_icon from '../images/svg/delete-icon.svg';
import arrow_left_red from '../images/svg/arrow-left-red.svg';
import uplatnica from '../images/uplatnica.png';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
    Button,
    Table,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Shoping3 extends React.Component {
    constructor(props) {
        super(props);
        this.executeOrder = this.executeOrder.bind(this);

        this.state = {
            step: 2,
            checked: true,
            checked2: true

        };

    }

    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.fetchCurrentOrder((data) => {
            this.setState({
                order: data.order,
                deliveryMethods: data.deliveryMethods
            });
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.uData && !prevProps.uData) {
            this.props.shopCore.fetchCurrentOrder((data) => {
                //console.log(data);
                this.setState({
                    order: data.order,
                    deliveryMethods: data.deliveryMethods
                });
            });

        }
    }

    executeOrder() {
        this.props.shopCore.executeOrder((data) => {
            if (data.successful) {
                if (this.props.uData && this.props.uData.User && this.props.uData.User.EMail && this.props.uData.User.pk) {

                    this.props.shopCore.userVerify({ email: this.props.uData.User.EMail, pk: this.props.uData.User.pk }, (data) => {
                        //console.log(data);
                        if (data.successful) {
                            this.props.setUserData(data.user);
                            this.props.shopCore.cartInfo();
                        }


                    });
                }

                this.props[0].history.push('/nalog/korpa4');

            }
        })

    }



    render() {
        let cartItems = this.state.order ? this.state.order.products : [];

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div>
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12" className="content-wrap">
                                <div className="cart-wrap">
                                    <Row>
                                        <ul className="cart-progress">

                                            <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                <div className="icon">
                                                    <Isvg src={cart_icon} />
                                                </div>
                                                <p>{this.props.translate("1. Korpa")}</p>
                                                <p>{this.props.translate("Količina, dostava i plaćanje")}</p>

                                            </li>

                                            <li className={this.state.step === 1 ? 'active' : this.state.step > 1 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 1 ? map_icon_active : map_icon} className="secoundSvg" />
                                                </div>
                                                <p>{this.props.translate("2. Adresa")}</p>
                                                <p>{this.props.translate("Adresa za isporuku")}</p>

                                            </li>

                                            <li className={this.state.step === 2 ? 'active' : this.state.step > 2 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 2 ? cart_done_icon_active : cart_done_icon} />
                                                </div>
                                                <p>{this.props.translate("3. Narudžba")}</p>
                                                <p>{this.props.translate("Provjerite narudžbu i potvrdite")}</p>

                                            </li>

                                            <li className={this.state.step === 3 ? 'active' : this.state.step > 3 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={cc_icon} />
                                                </div>
                                                <p>{this.props.translate("4. Plaćanje")}</p>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>

                                <div className="shoppingTable">
                                    <Container className="whiteContainer">
                                        <p>{this.props.translate("Molimo vas da provjerite svoje unose. Možete ih ispraviti u svakom koraku porudžbine.")}</p>
                                        <p>{this.props.translate("Ako je potrebno, kliknite na gore navedene korake.")}</p>
                                        <p>{this.props.translate("Molimo Vas obratite pažnju na")} <Link to="/page/terms-and-conditions" lang={this.props.lang}>{this.props.translate("Uslove Korištenja")}</Link> i <Link to="/page/privacy-policy" lang={this.props.lang}>{this.props.translate("Politiku Privatnosti")}</Link>.</p>
                                        <div className="input-wrap2" onClick={() => this.setState({ checked2: !this.state.checked2 })}><div className={this.state.checked2 ? 'checkbox checked' : 'checkbox'}></div><span>{this.props.translate("Pročitao sam i prihvatam")} <Link to="" lang={this.props.lang}>{this.props.translate("Uslove Korištenja.")} *</Link></span></div>

                                        {this.state.order && this.state.order.BillingAddress && this.state.order.ShippingAddress ?

                                            <div className="deliveryWrapper">
                                                <div className="delivery">
                                                    <div className="deliveryBox">
                                                        <h6>{this.props.translate("Adresa za obračun")}</h6>
                                                    </div>

                                                    <div className="text">
                                                        <Row>
                                                            <Col lg="6" md="6" xs="12">
                                                                <div className="txt1">
                                                                    <p>{this.state.order.BillingAddress.FirstName} {this.state.order.BillingAddress.LastName}</p>
                                                                    <p>{this.state.order.BillingAddress.Street}</p>
                                                                    <p>{this.state.order.BillingAddress.Zipcode} {this.state.order.BillingAddress.City}</p>
                                                                    <br />
                                                                    <p>{this.state.order.BillingAddress.EMail}</p>
                                                                </div>
                                                            </Col>
                                                            <Col lg="6" md="6" xs="12">
                                                                <div className="txt2">
                                                                    <p>{this.state.order.ShippingAddress.FirstName} {}</p>
                                                                    <p>{this.state.order.ShippingAddress.Street}</p>
                                                                    <p>{this.state.order.ShippingAddress.Zipcode} {this.state.order.ShippingAddress.City}</p>
                                                                    <br />
                                                                    <p>{this.state.order.ShippingAddress.EMail}</p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                            : null}
                                    </Container>

                                    <Container className="cart-table">

                                        <Row className="header">
                                            <Col lg="2" xs="2"><p>{this.props.translate("Količina")}</p></Col>
                                            <Col lg="6" xs="6"><p>{this.props.translate("Naziv artikla")}</p></Col>

                                            <Col lg="2" xs="2" className="number"><p>{this.props.translate("Cijena")}</p></Col>

                                            <Col lg="2" xs="2" className="number"><p>{this.props.translate("Ukupno")}</p></Col>
                                        </Row>

                                        {
                                            cartItems.map((item, idx) => {
                                                return (
                                                    <Row className="article-info">
                                                        <Col lg="2" xs="2">
                                                            <div className="inputBox">
                                                                {item.cartQuantity}
                                                            </div>
                                                        </Col>
                                                        <Col lg="6" xs="6">
                                                            <p>{item.Alias} - {item.Name && item.Name[this.props.lang]}</p>
                                                        </Col>
                                                        <Col lg="2" xs="2" className="number">
                                                            <p>{this.props.formatPrice(item.price)} </p>
                                                        </Col>
                                                        <Col lg="2" xs="2" className="number">
                                                            <p>{this.props.formatPrice(item.price * item.cartQuantity)} </p>
                                                        </Col>
                                                    </Row>

                                                )
                                            })
                                        }

                                        <Row className="article-info">
                                            <Col lg="8" xs="8">
                                                <p>{this.props.translate("Ukupan iznos")}</p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p>{this.state.order ? this.props.formatPrice(this.state.order.itemsTotal) : this.props.formatPrice(0)} </p>
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="2" xs="3">
                                                <p>{this.props.translate("Dostava")}</p>
                                            </Col>
                                            <Col lg="6" xs="7">
                                                {this.state.order ?
                                                    this.state.order.DeliveryMethod.name

                                                    : ''
                                                }
                                            </Col>
                                            <Col lg="2" xs="2" className="d-none d-lg-block">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p>{this.state.order ? this.props.formatPrice(this.state.order.DeliveryMethod.totalPrice) : this.props.formatPrice(0)} </p>
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="2" xs="3">
                                                <p>{this.props.translate("Način plaćanja")}</p>
                                            </Col>
                                            <Col lg="6" xs="9" >
                                                <p>{this.state.order ? (this.state.order.BillingMethod == 'žiralno' ? 'Žiralno' : 'Gotovinski pouzećem') : ''}</p>

                                            </Col>
                                            <Col lg="2" className="d-none d-lg-block number">
                                            </Col>
                                            <Col lg="2" className="d-none d-lg-block number">
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="8" xs="8">
                                                <p><span>{this.props.translate("Iznos bez PDV-a")}</span></p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p><span>{this.state.order ? this.props.formatPrice(this.state.order.subtotal) : this.props.formatPrice(0)} </span></p>
                                            </Col>
                                        </Row>
                                        <Row className="article-info">
                                            <Col lg="8" xs="8">
                                                <p><span>{this.props.translate("Iznos PDV-a")}</span></p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p><span>{this.state.order ? this.props.formatPrice(this.state.order.tax) : this.props.formatPrice(0)} </span></p>
                                            </Col>
                                        </Row>

                                        <Row className="article-info">
                                            <Col lg="8" xs="8">
                                                <p><span>{this.props.translate("UKUPAN IZNOS")}</span></p>
                                            </Col>
                                            <Col lg="2" xs="2">
                                            </Col>
                                            <Col lg="2" xs="2" className="number">
                                                <p><span>{this.state.order ? this.props.formatPrice(this.state.order.total) : this.props.formatPrice(0)} </span></p>
                                            </Col>
                                        </Row>




                                    </Container>

                                    <Row>
                                        <Col lg="12">
                                            {
                                                this.state.order && this.state.order.BillingMethod == 'žiralno' ?
                                                    <img className='uplatnica' src={uplatnica} />
                                                    :

                                                    null
                                            }
                                        </Col>

                                    </Row>

                                    <Row className="d-flex justify-content-center justify-content-lg-end">
                                        <Link to="/nalog/korpa4" lang={this.props.lang}><Button onClick={this.executeOrder} disabled={!this.state.checked2}>{this.props.translate("ZAVRŠI PORUDŽBINU")}</Button></Link>
                                    </Row>

                                </div>
                                <div className="continueShopping">
                                    <h6>{this.props.translate("Želite li da nastavite sa kupovinom kasnije?")}</h6>
                                    <p>{this.props.translate("Molimo Vas obratite pažnju na")} <Link to="/page/terms-and-conditions" lang={this.props.lang}>{this.props.translate("Uslove Korištenja")}</Link> i <Link to="/page/privacy-policy" lang={this.props.lang}>{this.props.translate("Politiku Privatnosti")}</Link>.</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Shoping3;