import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import picture from '../images/categoryDetailPicture.png';
import Isvg from 'react-inlinesvg';
import cart_icon from '../images/svg/cart-icon.svg'
import silverHeart from '../images/svg/heart2.svg';
import redFacebook from '../images/svg/redFacebook.svg';
import redInstagram from '../images/svg/redInstagram.svg';
import PosebnaPonuda from '../containers/specialArticles.js';
import SpecijalnaPonuda from '../containers/specialOffer.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators

} from 'reactstrap';


class CategoryDetail extends React.Component {
    constructor(props) {
        super(props);
        this.addToCart = this.addToCart.bind(this);
        this.addToCart1 = this.addToCart1.bind(this);

        this.addToWishlist = this.addToWishlist.bind(this);
        this.onTouchMove = this.onTouchMove.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
        this.onTouchStart = this.onTouchStart.bind(this);

        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);


        this.state = {
            product: {},
            quantity: '1',
            activeIndex: 0
        }
    }
    onTouchStart(event) {
        var x = event.clientX;
        var y = event.clientY;
        if (!this.state._startSwipePos) {
            this.setState({
                _startSwipePos: x,
                _startSwipePosY: y,
                _startLeft: this.carousel.scrollLeft
            });
        }
    }

    onTouchEnd() {
        this.setState({
            _startSwipePos: null,
            _startSwipePosY: null,
            _startLeft: null
        });
    }

    onTouchMove(event) {
        var x = event.clientX;
        var y = event.clientY;

        if (this.state._startSwipePos) {
            this.carousel.scrollLeft = this.state._startLeft - (x - this.state._startSwipePos);
        }

        this.setState({
            _swipePos: x
        });


    }


    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchProduct({ _id: this.props[0].match.params.id }, (data) => {
            this.setState({
                product: data,
                previewImage: data.Image,
                quantity: data.MinOrder
            });

            if (data.Attributes) {

                let product = data;
                this.props.shopCore.fetchProductAttributes({
                    _id: product._id,
                    attributes: product.Attributes
                }, (data) => {

                    if (data._id == product._id) {
                        let article = product;
                        article.attributes = data.attributes;
                        article.SelectedAttributes = {};

                        this.setState({
                            product: article
                        })
                    }
                })

            }

        });

    }


    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] === '/')
            breadcrumb = breadcrumb.substr(1)
        let broken = breadcrumb.split("/");
        let arr = [];
        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/kategorija" + items[i].Breadcrumb
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }
        return [];

    }

    componentDidUpdate(prevProps) {

        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.setState({
                product: {},
            }, () => {


                this.props.shopCore.fetchProduct({ _id: this.props[0].match.params.id }, (data) => {
                    this.setState({
                        product: data,
                        quantity: data.MinOrder,
                        previewImage: data.Image
                    });

                    if (data.Attributes) {

                        let product = data;
                        this.props.shopCore.fetchProductAttributes({
                            _id: product._id,
                            attributes: product.Attributes
                        }, (data) => {

                            if (data._id == product._id) {
                                let article = product;
                                article.attributes = data.attributes;
                                article.SelectedAttributes = {};
                                this.setState({
                                    product: article
                                })
                            }
                        })

                    }

                });
                //this.props.socketIOClient.emit('fetchProduct', { _id: this.props[0].match.params.id });
            });
        }

        if (!this.props.breadcrumb.length && this.props.categories.length && this.state.product && this.state.product._id && this.state.product.Breadcrumb && !this.state._updatingBreadcrumb) {
            let breadcrumb = this.generateBreadcrumb(this.props.categories, this.state.product.Breadcrumb, 0);
            this.setState({
                _updatingBreadcrumb: true
            })
            this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);
        }

        if (!prevProps.uData && this.props.uData) {
            this.props.shopCore.fetchProduct({ _id: this.props[0].match.params.id }, (data) => {
                this.setState({
                    product: data,
                    previewImage: data.Image,
                    quantity: data.MinOrder
                });

                if (data.Attributes) {

                    let product = data;
                    this.props.shopCore.fetchProductAttributes({
                        _id: product._id,
                        attributes: product.Attributes
                    }, (data) => {

                        if (data._id == product._id) {
                            let article = product;
                            article.attributes = data.attributes;
                            article.SelectedAttributes = {};

                            this.setState({
                                product: article
                            })
                        }
                    })

                }

            });


        }



    }

    addToCart() {

        if (this.state.product.SelectedAttributes) {

            let selectedAttributes = this.state.product.SelectedAttributes;
            let attributes = this.state.product.attributes;

            for (let i = 0; i < attributes.length; i++) {
                if (!selectedAttributes[attributes[i].Alias]) {
                    this.props.showInfoMessage('Selektujte sve atribute', true);
                    return;
                }
            }


            this.props.shopCore.addToCart({ _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity), selectedAttributes: this.state.product.SelectedAttributes }, (data) => {
                if (data.successful) {
                    this.props.showInfoMessage('Proizvod je uspješno dodat u korpu!');
                    this.props.shopCore.cartInfo('cartInfo');
                } else {
                    this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

                }

            });

        } else {
            this.props.shopCore.addToCart({ _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity) }, (data) => {
                if (data.successful) {
                    this.props.showInfoMessage('Proizvod je uspješno dodat u korpu!');
                    this.props.shopCore.cartInfo('cartInfo');
                } else {
                    this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

                }

            });

        }

    }

    addToCart1() {

        if (this.state.product.SelectedAttributes) {

            let selectedAttributes = this.state.product.SelectedAttributes;
            let attributes = this.state.product.attributes;

            for (let i = 0; i < attributes.length; i++) {
                if (!selectedAttributes[attributes[i].Alias]) {
                    this.props.showInfoMessage('Selektujte sve atribute', true);
                    return;
                }
            }


            this.props.shopCore.addToCart({ _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity), selectedAttributes: this.state.product.SelectedAttributes }, (data) => {
                if (data.successful) {
                    this.props.showInfoMessage('Proizvod je uspješno dodat u korpu!');
                    this.props.shopCore.cartInfo('cartInfo');
                    this.props[0].history.push('/nalog/korpa?lang='+this.props.lang);
                } else {
                    this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

                }

            });

        } else {
            this.props.shopCore.addToCart({ _id: this.props[0].match.params.id, quantity: parseInt(this.state.quantity) }, (data) => {
                if (data.successful) {
                    this.props.showInfoMessage('Proizvod je uspješno dodat u korpu!');
                    this.props.shopCore.cartInfo('cartInfo');
                    this.props[0].history.push('/nalog/korpa?lang='+this.props.lang);

                } else {
                    this.props.showInfoMessage('Proizvod trenutno nije na stanju', true);

                }

            });

        }

    }


    addToWishlist() {
        if (!this.props.uData) {
            this.props[0].history.push('/prijava');
            return;
        }

        this.props.shopCore.addToWishlist({ _id: this.props[0].match.params.id }, (data) => {
            this.props.showInfoMessage('Proizvod je uspješno dodat u listu omiljenih!');

        });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.product.Images.length  ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.product.Images.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    render() {
        let activeIndex = this.state.activeIndex;
        let product = this.state.product;


        const slides = product.Images &&  [product.Image].concat(product.Images).map((item) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} className="img-fluid" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
            )
        });
        console.log(this.props.uData)

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>
                            {
                                this.props.breadcrumb.map((item, idx) => {
                                    if (item)
                                        return (
                                            <NavItem key={idx}>
                                                <Link to={item.link} className="nav-link" lang={this.props.lang}>{item.name && item.name[this.props.lang]}</Link>
                                            </NavItem>

                                        )
                                })
                            }
                        </Row>
                    </Container>
                </div>
                <div className="categoryDetail">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col xl="9" lg="9" md="12" sm="12" xs="12">
                                <h2>{this.props.breadcrumb.length ? this.props.breadcrumb[this.props.breadcrumb.length - 1].name && this.props.breadcrumb[this.props.breadcrumb.length - 1].name[this.props.lang] : "Kategorije"}</h2>
                                <div className="content">
                                    <Col xl="5" lg="5" md="5" sm="12" xs="12" className="article-image">
                                        <img onClick={() => this.setState({opened: true})} className="preview" src={this.state.previewImage} />

                                        <div className="images" onMouseDown={this.onTouchStart} onMouseMove={this.onTouchMove} onMouseUp={this.onTouchEnd} ref={(input) => { this.carousel = input; }}>

                                            <div onClick={() => this.setState({ previewImage: product.Image, activeIndex: 0 })} className={this.state.previewImage == product.Image ? "image active" : "image"}>
                                                <img draggable="false" src={product.Image} />
                                            </div>

                                            {
                                                product && product.Images && product.Images.map((image, idx) => {
                                                    return (

                                                        <div onClick={() => this.setState({ previewImage: image, activeIndex: idx + 1 })} className={this.state.previewImage == image ? "image active" : "image"}>
                                                            <img draggable="false" src={image} />
                                                        </div>


                                                    )
                                                })
                                            }






                                        </div>


                                    </Col>
                                    <Col xl="7" lg="7" md="7" sm="12" xs="12">
                                        <h1><span>Art. {product.Alias}</span><br/>{product.Name && product.Name[this.props.lang]}</h1>
                                        <hr />


                                        <p className="price">{product.mpc && (product.mpc[0] != product.price) ? <span className="original-price">{product.mpc && product.mpc[0] ? this.props.formatPrice(product.mpc[0]) : null} </span> : null} {product.price ? this.props.formatPrice(product.price) : null}</p>
                                        {this.props.uData && this.props.uData.CustomerGroup == 'LegalPerson' ? <p>{this.props.translate('Cijena bez PDV-a')}</p> : <p>{this.props.translate('Cijena sa PDV-om')}</p>}
                                        <Row>
                                            <Col xs="6">
                                                <div className="input-wrap">
                                                    <label>{this.props.translate("Količina")}</label>

                                                    <input value={this.state.quantity} onChange={(e) => {
                                                        this.setState({
                                                            quantity: e.target.value
                                                        })
                                                    }} type="text" className="form-control" />

                                                </div>
                                            </Col>
                                            {
                                                product.attributes ?
                                                    product.attributes.map((item, idx) => {
                                                        return (
                                                            <Col xs="6">

                                                                <div className="input-wrap">
                                                                    <label>{item.Name && item.Name[this.props.lang]}</label>
                                                                    <select value={product.SelectedAttributes[item.Alias]} onChange={(e) => {
                                                                        let article = product;
                                                                        article.SelectedAttributes[item.Alias] = e.target.value;
                                                                        this.setState({
                                                                            articleModal: article
                                                                        })
                                                                    }} placeholder="Izaberite" className="form-control">
                                                                        <option disabled selected>{this.props.translate("Izaberite...")}</option>
                                                                        {
                                                                            item.Values && item.Values && item.Values[this.props.lang] && item.Values[this.props.lang].map((value) => {
                                                                                return (
                                                                                    <option value={value}>{value}</option>

                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                            </Col>

                                                        )
                                                    })

                                                    : null
                                            }

                                        </Row>


                                        <div className="spacer">
                                            <Button className="btn1" onClick={this.addToCart}><Isvg src={cart_icon}></Isvg>{this.props.translate("U KORPU")}</Button>
                                            <Button className="btn2" onClick={this.addToCart1}>{this.props.translate("KUPI ODMAH")}</Button>
                                        </div>
                                        <div className="spacer2">
                                            <p onClick={this.addToWishlist}><Isvg src={silverHeart} className="heart" />{this.props.translate("Dodaj u omiljene")}</p>
                                            <a href={`https://www.facebook.com/sharer/sharer.php?u=https://autoin.ba/artikal${product._id}/${product.Alias}`} target="_blank"><p>{this.props.translate("Podijeli")}<Isvg src={redFacebook} className="socialMedia" /></p></a>
                                        </div>

                                    </Col>

                                </div>
                                <div className="content2">
                                    <Col xl="6" lg="6" md="12" sm="12" xs="12">
                                        <div className="opis">
                                            <h6>{this.props.translate("Opis proizvoda")}</h6>
                                            <div dangerouslySetInnerHTML={{ __html: product.Description && product.Description[this.props.lang] }}>

                                            </div>

                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <SpecijalnaPonuda {...this.props}></SpecijalnaPonuda>
                <PosebnaPonuda {...this.props}></PosebnaPonuda>
                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
                <Footer translate={this.props.translate}></Footer>
                {this.state.opened ?
                    <div className="lightbox">
                        <Container>
                            <Row>
                                <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                <Carousel className="bg"
                                    activeIndex={activeIndex}
                                    next={this.next}
                                    previous={this.previous}
                                >
                                    <CarouselIndicators items={product.Images} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                    {slides}
                                    <i class="fa fa-angle-left" onClick={this.previous}></i>
                                    <i class="fa fa-angle-right" onClick={this.next}></i>
                                </Carousel>
                            </Row>
                        </Container>
                    </div> : null
                }

            </div>

        );
    }
}


export default CategoryDetail;