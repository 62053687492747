import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import cart_icon from '../images/svg/cart-icon.svg';
import map_icon from '../images/svg/map-icon.svg';
import map_icon_active from '../images/svg/map-icon-active.svg';
import cart_done_icon_active from '../images/svg/cart-done-icon-active.svg';
import cart_done_icon from '../images/svg/cart-done-icon.svg';
import cc_icon from '../images/svg/credit-card-icon.svg';
import Isvg from 'react-inlinesvg';
import minus from '../images/svg/minus.svg';
import plus from '../images/svg/plus.svg';
import delete_icon from '../images/svg/delete-icon.svg';
import arrow_left_red from '../images/svg/arrow-left-red.svg';

import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    NavItem,
    NavLink,
    Button,
    Table,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Shoping4 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 3,
            checked: true,
            checked2: false

        };

    }


    componentDidMount(){
        window.scrollTo(0,0);
    }

    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div>
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12" className="content-wrap">
                                <div className="cart-wrap">
                                    <Row>
                                        <ul className="cart-progress">

                                            <li className={this.state.step === 0 ? 'active' : 'done'}>
                                                <div className="icon">
                                                    <Isvg src={cart_icon} />
                                                </div>
                                                <p>{this.props.translate("1. Korpa")}</p>
                                                <p>{this.props.translate("Količina, dostava i plaćanje")}</p>

                                            </li>

                                            <li className={this.state.step === 1 ? 'active' : this.state.step > 1 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 1 ? map_icon_active : map_icon} className="secoundSvg" />
                                                </div>
                                                <p>{this.props.translate("2. Adresa")}</p>
                                                <p>{this.props.translate("Adresa za isporuku")}</p>

                                            </li>

                                            <li className={this.state.step === 2 ? 'active' : this.state.step > 2 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={this.state.step >= 2 ? cart_done_icon_active : cart_done_icon} />
                                                </div>
                                                <p>{this.props.translate("3. Narudžba")}</p>
                                                <p>{this.props.translate("Provjerite narudžbu i potvrdite")}</p>

                                            </li>

                                            <li className={this.state.step === 3 ? 'active' : this.state.step > 3 ? 'done' : null}>
                                                <div className="icon">
                                                    <Isvg src={cc_icon} />
                                                </div>
                                                <p>{this.props.translate("4. Plaćanje")}</p>
                                            </li>
                                        </ul>
                                    </Row>
                                </div>

                                <div className="shoppingTable">
                                    <Container className="whiteContainer">                                      
                                        <p>{this.props.translate("Uspešno ste naručili.")}</p>
                     
                                    </Container>

                            
                                </div>
                              
                            </Col>
                        </Row>

                    </Container>
                </div>

                <div className = "newsSpacer">
                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                </div>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Shoping4;