import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import odobreno from '../images/svg/profile/odobreno.svg';
import odbijeno from '../images/svg/profile/odbijeno.svg';
import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Table,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class NewsletterPage extends React.Component {
    constructor(props) {
        super(props);
        this.subscribeToNewsletter = this.subscribeToNewsletter.bind(this);
        this.state = {

        };
    }


    subscribeToNewsletter(val) {
        this.props.shopCore.updateUserData( {
            SubscribeToNewsletter: val
        }, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);
                this.props[0].history.push('/nalog')
            }
        });
    }


    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Newsletter")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Newsletter")}</h2>
                                <div className="newsletterPage">
                                    <p>{this.props.translate("Da li želite da budete u toku? Kako naručiti naš Newsletter:")}</p>
                                    <p className = "paragraf">{this.props.translate("1. Izabereite  Newsletter.")}</p>
                                    <p className = "paragraf">{this.props.translate("2. Unesite svoje ime i e-mail adresu, ako već niste prijavljeni.")}</p>
                                    <p className = "paragraf">{this.props.translate("3. Kliknite Prijavi se.")}</p>
                                    <p className = "paragraf">{this.props.translate("4. Primićete e-mail na kojem morate da potvrdite pretplatu na Newsletter.")}</p>
                                    <p className = "spacer">{this.props.translate("Napomena: Dostava e-mail poruke može potrajati nekoliko minuta.")}</p>
                                    <p>{this.props.translate("Proverite da li je poruka e-pošte premještena u spam folder.")}</p>
                                    <p>{this.props.translate("Ako ne primite ovu e-poruku, pokušajte ponovo ili nas kontaktirajte direktno.")}</p>
                                    <br/>
                                    <p>{this.props.translate("Možete se odjaviti sa našeg Newsletter na ovoj stranici u bilo kom trenutku.")}</p>
                                    <div className = "buttons">
                                    { this.props.uData && !this.props.uData.SubscribeToNewsletter ?
                                    <Button onClick={() => this.subscribeToNewsletter(true)} className = "btn1">{this.props.translate("PRIJAVI SE")}</Button>
                                    :
                                    <Button onClick={() => this.subscribeToNewsletter(false)} className = "btn2">{this.props.translate("ODJAVI SE")}</Button>
                                    }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default NewsletterPage;