import React, { Component } from 'react';
import artikal1 from '../images/Artikal1.png';
import artikal2 from '../images/Artikal2.png';
import artikal3 from '../images/Artikal3.png';
import artikal4 from '../images/Artikal4.png';
import artikal5 from '../images/Artikal5.png';
import artikal6 from '../images/Artikal6.png';


import Artikal from './article';
import {
    Container, Row, Col, Input, InputGroup, Button, InputGroupAddon,
    Navbar, NavbarBrand, Collapse, UncontrolledDropdown, NavItem, NavLink, Nav

} from 'reactstrap';


class Artikli extends React.Component {
    constructor(props) {
        super(props);

    }


    render() {
        //console.log(this.props);
        return (
            <Container>
                <Row>
                    {this.props.items && this.props.items.map((item, idx) => {
                        return (
                            <Col xl="4" md="6" xs = "6" className = "divider" key={item._id}>
                                <Artikal {...this.props} {...item} ></Artikal>
                            </Col>

                        )
                    })}

                </Row>
            </Container>
        );
    }
}


export default Artikli;