import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import PersonalDataForm from '../containers/forms/personalDataForm.js';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class PersonalData extends React.Component {
    constructor(props) {
        super(props);
        this.editAccount = this.editAccount.bind(this);

        this.state = {
            initialValues: null
        };
    }

    componentDidMount() { window.scrollTo(0,0);
        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.BillingAddress.FirstName,
                    LastName: this.props.uData.BillingAddress.LastName,
                    Street: this.props.uData.BillingAddress.Street,
                    Zipcode: this.props.uData.BillingAddress.Zipcode,
                    City: this.props.uData.BillingAddress.City,
                    Country: this.props.uData.BillingAddress.Country,
                    EMail: this.props.uData.BillingAddress.EMail,
                    Phone: this.props.uData.BillingAddress.Phone,
                    Company: this.props.uData.BillingAddress.Company,
                    JIB: this.props.uData.BillingAddress.JIB,
                    PDV: this.props.uData.BillingAddress.PDV,
                    MB: this.props.uData.BillingAddress.MB,

                }
            })
        }

    }
    componentDidUpdate(prevProps){
        //console.log(this.state.initialValues);
        if (this.props.uData && !this.state.initialValues) {
            this.setState({
                initialValues: {
                    FirstName: this.props.uData.BillingAddress.FirstName,
                    LastName: this.props.uData.BillingAddress.LastName,
                    Street: this.props.uData.BillingAddress.Street,
                    Zipcode: this.props.uData.BillingAddress.Zipcode,
                    City: this.props.uData.BillingAddress.City,
                    Country: this.props.uData.BillingAddress.Country,
                    EMail: this.props.uData.BillingAddress.EMail,
                    Phone: this.props.uData.BillingAddress.Phone,
                    Company: this.props.uData.BillingAddress.Company,
                    JIB: this.props.uData.BillingAddress.JIB,
                    PDV: this.props.uData.BillingAddress.PDV,
                    MB: this.props.uData.BillingAddress.MB,

                }
            })
        }
    }

    editAccount(data) {
        let obj = {
            'User.Name': data.FirstName + " " + data.LastName,
            'User.EMail': data.EMail,
            'BillingAddress.FirstName': data.FirstName,
            'BillingAddress.LastName': data.LastName,
            'BillingAddress.Street': data.Street,
            'BillingAddress.Zipcode': data.Zipcode,
            'BillingAddress.City': data.City,
            'BillingAddress.EMail': data.EMail,
            'BillingAddress.Phone': data.Phone,
            'BillingAddress.Company': data.Company,
            'BillingAddress.JIB': data.JIB,
            'BillingAddress.PDV': data.PDV,
            'BillingAddress.MB': data.MB,

        }

        //this.props.socketIOClient.emit("updateUserData", obj);
        this.props.shopCore.updateUserData(obj, (data) => {
            if (data.successful) {
                this.props.setUserData(data.user);
                this.props[0].history.push('/nalog');
            }
        })
    }



    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Lični podaci")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Lični podaci")}</h2>
                                <div className="loginForm">
                                    <h6>{this.props.translate("Podaci")}</h6>
                                    <Row>
                                        <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                                            <PersonalDataForm initialValues={this.state.initialValues} onSubmit={this.editAccount} translate={this.props.translate} lang={this.props.lang}></PersonalDataForm>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default PersonalData;