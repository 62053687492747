import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Link from './../link.js';
import News from '../containers/news.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import phone from '../images/phone.png';
import location from '../images/location.png';
import email from '../images/email.png';
import { reduxForm, Field } from 'redux-form';
import ContactForm from '../containers/forms/contactForm.js';

import {
  Container,
  Row,
  Col,
  NavItem,
  NavLink,
  Button,
  Form, FormGroup,
  Label, Input, FormText
} from 'reactstrap';



class Kontakt extends React.Component {
  constructor(props) {
    super(props);
    this.contact = this.contact.bind(this);

    this.state = {
      error: 'text'
    }
  }


  contact(data) {
        this.props.shopCore.contact(data, (data) => {
            //console.log(data);
            if (data.successful) {
                this.setState({message: 'Vaša poruka je uspješno poslata, očekujte odgovor ubrzo.'})
            }
        });
    }

  render() {


    return (
      <div>
        <Navigacija {...this.props}></Navigacija>

        <div className="breadcrumbLink">
          <Container>
            <Row className="d-flex justify-content-end no-gutters">
              <NavItem>
                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
              </NavItem>

              <NavItem>
                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Kontakt")}</Link>
              </NavItem>
            </Row>
          </Container>
        </div>
        <div className="naslovWrapper">
          <Container>
            <div className="naslov">
              <Row className="d-block">
                <h1>{this.props.translate("Kontakt")}</h1>
              </Row>
            </div>
          </Container>
        </div>

        <div className="contactForm">
          <Container>

            <Row>
              <Col xl={8} lg={8} md={12} sm={12} xs={12}>
                <h6>{this.props.translate("Kontakt")}</h6>
                <ContactForm onSubmit={this.contact} translate={this.props.translate} lang={this.props.lang}></ContactForm>
                {
                  this.state.message ?
                    <div className="alert alert-success" role="alert">
                      {this.state.message}
                    </div>

                    : null
                }
              </Col>
              <Col xl={4} lg={4} md={12}>
                <div className="contact-card">
                  <h2>{this.props.translate("INFORMACIJE")}</h2>
                  <p>{this.props.translate("Budite slobodni da nas kontaktirate. Odgovorićemo Vam u što kraćem roku.")}</p>
                  <div className="mid-text">
                    <p>{this.props.translate("d.o.o. EXCALIBUR@")}</p>
                  </div>
                  <p className="firstParagraf"><img src={phone}></img>+387 (0) 55 202 297</p>
                  <p className="secoundParagraf">Dušana Baranjina 37</p>
                  <p className="thirdParagraf"><img src={location}></img>76300 Bijeljina</p>
                  <p className="fourthParagraf"><img src={email}></img>info@autoin.ba</p>

                </div>
              </Col>
            </Row>

          </Container>
        </div>
        <Newsletter {...this.props} translate={this.props.translate} lang={this.props.lang}></Newsletter>
        <Map {...this.props} ></Map>
       <Footer translate={this.props.translate}></Footer>

      </div>

    );
  }
}


export default Kontakt;