import React, { Component } from 'react';
import Link from './../link.js';
import account_icon from '../images/svg/account-icon.svg';
import Isvg from 'react-inlinesvg';

import {
    Container, Row, Col, Input, InputGroup, Button, InputGroupAddon,
    Navbar, NavbarBrand, Collapse, UncontrolledDropdown, NavItem, NavLink, Nav

} from 'reactstrap';

class MobileCategories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }


    componentDidMount(){
        for (let i = 0; i < this.props.breadcrumb.length; i++) {
            let state = {};
            state['_selectedCategory' + i] = this.props.breadcrumb[i]._id;
            this.setState(state);
            //console.log(state);
        }

    }

    componentDidUpdate(prevProps) {
        let change;
        if (this.props.breadcrumb.length !== prevProps.breadcrumb.length){
            change = true;
        }else{
            for(let i=0;i<this.props.breadcrumb.length;i++){
                if (this.props.breadcrumb[i]._id !== prevProps.breadcrumb[i]._id){
                    change = true;
                    break;
                }
            }
        }

        if (change) {

            for (let i = 0; i < this.props.breadcrumb.length; i++) {
                let state = {};
                state['_selectedCategory' + i] = this.props.breadcrumb[i]._id;
                this.setState(state);
            }

        }

    }


    _renderCategories(items, level) {
        return (
            <ul>
                {
                    items.map((item, idx) => {
                        return (
                            <li key={idx} className={this.state['_selectedCategory' + level] === item._id ? "active" : null} onClick={() => {

                                let state = {};
                                state['_selectedCategory' + level] = item._id;

                                this.setState(state);


                            }}><Link to={'/kategorija' + item.Breadcrumb} lang={this.props.lang}>{item.Name && item.Name[this.props.lang]}</Link>
                                {this.state['_selectedCategory' + level] === item._id && item.subcategories && item.subcategories.length ? this._renderCategories(item.subcategories, level + 1) : null}
                            </li>
                        )
                    })
                }
            </ul>
        )
    }


    render() {
        return (

                    
                        this._renderCategories(this.props.categories, 0)
                    


        );
    }
}


export default MobileCategories;