import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Artikli from '../containers/articles.js';
import carousel1 from '../images/Carousel1.png';
import Link from './../link.js';
import SpecijalnaPonuda from '../containers/specialOffer.js';
import PosebnaPonuda from '../containers/specialArticles.js';
import News from '../containers/news.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import categoryPicture from '../images/categoryPagePicture.png';
import listView from '../images/svg/list-view.svg';
import blockView from '../images/svg/block-view.svg';
import Isvg from 'react-inlinesvg';
import arrowDown from '../images/svg/arrowDown.svg';
import arrowLeft from '../images/svg/arrowLeft.svg';
import arrowRight from '../images/svg/arrowRight.svg';
import ReactPaginate from 'react-paginate';
import grid_icon_active from '../images/svg/grid-view-active.svg';
import list_icon from '../images/svg/list-view.svg';
import grid_icon from '../images/svg/grid-view.svg';
import list_icon_active from '../images/svg/list-view-active.svg';

import {
    Container,
    Row,
    Col,
    NavItem,
    FormGroup,
    Label, Input,
    Carousel,
    CarouselItem,
    CarouselIndicators,
    Pagination,
    PaginationLink,
    PaginationItem
} from 'reactstrap';

class Category extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            display: 'grid',
            breadcrumb: [],
            articles: [],
            total: 0,
            page: 0,
            sort: 0

        }
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.categories.length)
            this.fetchProducts();
    }

    sortArticles(sort) {
        this.setState({
            sort: sort
        }, () => {
            this.fetchProducts();
        });


    }

    fetchProducts() {
        //console.log(this.state.page);
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");
        let category = brokenPath[0].replace("/kategorija", "");
        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        //console.log(filters);


        this.props.shopCore.fetchProducts({
            category: category,
            skip:  this.props[0].location.pathname.indexOf('&search=') !== -1 ? (filters.page ? (parseInt(filters.page) - 1) * 6 : 0) : null,
            search: filters.search ? filters.search : null,
            count: this.props[0].location.pathname.indexOf('&search=') !== -1 ? 6 : null,
            sort: this.state.sort
        }, (data) => {
            //alert(JSON.stringify(data))
            this.setState({
                articles: data.products,
                total: data.total

            }, () => {
                if (this.props.scrollPos){
                    window.scrollTo(0, this.props.scrollPos);
                    this.props.setScrollPos(null);
                }else{
                    window.scrollTo(0, 0);
                }
            });
        });

        let breadcrumb = this.generateBreadcrumb(this.props.categories, category, 0);

        if (!filters.page) {
            this.setState({
                page: 0
            }, () => {
                this.forceUpdate();
            })
        }

        if (breadcrumb) {
            /*for (let i = 0; i < breadcrumb.length; i++) {
                let state = {};
                state['_selectedCategory' + i] = breadcrumb[i]._id;
                this.setState(state);
                //console.log(state);
            }*/
        }

        //alert(JSON.stringify(breadcrumb));

        this.props.updateBreadcrumb(!breadcrumb ? [] : breadcrumb);

    }

    updateFilter(name, val) {
        let path = this.props[0].location.pathname;
        let brokenPath = path.split("&");

        let filters = {};

        for (let i = 1; i < brokenPath.length; i++) {
            let brokenFilter = brokenPath[i].split("=");
            filters[brokenFilter[0]] = brokenFilter[1];
        }

        filters[name] = val;

        let link = brokenPath[0];

        if (link == "/kategorija") {
            link = "/kategorija/";
        }
        for (var key in filters) {
            if (filters.hasOwnProperty(key)) {
                link += "&" + key + "=" + filters[key];
            }
        }

        if (!filters.page) {
            this.setState({
                page: parseInt(filters.page) - 1
            }, () => {
                this.forceUpdate();
            })
        }


        this.props[0].history.push(link)

    }

    componentDidUpdate(prevProps) {

        if (!prevProps.categories.length && this.props.categories.length) {
            this.fetchProducts();
        }

        if (prevProps[0].location.pathname !== this.props[0].location.pathname) {

            this.fetchProducts();
        }

        if (!prevProps.uData && this.props.uData) {
            this.fetchProducts();

        }
    }

    generateBreadcrumb(items, breadcrumb, level) {
        if (breadcrumb[0] === '/')
            breadcrumb = breadcrumb.substr(1)
        let broken = breadcrumb.split("/");
        let arr = [];
        if (level >= broken.length) {
            return [];
        }

        for (let i = 0; i < items.length; i++) {
            if (broken[level] == items[i].Alias) {

                arr.push({
                    _id: items[i]._id,
                    name: items[i].Name,
                    link: "/kategorija" + items[i].Breadcrumb,
                    image: items[i].Image,
                    subcategories: items[i].subcategories
                });

                if (items[i].subcategories && items[i].subcategories.length) {
                    arr = arr.concat(this.generateBreadcrumb(items[i].subcategories, breadcrumb, level + 1));
                }

                return arr;
            }
        }
        return [];

    }

    componentWillUnmount(){
        console.log(document.body.scrollTop || document.documentElement.scrollTop);
        this.props.setScrollPos(document.body.scrollTop || document.documentElement.scrollTop)
    }



    render() {
        //console.log(this.state.articles, this.props.breadcrumb.length);
        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            {
                                this.props.breadcrumb.map((item, idx) => {
                                    if (item)
                                        return (
                                            <NavItem key={idx}>
                                                <Link to={item.link} className="nav-link" lang={this.props.lang}>{item.name && item.name[this.props.lang]}</Link>
                                            </NavItem>

                                        )
                                })
                            }


                        </Row>
                    </Container>
                </div>
                <div className="categoryPage">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" xs="12">
                                {

                                    this.props.breadcrumb.length ?
                                        <img src={this.props.breadcrumb.length ? this.props.breadcrumb[this.props.breadcrumb.length - 1].image : null} className="img-fluid naslovnaSlika"></img>
                                        :
                                        null
                                }
                                <div className="selectMenu">
                                    <Container>
                                        <Row className="d-flex justify-content-between">
                                            <h3>{this.props.breadcrumb.length ? this.props.breadcrumb[this.props.breadcrumb.length - 1].name && this.props.breadcrumb[this.props.breadcrumb.length - 1].name[this.props.lang] : "Kategorije"}</h3>
                                            <div className="selectWrapper">
                                                <FormGroup>
                                                    <div>
                                                        <Label for="exampleSelect">{this.props.translate("SORTIRAJ PO")}</Label>
                                                    </div>
                                                    <div>
                                                        <Input onChange={(e) => { this.setState({ sort: e.target.value }, () => this.fetchProducts()) }} type="select" name="select" id="exampleSelect" >
                                                            <option value={0}>{this.props.translate("Šifri artikla")}</option>
                                                            <option value={1}>{this.props.translate("Cijeni opadajućoj")}</option>
                                                            <option value={2}>{this.props.translate("Cijeni rastućoj")}</option>
                                                        </Input>
                                                        <Isvg src={arrowDown}></Isvg>

                                                    </div>

                                                </FormGroup>
                                                {/*
                                                <div className={this.state.display === 'grid' ? 'btn active' : 'btn'} onClick={() => { this.setState({ display: 'grid' }) }}>
                                                    <Isvg src={this.state.display === 'grid' ? grid_icon_active : grid_icon} />
                                                </div>
                                                
                                                <div className={this.state.display === 'list' ? 'btn active' : 'btn'} onClick={() => { this.setState({ display: 'list' }) }}>
                                                    <Isvg src={this.state.display === 'list' ? list_icon_active : list_icon} />
                                                </div>
                                                */}
                                            </div>

                                        </Row>

                                    </Container>

                                </div>

                                <div className="articleBox">
                                    {
                                        this.props.breadcrumb.length == 1 && this.props.breadcrumb[0].subcategories.length ?

                                            this.props.breadcrumb[0].subcategories.map((cat, idx) => {

                                                let articles = [];

                                                for (let i = 0; i < this.state.articles.length; i++) {
                                                    if (this.state.articles[i].category == cat._id) {
                                                        articles.push(this.state.articles[i]);
                                                    }

                                                }

                                                if (articles.length)
                                                    return (
                                                        <div>
                                                            <h4 className="subcat-head">{cat.Name && cat.Name[this.props.lang]}</h4>
                                                            <Artikli items={articles} {...this.props}></Artikli>
                                                        </div>
                                                    )
                                            })

                                            :

                                            <Artikli {...this.props} items={this.state.articles} ></Artikli>

                                    }

                                </div>
                                {
                                    this.props[0].location.pathname.indexOf('&search=') == -1 ?
                                        null
                                        :
                                        <ul className="pagination">
                                            <ReactPaginate
                                                previousLabel={'<'}
                                                nextLabel={'>'}
                                                breakLabel={'...'}
                                                breakClassName={'break-me'}
                                                pageCount={this.state.total / 6}
                                                onPageChange={(page) => { this.updateFilter('page', page.selected + 1) }}
                                                marginPagesDisplayed={window.innerWidth < 500 ?  0 : 1}
                                                pageRangeDisplayed={window.innerWidth < 500 ? 1 : 2}
                                                containerClassName={'pagination'}
                                                subContainerClassName={'pages pagination'}
                                                activeClassName={'active'}
                                                forcePage={this.state.page}

                                            />
                                        </ul>
                                }

                            </Col>

                        </Row>
                    </Container>
                </div>
                <SpecijalnaPonuda {...this.props}></SpecijalnaPonuda>
                <PosebnaPonuda {...this.props}></PosebnaPonuda>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
                <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Category; 