import React, { Component } from 'react';
import Link from './../link.js';
import account_icon from '../images/svg/account-icon.svg';
import Isvg from 'react-inlinesvg';

import {
  Container, Row, Col, Input, InputGroup, Button, InputGroupAddon,
  Navbar, NavbarBrand, Collapse, UncontrolledDropdown, NavItem, NavLink, Nav

} from 'reactstrap';

class HomeKategorije extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <Col lg="3">
        <div className="categories">
          <h3>{this.props.translate("KATEGORIJE")}</h3>
          <ul>
            {
              this.props.categories.map((item, idx) => {
                return (
                  <li><Link to={`/kategorija${item.Breadcrumb}`} lang={this.props.lang}>{item.Name && item.Name[this.props.lang]}</Link>

                    {
                      item.subcategories && item.subcategories.length ?
                        <div className="submenu">


                          {item.subcategories && item.subcategories.map((subcat, idx1) => {
                            return (
                              <ul key={idx1}>
                                <Link to={'/kategorija' + subcat.Breadcrumb} lang={this.props.lang}><h5>{subcat.Name && subcat.Name[this.props.lang]}</h5></Link>
                                {subcat.subcategories && subcat.subcategories.map((subcat1, idx2) => {
                                  return (
                                    <li key={idx2}><Link to={'/kategorija' + subcat1.Breadcrumb} lang={this.props.lang}>{subcat1.Name && subcat1.Name[this.props.lang]}</Link></li>
                                  )
                                })}

                              </ul>

                            )
                          })
                          }
                        </div>

                        : null

                    }
                  </li>

                )
              })
            }

          </ul>
        </div>
      </Col>


    );
  }
}


export default HomeKategorije;