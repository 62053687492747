import React, { Component } from 'react';
import Link from './../link.js';
import Isvg from 'react-inlinesvg';
import cart_icon from '../images/svg/cart-icon.svg'
import watermark from '../images/watermark.png';
import removeIcon from '../images/svg/remove-icon.svg';
import removeIcon2 from '../images/svg/remove-icon-red.svg';

import {
    Button

} from 'reactstrap';

class favoriteArticle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            close: 'silver'
        }
    }


    render() {
        return (
            <div className = "wrapper">

                        <div className="front" onMouseLeave = {() => this.setState({ close: 'silver' })}>
                        {/*<img src = {watermark} className = "watermark"></img>*/}
                        <div onClick={() => this.props.handleDelete(this.props._id)} onMouseEnter = {() => this.setState({ close: '' })} onMouseLeave = {() => this.setState({ close: 'silver' })}> 
                            <Isvg src = {this.state.close === 'silver'? removeIcon: removeIcon2} className = "closeIcon"></Isvg>
                        </div>
                            <div className="imageBox" >
                                <img src={this.props.Image} className="img-fluid"></img>
                            </div>
                            <div className="textBox">
                                <h3>{this.props.Name && this.props.Name[this.props.lang]}</h3>
                                <div className="textWrapper">
                                    <h2>{this.props.formatPrice(this.props.price) }</h2>
                                    <Link to={`/artikal/${this.props._id}/${this.props.Alias}`} lang={this.props.lang}><Button><Isvg src={cart_icon} />{this.props.translate("U KORPU")}</Button></Link>
                                </div>
                            </div>
                            </div>
                </div>
        );
    }
}


export default favoriteArticle;