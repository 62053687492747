import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Link from './../link.js';
import News from '../containers/news.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import Gallery from '../containers/gallery.js';
import lupa from '../images/lupa.png';

import {
    Container,
    Row,
    Col,
    NavItem,
} from 'reactstrap';


class GalleryPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            news: []
        }
    }

    componentDidMount() { window.scrollTo(0,0);
        this.props.shopCore.fetchNews({count: 4}, (data) => {
            //alert(JSON.stringify(data));
            this.setState({news: data});
          })
      
    }

    render() {

        return (
            <div>
                <Navigacija {...this.props}></Navigacija>

                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Galerija")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="galleryNaslov">
                        <Container>
                            <div className="naslov">
                                <Row className="d-block">
                                    <h1>{this.props.translate("Foto Galerija")}</h1>
                                    <p>{this.props.translate("Ideja o kompaniji, koja se bavi distribucijom kvalitetnih proizvoda iz segmenta AutoOpreme i AutoKozmetike, podstakla je osnivanje firme doo.EXCALIBUR@, Decembra 1998.godine.")}</p>
                                </Row>
                            </div>
                        </Container>
                </div>


                <Gallery  {...this.props}></Gallery>


                <div className="homeNews">
                    <Container>
                        <Row className="no-gutters d-flex justify-content-between">
                            <h4>{this.props.translate("NOVOSTI")}</h4>
                            <Link to="/novosti" className="link" translate={this.props.translate} lang={this.props.lang}>{this.props.translate("Arhiva novosti")}</Link>
                        </Row>
                        <News translate={this.props.translate} lang={this.props.lang} items={this.state.news}></News>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
               <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default GalleryPage;