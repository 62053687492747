
import React, { Component } from 'react';
import {  Redirect } from 'react-router-dom';
import Link from './../link.js';

import Isvg from 'react-inlinesvg';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Button,
    Form, FormGroup,
    Label, Input, FormText
  } from 'reactstrap';
class Text extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className="input-wrap">
                <input className={this.props.error ? 'required' : 'form-control'} type={this.props.type} onChange={this.props.onChange} value={this.props.value} placeholder={this.props.error ? this.props.error : this.props.placeholder}/>
                <label>{this.props.label}<span>{this.props.requiredField ? ' *': null}</span></label>
            </div>
        );
    }
}

export default Text;