import React, { Component } from 'react';
import picture1 from '../images/Picture1.png';
import picture2 from '../images/Picture2.png';
import picture3 from '../images/Picture3.png';
import picture4 from '../images/Picture4.png';
import picture5 from '../images/Picture5.png';
import picture6 from '../images/Picture6.png';
import lupa from '../images/lupa.png';

import {
    Container,
    Row,
    Col,
    Image,
    Button,
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators
} from 'reactstrap';

const pictures = [
    {
        src: picture1,
        altText: 'Picture1',
        caption: 'Picture1'
    },
    {
        src: picture2,
        altText: 'Picture2',
        caption: 'Picture2'
    },
    {
        src: picture3,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: picture4,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: picture5,
        altText: 'Picture3',
        caption: 'Picture3'
    },
    {
        src: picture6,
        altText: 'Picture3',
        caption: 'Picture3'
    }
];

export default class Gallery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === pictures.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? pictures.length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }


    render() {
        const activeIndex = this.state.activeIndex;
        const slides = this.props.gallery.map((item) => {
            return (
                <CarouselItem className="bg"
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={item}
                >
                    <img src={item} className="img-fluid" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row d-flex justify-content-start">
                                <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </CarouselItem>
            )
        });


        return (
            <div className="gallery-wrapper">
                <Container>
                    <Row>
                        {
                            this.props.gallery.map((item, idx) => {
                                return (
                                    <Col xl={4} lg={4} md={6} sm={12} key={idx}>
                                    <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: idx })} >
                                        <img src={item} className="img-fluid"/>
                                        <div class="overlay">
                                            <img src={lupa} />
                                        </div>
                                    </div>
                                </Col>
                                
        
                                )
                            })
                        }

                        {/*
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 0 })} >
                                <img src={picture1} className="img-fluid"/>
                                <div class="overlay">
                                    <img src={lupa} />
                                </div>
                            </div>
                        </Col>
                        
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 1 })}>
                                <img src={picture2} className="img-fluid"/>
                                <div class="overlay">
                                    <img src={lupa} />
                                </div>
                            </div>
                        </Col>
                     
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 2 })}>
                                <img src={picture3} className="img-fluid"/>
                                <div class="overlay">
                                    <img src={lupa} />
                                </div>
                            </div>
                        </Col>
                       
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 3 })}>
                                <img src={picture4} className="img-fluid"/>
                                <div class="overlay">
                                    <img src={lupa} />
                                </div>
                            </div>
                        </Col>
                        
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 4 })}>
                                <img src={picture5} className="img-fluid"/>
                                <div class="overlay">
                                    <img src={lupa} />
                                </div>
                            </div>
                        </Col>
                        
                        <Col xl={4} lg={4} md={6} sm={12}>
                            <div className="pictures" onClick={() => this.setState({ opened: true, activeIndex: 5 })}>
                                <img src={picture6} className="img-fluid"/>
                                <div class="overlay">
                                    <img src={lupa} />
                                </div>
                            </div>
                        </Col>
                        */
                        }
                        
                        {this.state.opened ?
                            <div className="lightbox">
                                <Container>
                                    <Row>
                                        <i className="fa fa-times" aria-hidden="true" onClick={() => this.setState({ opened: false })}></i>

                                        <Carousel className="bg"
                                            activeIndex={activeIndex}
                                            next={this.next}
                                            previous={this.previous}
                                        >
                                            <CarouselIndicators items={pictures} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                            {slides}
                                            <i class="fa fa-angle-left" onClick={this.previous}></i>
                                            <i class="fa fa-angle-right" onClick={this.next}></i>
                                        </Carousel>
                                    </Row>
                                </Container>
                            </div> : null
                        }
                    </Row>
                </Container>

            </div>
        )
    }
};

