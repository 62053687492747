import React, { Component } from 'react';
import Navigacija from '../containers/header.js';
import Kategorije from '../containers/categories.js';
import Link from './../link.js';
import Newsletter from '../containers/newsletter.js';
import Map from '../containers/map.js';
import Footer from '../containers/footer.js';
import odobreno from '../images/svg/profile/odobreno.svg';
import odbijeno from '../images/svg/profile/odbijeno.svg';
import Isvg from 'react-inlinesvg';
import moment from 'moment';

import {
    Container,
    Row,
    Col,
    NavItem,
    NavLink,
    Table,
    Button,
    Form, FormGroup,
    Label, Input, FormText
} from 'reactstrap';


class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: []
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.shopCore.fetchOrders((data) => {
            this.setState({
                orders: data
            })

        })



    }

    componentDidUpdate(prevProps) {
        if (!prevProps.uData && this.props.uData) {
            this.props.shopCore.fetchOrders((data) => {
                this.setState({
                    orders: data
                })

            })


        }

    }


    render() {
        let currMonth = 0;
        let lastMonth = 0;
        let toPay = 0;

        if (this.props.uData && this.props.uData.IsSalesManager) {
            // Get a date object for the current time
            var d = new Date();

            // Set it to one month ago
            d.setMonth(d.getMonth() - 1);
            d.setDate(1);
            // Zero the hours
            d.setHours(0, 0, 0);
            // Zero the milliseconds
            d.setMilliseconds(0);


            let lastMonthStartDate = d / 1000;

            // Get a date object for the current time



            var d1 = new Date();

            // Set it to one month ago
            d1.setMonth(d1.getMonth());
            d1.setDate(1);
            // Zero the hours
            d1.setHours(0, 0, 0);

            // Zero the milliseconds
            d1.setMilliseconds(0);


            let currMonthStartDate = d1 / 1000;

            let orders = this.state.orders;
            for (let i = 0; i < orders.length; i++) {
                if (orders[i].orderTime >= lastMonthStartDate && orders[i].orderTime < currMonthStartDate) {
                    lastMonth += parseFloat(orders[i].total);
                }

                if (orders[i].orderTime >= currMonthStartDate) {
                    currMonth += parseFloat(orders[i].total);
                }


            }



        }
        let orders = this.state.orders;

        for (let i = 0; i < orders.length; i++) {
            

            toPay += parseFloat(orders[i].ToPay);

        }
        return (
            <div>
                <Navigacija {...this.props}></Navigacija>
                <div className="breadcrumbLink">
                    <Container>
                        <Row className="d-flex justify-content-end no-gutters">
                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Početna")}</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/" className="nav-link" lang={this.props.lang}>{this.props.translate("Narudžbe")}</Link>
                            </NavItem>
                        </Row>
                    </Container>
                </div>
                <div className="register">
                    <Container>
                        <Row>
                            <Kategorije {...this.props}></Kategorije>
                            <Col lg="9" md="12">
                                <h2>{this.props.translate("Narudžbe")}
                                    {this.props.uData && this.props.uData.IsSalesManager ?
                                        <span className="orders-statistics">{this.props.translate('Prethodni mjesec:')} {lastMonth.toFixed(2)} KM, {this.props.translate('Trenutni mjesec:')} {currMonth.toFixed(2)} KM</span>

                                        : null
                                    }
                                     <span className="orders-statistics">{this.props.translate('Ukupan dug:')} {toPay.toFixed(2)} KM</span>
                                </h2>

                                <div className="orders">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>{this.props.translate("Broj nardužbe")}</th>
                                                <th>{this.props.translate("Datum")}</th>
                                                <th>{this.props.translate("Ukupno")}</th>
                                                <th>{this.props.translate("Plaćeno")}</th>
                                                <th>{this.props.translate("Dug")}</th>
                                                <th>{this.props.translate("Valuta plaćanja")}</th>
                                                <th>{this.props.translate("Status")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.orders.map((item, idx) => {
                                                    return (
                                                        <tr>
                                                            <th scope="row">{item.orderNumber}</th>
                                                            <td>{moment.unix(item.orderTime).format('DD.MM.YYYY')}</td>
                                                            <td>{this.props.formatPrice(item.total)} </td>
                                                            <td>{this.props.formatPrice(item.Payed ? item.Payed : 0)} </td>
                                                            <td>{this.props.formatPrice(item.ToPay ? item.ToPay : 0)} </td>
                                                            <td className={item.ToPay === 0 ? 'payed' : (new Date().getTime() / 1000 > (item.orderTime + item.user.TimeToPay * 60 * 60 * 24)) ? 'not-payed' : '' }>{moment.unix(item.orderTime + item.user.TimeToPay * 60 * 60 * 24).format('DD.MM.YYYY')} </td>
                                                            <td>{item.Status}</td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Newsletter {...this.props} translate={this.props.translate}></Newsletter>
                <Map {...this.props}></Map>
                <Footer translate={this.props.translate}></Footer>

            </div>

        );
    }
}


export default Orders;