import React, { Component } from 'react';
import ponudaPatosnice from '../images/ponudaPatosnice.png';
import Link from './../link.js';

import {
    Container, Row, Col

} from 'reactstrap';

class specijalnaPonuda extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="specijalnaPonuda">
                    <Container>
                        <Row>
                            {
                                this.props.banners.map((item, idx) => {
                                    return (
                                        <Col xl="6" md="6" key={idx}>
                                            <a href={item.Link ? item.Link : '/'}>

                                                <div className="box">
                                                    <Row >
                                                        <Col lg="8" md="8" xs="7">
                                                            <h6>{this.props.translate("SPECIJANA PONUDA")}</h6>
                                                            <h3>{item.Title && item.Title[this.props.lang]} </h3>
                                                            <p>{item.Subtitle && item.Subtitle[this.props.lang]}</p>
                                                        </Col>
                                                        <Col lg="4" md="4" xs="5">
                                                            <img src={item.Image}></img>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </a>

                                        </Col>

                                    )
                                })
                            }

                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}


export default specijalnaPonuda;