import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"; import './App.css';
import Home from './views/home.js';
import GoogleMap from './googleMapScript.js';
import Novosti from './views/novosti.js';
import NovostiDetalji from './views/novostiDetalji.js';
import About from './views/about.js';
import Partner from './views/partner.js';
import GalleryPage from './views/galleryPage.js';
import Kontakt from './views/kontakt.js';
import Login from './views/prijava.js';
import ForgotPassword from './views/forgotPassword.js';
import NewPassword from './views/newPassword.js';
import Register from './views/register.js';
import CategoryDetail from './views/categoryDetail.js';
import Profile from './views/profile.js';
import PersonalData from './views/personalData.js';
import Orders from './views/orders.js';
import DeliveryAdress from './views/deliveryAdress.js';
import NewsletterPage from './views/newsletterPage.js';
import Category from './views/category.js';
import FavoriteList from './views/favoriteList.js';
import Shoping from './views/shoping.js';
import Shoping2 from './views/shoping2.js';
import Shoping3 from './views/shoping3.js';
import Shoping4 from './views/shoping4.js';
import ResetPassword from './views/resetPassword.js';
import InPreparation from './views/inPreparation.js';
import ConfirmMail from './views/confirmMail';
import Page from './views/page';

import langs from './langs';

import { createBrowserHistory } from 'history';

import { Core } from './core';

import {
  Container,
  Row,
  Col,
  NavItem,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Button,

} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import cart_icon from './images/svg/cart-icon.svg'
import silverHeart from './images/svg/heart2.svg';
import redFacebook from './images/svg/redFacebook.svg';
import redInstagram from './images/svg/redInstagram.svg';
import viber from './images/viber.png';

const history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.callback = this.callback.bind(this);
    this.updateBreadcrumb = this.updateBreadcrumb.bind(this);
    this.setUserData = this.setUserData.bind(this);
    this.showInfoMessage = this.showInfoMessage.bind(this);
    this.hideInfoMessage = this.hideInfoMessage.bind(this);
    this.handleArticleModal = this.handleArticleModal.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.translate = this.translate.bind(this);
    this.formatPrice = this.formatPrice.bind(this);
    this.setScrollPos = this.setScrollPos.bind(this);
    window.googleMapsCallback = this.callback;
    this.shopCore = new Core({
      cartInfoCallback: (data) => {
        this.setState({
          cartCount: data.count,

        })

      }
    });

    this.state = {
      lang: 'ba',
      categories: [],
      breadcrumb: [],
      banners: [],
      gallery: [],
      specialOffer: [],
      cartCount: 0,
      scrollPos: 0,
      infoMessages: {

      },
      pages: {
        'terms-and-conditions': {},
        'privacy-policy': {}

      }

    };

  }

  setScrollPos(pos){
    this.setState({
      scrollPos: pos
    })
  }

  translate(text) {
/*
    if (!localStorage.translate) {
      localStorage.translate = JSON.stringify({
        'en': {

        }, 'ba': {

        }

      });
    }

    let obj = JSON.parse(localStorage.translate);
    obj.ba[text] = text;
    obj.en[text] = text;

    localStorage.translate = JSON.stringify(obj);

    return text;
*/
    if (langs[this.state.lang][text]) {
      return langs[this.state.lang][text];
    } else {
      return text
    }
  }
  changeLanguage(lang) {
    this.setState({
      lang: lang
    })
  }

  handleArticleModal(product) {
    product.SelectedAttributes = {};

    this.setState({
      articleModal: product
    })


    this.shopCore.fetchProductAttributes({
      _id: product._id,
      attributes: product.Attributes
    }, (data) => {

      if (data._id == product._id) {
        let article = product;
        article.attributes = data.attributes;
        this.setState({
          articleModal: article
        })
      }
    })
  }

  showInfoMessage(text, error) {
    //console.log(this.state.infoMessages);
    let messages = this.state.infoMessages;
    let idx = Date.now().toString();
    messages[idx] = {
      idx: idx,
      message: text,
      error: error
    };

    this.setState({
      infoMessages: messages
    }, () => {
      this.forceUpdate();
      setTimeout(() => {
        this.hideInfoMessage(idx);
      }, 3000);
    });



  }

  hideInfoMessage(idx) {
    let messages = this.state.infoMessages;
    if (!messages[idx])
      return;
    messages[idx].animate = true;
    this.setState({
      infoMessages: messages
    }, () => {
      setTimeout(() => {


        let messages = this.state.infoMessages;
        delete messages[idx];
        this.setState({
          infoMessages: messages
        })
      }, 1000);
    })
  }


  callback() {
    this.setState({ mapinit: true });
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);


    this.shopCore.fetchCategories((data) => {
      this.setState({ categories: data });
    });

    this.shopCore.fetchPages((data) => {
      this.setState({ pages: data });
    });



    if (localStorage.uData) {
      let uData = JSON.parse(localStorage.uData);

      if (uData && uData.User && uData.User.EMail && uData.User.pk)
        this.shopCore.userVerify({ email: uData.User.EMail, pk: uData.User.pk }, (data) => {
          //console.log(data);
          if (data.successful) {
            this.setUserData(data.user);
            this.shopCore.cartInfo();


            this.shopCore.fetchGallery((data) => {
              this.setState({ gallery: data });
            });


            this.shopCore.fetchBanners((data) => {
              this.setState({ banners: data });
            });


            this.shopCore.fetchSpecialOfferProducts((data) => {
              this.setState({ specialOffer: data });
            });

          }else{
      
            this.shopCore.fetchGallery((data) => {
              this.setState({ gallery: data });
            });
      
      
            this.shopCore.fetchBanners((data) => {
              this.setState({ banners: data });
            });
      
      
            this.shopCore.fetchSpecialOfferProducts((data) => {
              this.setState({ specialOffer: data });
            });
      
          }


        });
    } else {
      this.shopCore.fetchGallery((data) => {
        this.setState({ gallery: data });
      });


      this.shopCore.fetchBanners((data) => {
        this.setState({ banners: data });
      });


      this.shopCore.fetchSpecialOfferProducts((data) => {
        this.setState({ specialOffer: data });
      });
    }


    const unlisten = history.listen((location, action) => {
      window.scrollTo(0, 0);
    });

    if (window.location.href.indexOf('?lang=') !== -1) {
      let lang = window.location.href.split('?lang=')[1];
      this.setState({
        lang: lang
      })
    }
  }


  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        articleModal: null
      })
    }

  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  updateBreadcrumb(bcrumb) {
    this.setState({
      breadcrumb: bcrumb
    });
  }

  setUserData(data) {

    this.setState({
      uData: data
    });
    if (!data) {
      localStorage.removeItem('uData')
    } else {

      localStorage.uData = JSON.stringify(data);
    }
  }

  addToCart(e) {
    e.preventDefault();



    if (parseInt(this.state.quantity) < this.state.articleModal.MinOrder) {
      this.props.showInfoMessage('Za ovaj proizvod postoji minimalna količina.', true);
      return;
    }


    let selectedAttributes = this.state.articleModal.SelectedAttributes;
    let attributes = this.state.articleModal.attributes;

    for (let i = 0; i < attributes.length; i++) {
      if (!selectedAttributes[attributes[i].Alias]) {
        this.showInfoMessage('Selektujte sve atribute', true);
        return;
      }
    }




    this.shopCore.addToCart({ _id: this.state.articleModal._id, quantity: parseInt(this.state.articleModal.quantity), selectedAttributes: selectedAttributes }, (data) => {
      if (data.successful) {
        this.showInfoMessage('Proizvod je uspješno dodat u korpu!');
        this.shopCore.cartInfo('cartInfo');
        this.setState({
          articleModal: null
        })
      } else {
        this.showInfoMessage('Proizvod trenutno nije na stanju', true);
        this.setState({
          articleModal: null
        })

      }
    });
    return false;
  }

  formatPrice(p) {
    let price = p / (this.state.lang == 'en' ? 1.95583 : 1);
    let dec_point = ',';
    let thousands_sep =  '.';

    var parts = price.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_sep);

    return parts.join(dec_point) + ' ' + (this.state.lang == 'en' ? '€' : 'KM');
}

  render() {
    /*if (!this.state.uData) {
      return (


        <InPreparation shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...this.state} />



      )
    } else {*/
      return (
        <div className="linija">
          <Container className="asd">
            <GoogleMap API_KEY="AIzaSyAAqbIo7N0_Rpwtay3-CWzo5gkfpgWZ4to" />
            <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
              <Switch>
                <Route exact path="/" render={(...renderProps) => (<Home shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Home>)} />
                <Route exact path="/novosti" render={(...renderProps) => (<Novosti shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Novosti>)} />
                <Route exact path="/novosti/:id/:alias" render={(...renderProps) => (<NovostiDetalji shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></NovostiDetalji>)} />
                <Route exact path="/o-nama" render={(...renderProps) => (<About shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></About>)} />
                <Route exact path="/partner" render={(...renderProps) => (<Partner shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Partner>)} />
                <Route exact path="/galerija" render={(...renderProps) => (<GalleryPage shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></GalleryPage>)} />
                <Route exact path="/kontakt" render={(...renderProps) => (<Kontakt shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Kontakt>)} />
                <Route exact path="/prijava" render={(...renderProps) => (<Login shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Login>)} />
                <Route exact path="/zaboravljena-lozinka" render={(...renderProps) => (<ForgotPassword shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></ForgotPassword>)} />
                <Route exact path="/reset-lozinke/:uid/:code" render={(...renderProps) => (<ResetPassword shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></ResetPassword>)} />
                <Route exact path="/nova-lozinka" render={(...renderProps) => (<NewPassword shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></NewPassword>)} />
                <Route exact path="/registracija" render={(...renderProps) => (<Register shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Register>)} />
                <Route path="/kategorija" render={(...renderProps) => (<Category updateBreadcrumb={this.updateBreadcrumb} shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Category>)} />
                <Route exact path="/artikal/:id/:alias" render={(...renderProps) => (<CategoryDetail updateBreadcrumb={this.updateBreadcrumb} shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></CategoryDetail>)} />
                <Route exact path="/nalog" render={(...renderProps) => (<Profile shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Profile>)} />
                <Route exact path="/nalog/licni-podaci" render={(...renderProps) => (<PersonalData shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></PersonalData>)} />
                <Route exact path="/nalog/narudzbe" render={(...renderProps) => (<Orders shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Orders>)} />
                <Route exact path="/nalog/adresa" render={(...renderProps) => (<DeliveryAdress shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></DeliveryAdress>)} />
                <Route exact path="/nalog/newsletter" render={(...renderProps) => (<NewsletterPage shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></NewsletterPage>)} />
                <Route exact path="/nalog/lista-zelja" render={(...renderProps) => (<FavoriteList shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}  {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></FavoriteList>)} />
                <Route exact path="/nalog/korpa" render={(...renderProps) => (<Shoping shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Shoping>)} />
                <Route exact path="/nalog/korpa2" render={(...renderProps) => (<Shoping2 shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Shoping2>)} />
                <Route exact path="/nalog/korpa3" render={(...renderProps) => (<Shoping3 shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Shoping3>)} />
                <Route exact path="/nalog/korpa4" render={(...renderProps) => (<Shoping4 shopCore={this.shopCore} setScrollPos={this.setScrollPos} handleArticleModal={this.handleArticleModal} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData} {...renderProps} {...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice}></Shoping4>)} />
                <Route exact path="/potvrda/:uid/:code" render={(...renderProps) => (<ConfirmMail {...renderProps} shopCore={this.shopCore} setScrollPos={this.setScrollPos} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice} />)} />
                <Route exact path="/page/:alias" render={(...renderProps) => (<Page {...renderProps} shopCore={this.shopCore} setScrollPos={this.setScrollPos} updateBreadcrumb={this.updateBreadcrumb} showInfoMessage={this.showInfoMessage} hideInfoMessage={this.hideInfoMessage} setUserData={this.setUserData}{...this.state} changeLanguage={this.changeLanguage} translate={this.translate} formatPrice={this.formatPrice} />)} />

              </Switch>
            </Router>
          </Container>
          <div className="pop-up-messages">
            {
              Object.values(this.state.infoMessages).map((item, idx) => {
                //console.log(item);
                return (
                  <div className={item.animate ? 'hide-message ' : ''} key={idx} onClick={() => this.hideInfoMessage(item.idx)}>

                    <p className={item.error ? 'error' : ''}>{item.error ? <i className="fa fa-times" ></i> : null}{item.message}</p>
                  </div>
                )
              })
            }

          </div>


          {
            this.state.articleModal ?

              <div className="article-modal">

                <div className="content" ref={(node) => this.wrapperRef = node}>
                  <Col xl="5" lg="5" md="5" sm="12" xs="12">
                    <img src={this.state.articleModal.Image} className="img-fluid artikal"></img>
                  </Col>
                  <Col xl="7" lg="7" md="7" sm="12" xs="12">
                    <h1>{this.state.articleModal.Name && this.state.articleModal.Name[this.state.lang]}</h1>
                    <hr />
                    <p className="price">{this.state.articleModal.price &&  this.formatPrice(this.state.articleModal.price)}</p>

                    <Row>
                      {
                        this.state.articleModal.attributes ?
                          this.state.articleModal.attributes.map((item, idx) => {
                            return (
                              <Col xs="6">

                                <div className="input-wrap">
                                  <label>{item.Name && item.Name[this.state.lang]}</label>
                                  <select value={this.state.articleModal.SelectedAttributes[item.Alias]} onChange={(e) => {
                                    let article = this.state.articleModal;
                                    article.SelectedAttributes[item.Alias] = e.target.value;
                                    this.setState({
                                      articleModal: article
                                    })
                                  }} placeholder="Izaberite" className="form-control">
                                    <option disabled selected>Izaberite...</option>
                                    {
                                      item.Values && item.Values[this.state.lang] && item.Values[this.state.lang].map((value) => {
                                        return (
                                          <option value={value}>{value}</option>

                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </Col>

                            )
                          })

                          : null
                      }

                    </Row>


                    <div className="spacer">
                      <Button className="btn1" onClick={this.addToCart}><Isvg src={cart_icon}></Isvg>U KORPU</Button>
                      <Button className="btn2" onClick={() => {
                        this.setState({
                          articleModal: null
                        })
                      }}><i className="fa fa-times" ></i>ZATVORI</Button>

                    </div>

                  </Col>

                </div>


              </div>

              : null
          }
          
          <a href="viber://contact?number=%2B38765582516" className="viber"><img src={viber} /></a>

        </div>
      );
    //}
  }
}

export default App;
